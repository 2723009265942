<template>
  <vuetify-money
    :style="cssstyle"
    v-bind="$attrs"
    v-bind:properties="properties"
    :loading="innerLoading"
    v-on="$listeners"
    v-model="innerValue"
    :valueWhenIsEmpty="valueWhenIsEmpty"
    :options="moneyOptions"
    :name="randomName"
    :id="randomName"
    :autocomplete="randomName"
  />
</template>

<script>
export default {
  props: {
    fixedId: String,
    value: {
      type: [String, Number],
      default: () => "",
    },
    valueWhenIsEmpty: {
      type: String,
      default: () => "",
    },
    prefix: {
      type: String,
      default: () => "",
    },
    prependInnerIcon: {
      type: String,
      default: () => "",
    },
    appendIcon: {
      type: String,
      default: () => "",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    cssstyle: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      innerValue: null,
      innerLoading: false,
      randomName: "",
      moneyOptions: {
        locale: "en-CA",
        prefix: "",
        suffix: "",
        length: 15,
        precision: 2,
      },
      properties: {
        prependInnerIcon: this.prependInnerIcon,
        appendIcon: this.appendIcon,
        loading: this.loading,
        // You can add other v-text-field properties, here.
      },
    };
  },
  watch: {
    value: {
      handler(v) {
        this.innerValue = v;
      },
      immediate: true,
    },
    loading: {
      handler(v) {
        this.$set(this.properties, "loading", v);
      },
      immediate: true,
    },
    innerValue: {
      async handler(v) {
        this.$emit("update:value", v);
        this.$emit("change", v);
      },
    },
    fixedId: {
      handler(v) {
        if (v === null || v === undefined) {
          this.randomName = v;
        } else {
          this.randomName = this.getRandomName();
        }
      },
      immediate: true,
    },
    prefix: {
      handler(v) {
        this.moneyOptions.prefix = v;
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.fixedId === null || this.fixedId === undefined) {
      this.randomName = this.getRandomName();
    } else {
      this.randomName = this.fixedId;
    }
    this.moneyOptions.prefix = this.prefix;
  },
  methods: {
    getRandomName() {
      return Math.random().toString();
    },
  },
};
</script>
