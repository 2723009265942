<template>
  <material-card-sba-small
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :widgetIcon="widgetIcon"
    color="accent"
    :title="$t('components.vue_material_dashboard.items.submit_claim.title')"
  >
    <v-card-text class="my-5">
      <v-btn
        height="80"
        outlined
        x-large
        color="accent lighten-2"
        class="rounded-lg px-5 claim"
        to="submit-claim"
      >
        <span class="accent--text claim-btn">
          <v-icon>mdi-plus</v-icon>
          {{
            $t("components.vue_material_dashboard.items.submit_claim.add_claim")
          }}</span
        >
      </v-btn>
      <v-col>{{
        $t("components.vue_material_dashboard.items.submit_claim.subtitle")
      }}</v-col>
    </v-card-text>
  </material-card-sba-small>
</template>

<script>
import MaterialCardSbaSmall from "@/components/VueMaterialDashboard/material/MaterialCardSmallSbaDashboard.vue";
export default {
  components: {
    MaterialCardSbaSmall,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
    };
  },
  computed: {
    cssVars() {
      return {
        "--border-color": this.$vuetify.theme.themes.light.accent,
      };
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {},
};
</script>
<style scoped>
.v-btn--outlined {
  border: 6px solid;
}
.claim-btn {
  font-weight: 700 !important;
}
.claim >>> .v-btn__content {
  max-width: 180px;
  white-space: normal !important;
  font-size: 1.25rem !important;
}
</style>
