<template>
  <div>
    <material-card-custom
      style="z-index: 2"
      :size.sync="internalSize"
      :isPensions="true"
      :height.sync="internalHeight"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      color="primary"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :title="$t('components.vue_material_dashboard.items.life_events.title')"
    >
      <v-card height="432" flat class="rounded-t-0" light>
        <v-carousel
          cycle
          height="400"
          hide-delimiter-background
          hide-delimiters
          show-arrows-on-hover
        >
          <v-carousel-item v-for="(category, i) in lifeEventsToShow" :key="i">
            <v-sheet height="100%">
              <v-card class="fill-height" flat>
                <v-col align-self="center" class="px-0">
                  <v-card-text class="primary--text text-left">
                    <router-link
                      :to="`/life-event/${tabs[0]}/category/${category.CategoryId}/question/1`"
                    >
                      <v-img height="250" :src="category.CategoryImage"></v-img>
                    </router-link>
                    <div class="category-title my-4">
                      {{
                        $vuetify.lang.current === "en"
                          ? category.CategoryEN
                          : category.CategoryFR
                      }}
                    </div>
                    <v-col :class="category.TextColor" class="pa-0">
                      {{
                        $vuetify.lang.current === "en"
                          ? category.CategoryTextEN
                          : category.CategoryTextFR
                      }}
                    </v-col>
                  </v-card-text>
                  <!-- <v-btn
                    small
                    width="120"
                    color="secondary lighten-2"
                    :to="`/life-event/${tab}/category/${category.CategoryId}/question/1`"
                    class="read-more-btn"
                    >{{ $t("components.life_events.read_more") }}</v-btn
                  > -->
                </v-col>
              </v-card>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-row no-gutters class="bottom-btn">
        <v-btn
          class="secondary lighten-2 mt-2 mt-sm-7"
          width="150px"
          to="life-events"
          >{{ $t("globals.more") }}</v-btn
        >
      </v-row>
    </material-card-custom>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MaterialCardCustom from "@/components/VueMaterialDashboard/material/MaterialCardCustomDashboard.vue";
export default {
  components: {
    MaterialCardCustom,
  },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      lifeEventsToShow: [],
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
    lifeEvents: {
      handler(v) {
        if (v) {
          if (this.tabs.length > 0) {
            this.lifeEventsToShow = v[this.tabs[0]];
          } else {
            this.lifeEventsToShow = [];
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("common", ["lifeEvents"]),
    tabs() {
      return Object.keys(this.lifeEvents);
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {},
};
</script>
<style scoped>
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
