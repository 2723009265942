<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    persistent
    :fullscreen="fullScreen"
    hide-overlay
    scrollable
    v-model="view"
    :width="$vuetify.breakpoint.mdAndDown ? '100%' : '80%'"
  >
    <v-card>
      <v-toolbar dark color="secondary" style="flex: 0 1 0%" flat>
        <v-toolbar-title class="pl-5 text-subtitle-1">
          {{ file !== null && file !== undefined ? file.FileName : "" }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="zoom(10)"
            v-if="file && file.FileExtension === 'pdf'"
          >
            <v-icon>zoom_in</v-icon>
          </v-btn>
          <v-btn
            dark
            text
            @click="zoom(-10)"
            v-if="file && file.FileExtension === 'pdf'"
          >
            <v-icon>zoom_out</v-icon>
          </v-btn>
          <v-btn dark text @click="downloadFile(file)">
            <v-icon>file_download</v-icon>
          </v-btn>
          <v-btn dark text @click="printFile(file)">
            <v-icon>print</v-icon>
          </v-btn>
          <v-btn dark text @click="$emit('close')">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-container
          fluid
          grid-list-md
          v-if="file === null || file === undefined"
        >
          <v-layout row justify-center>
            <v-flex xs12 sm6 md4 lg3>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-flex>
          </v-layout>
        </v-container>
        <div
          v-else-if="file.FileExtension === 'pdf'"
          style="background-color: grey"
        >
          <div
            style="text-align: center; width: 100%; color: white"
            v-if="fullScreen"
          >
            {{
              $t("components.filepreview.current_zoom_is") + previewZoom + "%"
            }}
          </div>
          <pdf
            v-for="page in pageCount"
            :key="page"
            :src.sync="pdfSrc"
            :page="page"
            class="centered-div"
            :style="'width: ' + previewZoom + '%;'"
          ></pdf>
        </div>
        <div
          v-else-if="
            file.FileExtension === 'png' ||
            file.FileExtension === 'jpg' ||
            file.FileExtension === 'jpeg'
          "
          class="file-preview-container"
        >
          <v-img ref="img" :src.sync="src"></v-img>
        </div>
        <div
          v-else-if="file.FileExtension === 'txt'"
          class="file-preview-container"
          style="font-size: 1em; overflow-y: auto"
        >
          {{ src }}
        </div>
        <v-card-text v-else class="file-preview-container">
          <h5>{{ $t("components.filepreview.no_preview") }}</h5>
        </v-card-text>
        <!-- <file-preview
          v-else
          :showHeader="false"
          :fileType="file.FileType"
          :data.sync="fileData"
          :fileName="file.FileName"
          :btnColor="'white'"
          height="100%"
        /> -->
      </v-card-text>
      <!-- <v-card-actions v-if="!fullScreen">
        <v-btn text @click="$emit('close')">
          {{ $t("globals.close") }}
        </v-btn>
      </v-card-actions> -->
      <v-dialog scrollable v-model="loadingFile" persistent max-width="200">
        <v-card>
          <v-card-title>{{
            $t("components.filepreview.please_wait")
          }}</v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout row justify-center>
                <v-flex xs12 sm6 md4 lg3>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import { saveAs } from "file-saver";
import printJS from "print-js";
import pdf from "vue-pdf";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "file-preview",
  props: ["fileItem", "view", "fullScreen"],
  components: {
    pdf,
  },
  mounted() {
    if (this.fullScreen) {
      this.previewZoom = 60;
    } else {
      this.previewZoom = 60;
    }
    this.loadFile();
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      loadingFile: false,
      previewZoom: 60,
      file: null,
      pdfSrc: null,
      pageCount: 0,
      src: null,
    };
  },
  watch: {},
  methods: {
    ...mapActions("data", ["getFile"]),
    async loadFile() {
      try {
        const file = (
          await this.getFile({
            planIdentityId: this.user.plan,
            physicalFileId: this.fileItem.Id,
          })
        ).data;
        const blob = this.b64toBlob(file);

        this.fileData = new File([blob], this.fileItem.FileName, {
          type: blob.type,
        });
        if (this.fileItem.FileExtension === "png") {
          this.src = "data:image/png;base64," + file;
        }

        if (
          this.fileItem.FileExtension === "jpg" ||
          this.fileItem.FileExtension === "jpeg"
        ) {
          this.src = "data:image/jpg;base64," + file;
        }

        if (this.fileItem.FileExtension === "txt") {
          this.src = atob(file);
        }

        if (this.fileItem.FileExtension === "pdf") {
          let src = await this.readFileAsDataUrlAsync(this.fileData);
          this.pdfSrc = pdf.createLoadingTask(src);
          this.pdfSrc.promise.then((p) => {
            this.pageCount = p.numPages;
          });
        }
        this.file = this.fileItem;
      } catch (error) {
        this.error = error;
      }
    },
    zoom(level) {
      let zoom = this.previewZoom + level;
      if (zoom < 0) {
        zoom = 0;
      }
      this.previewZoom = zoom;
    },
    readFileAsDataUrlAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    async downloadFile(item) {
      try {
        this.loadingFile = true;
        let file = (
          await this.getFile({
            planIdentityId: this.user.plan,
            physicalFileId: item.Id,
          })
        ).data;
        const blob = this.b64toBlob(file);
        this.loadingFile = false;
        saveAs(blob, item.FileName);
      } catch (error) {
        this.error = error;
      }
    },
    async printFile(item) {
      try {
        this.loadingFile = true;
        let file = (
          await this.getFile({
            planIdentityId: this.user.plan,
            physicalFileId: item.Id,
          })
        ).data;
        const blob = this.b64toBlob(file);

        const fileData = new File([blob], item.FileName, {
          type: blob.type,
        });

        if (item.FileExtension === "txt") {
          this.src = await this.readFileAsTextAsync(fileData);
        } else if (
          item.FileExtension === "pdf" ||
          item.FileExtension === "jpg" ||
          item.FileExtension === "jpeg" ||
          item.FileExtension === "png"
        ) {
          this.src = await this.readFileAsDataUrlAsync(fileData);
        }

        this.printfile = item;
        this.loadingFile = false;

        this.$nextTick(() => {
          const objectURL = URL.createObjectURL(fileData);
          const pagestyle =
            "@page { size: A4; margin-top: 0mm; margin-bottom: 0mm;} body {margin: 0;} h4 {margin:0}";
          if (item.FileType === "Image") {
            printJS({
              printable: objectURL,
              type: "image",
              header: item.FileName,
              imageStyle: "width: 100%",
              headerStyle: "font-weight: 300;",
              style: pagestyle,
              targetStyles: ["*"],
            });
          } else if (item.FileExtension === "pdf") {
            printJS({
              printable: objectURL,
              type: "pdf",
            });
          } else {
            printJS({
              printable: "print",
              type: "html",
              header: item.FileName,
              headerStyle: "font-weight: 300;",
              style: pagestyle,
              targetStyles: ["*"],
            });
          }
        });
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
<style>
.file-preview-container {
  height: 450px;
  width: 450px;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 20px;
}
.centered-div {
  margin-left: auto !important;
  margin-right: auto !important;
  padding-bottom: 5px;
}
</style>
