var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pa-0 px-1",class:`
                animate__animated
                animate__fadeIn
                animate__faster
                ${_vm.disabled ? 'disabled' : ''}`,attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"py-1 mt-4 rounded-lg",attrs:{"height":"150","width":"200","outlined":""},on:{"drop":_vm.getDroppedFiles,"dragenter":function($event){$event.preventDefault();return _vm.dragEnter.apply(null, arguments)},"dragleave":function($event){$event.preventDefault();return _vm.dragLeave.apply(null, arguments)},"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)}}},[((_vm.proof === null || _vm.proof === undefined) && !_vm.disabled)?_c('div',{class:`
              required-text
              animate__animated animate__heartBeat animate__fast`},[_vm._v(" "+_vm._s(_vm.$t("globals.required"))+" ")]):_vm._e(),(_vm.disabled)?_c('div',{class:`
              not-required-text`},[_vm._v(" "+_vm._s(_vm.$t("globals.not_required"))+" ")]):_vm._e(),(_vm.proof !== null && _vm.proof !== undefined && !_vm.disabled)?_c('div',{staticClass:"required-text"},[_c('v-icon',{class:`
                        animate__animated animate__heartBeat
                        success--text`},[_vm._v("mdi-check")])],1):_vm._e(),_c('v-card-text',{class:`
              fill-height
              d-flex
              justify-space-between
              flex-column
              align-center py-0`,on:{"dragover":function($event){$event.preventDefault();}}},[_c('v-icon',{staticClass:"d-block",attrs:{"color":"secondary"}},[_vm._v(" mdi-tray-arrow-down ")]),(!_vm.uploading || !_vm.uploadReceipt)?_c('div',[_c('div',{staticClass:"small-text secondary--text"},[(_vm.dragEntered && _vm.proof === null && !_vm.disabled)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t("components.new_claim.library.drop_here")))]):_c('span',[_vm._v(_vm._s(_vm.$t("components.new_claim.library.drag_and_drop")))]),_c('div',{staticClass:"pt-1"},[_vm._v(_vm._s(_vm.$t("globals.or")))])]),(
            _vm.proofs.length === 0 && (_vm.proof === null || _vm.proof === undefined)
          )?_c('v-btn',{staticClass:"rounded-lg select-btn",attrs:{"color":"accent","dark":"","small":"","height":"42","disabled":_vm.disabled},on:{"click":function($event){_vm.uploadReceipt = true;
            _vm.$emit('upload-receipt', {
              type: _vm.uploadType,
              source: 'upload',
            });}}},[_vm._v(" "+_vm._s(_vm.$t("globals.select_files"))+" ")]):_vm._e()],1):_vm._e(),(_vm.uploading && _vm.uploadReceipt)?_c('div',[_c('div',{staticClass:"small-text mt-n2 mb-8 secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("components.new_claim.library.uploading_files"))+" ")]),_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.proofs.length !== 0 && !(_vm.uploading && _vm.uploadReceipt))?_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.proof !== null && _vm.proof !== undefined},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg select-btn",attrs:{"color":"accent","dark":"","small":"","height":"42","disabled":_vm.disabled,"max-width":"150"}},'v-btn',attrs,false),on),[(_vm.proof === null || _vm.proof === undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("globals.select_files"))+" ")]):_c('div',[_c('span',{staticClass:"upload-name"},[_vm._v(_vm._s(_vm.proof?.UploadName))]),_c('v-icon',{staticClass:"pl-1 action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('preview-receipt', _vm.proof)}}},[_vm._v("visibility")]),_c('v-icon',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove-receipt', _vm.proofType)}}},[_vm._v("close")])],1)])]}}],null,false,1907734854)},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.uploadReceipt = true;
                  _vm.$emit('upload-receipt', {
                    type: _vm.uploadType,
                    source: 'upload',
                  });}}},[_vm._v(_vm._s(_vm.$t("components.new_claim.library.upload_files")))])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$emit('upload-receipt', {
                    type: _vm.uploadType,
                    source: 'library',
                  })}}},[_vm._v(_vm._s(_vm.$t("components.new_claim.library.select_from_library")))])],1)],1)],1)],1):_vm._e(),(
          _vm.proofs.length === 0 &&
          _vm.proof !== null &&
          _vm.proof !== undefined &&
          !(_vm.uploading && _vm.uploadReceipt)
        )?_c('v-menu',{attrs:{"offset-y":"","disabled":_vm.proof !== null && _vm.proof !== undefined},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg select-btn",attrs:{"color":"accent","dark":"","small":"","loading":_vm.loading,"height":"42","disabled":_vm.disabled,"max-width":"150"}},'v-btn',attrs,false),on),[(_vm.proof === null || _vm.proof === undefined)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("globals.select_files"))+" ")]):_c('div',[_c('span',{staticClass:"upload-name pt-1"},[_vm._v(_vm._s(_vm.proof?.FileSegments[0].FileName))]),_c('v-icon',{staticClass:"pl-1 action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('preview-proof-files', _vm.proof)}}},[_vm._v("visibility")]),_c('v-icon',{staticClass:"action-icon",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove-receipt', _vm.proofType)}}},[_vm._v("close")])],1)])]}}],null,false,1244111395)},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.uploadReceipt = true;
                  _vm.$emit('upload-receipt', {
                    type: _vm.uploadType,
                    source: 'upload',
                  });}}},[_vm._v(_vm._s(_vm.$t("components.new_claim.library.upload_files")))])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.$emit('upload-receipt', {
                    type: _vm.uploadType,
                    source: 'library',
                  })}}},[_vm._v(_vm._s(_vm.$t("components.new_claim.library.select_from_library")))])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"upload-label text-uppercase",class:_vm.proof === null || _vm.proof === undefined ? 'error--text' : ''},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }