<template>
  <!-- <iframe
    :src="source + '#view=fit'"
    :title="title"
    height="100%"
    width="100%"
  /> -->
  <!-- <object
    v-if="isMobile"
    :data="
      'https://docs.google.com/gview?url=' + this.source + '&embedded=true'
    "
    type="application/pdf"
    width="100%"
    height="100%"
  >
    <embed
      :src="
        'https://docs.google.com/gview?url=' + this.source + '&embedded=true'
      "
      type="application/pdf"
    />
  </object> -->
  <object
    :data="source + '#view=fit'"
    type="application/pdf"
    width="100%"
    height="100%"
  >
    <embed :src="source + '#view=fit'" type="application/pdf" />
  </object>
</template>

// url = 'https://docs.google.com/gview?url=' + url + '&embedded=true';
<script>
export default {
  props: {
    source: String,
    title: String,
  },
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>
