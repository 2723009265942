<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    :widgetId="widgetId"
    dark
    :color="'secondary lighten-2'"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :title="
      $t(
        'components.vue_material_dashboard.items.downloads_dashboard_item.title'
      )
    "
  >
    <router-link to="download-centre" class="text-decoration-none">
      <v-card-text class="text-left pb-0">
        <v-list class="pt-0" color="secondary lighten-2">
          <v-list-item-group v-model="selectedItem">
            <v-list-item v-if="loading">
              <v-row justify="center">
                <v-progress-circular
                  size="50"
                  class="mt-10"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-row>
            </v-list-item>
            <div v-show="!loading">
              <v-divider></v-divider>
              <template v-for="i in 3">
                <v-list-item class="px-0" :key="i">
                  <v-list-item-icon class="my-1">
                    <v-icon size="50">mdi-file-document-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <router-link
                      class="text-decoration-none"
                      :to="`download-center/` + i"
                    >
                      <v-list-item-title class="white--text">
                        {{ downloadsData[i] ? downloadsData[i].name : "" }}
                      </v-list-item-title>
                    </router-link>
                  </v-list-item-content>
                  <v-list-item-icon class="pt-2">
                    <v-icon @click.stop="printFile" small>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon class="pt-2 ml-0">
                    <v-icon class="" @click.stop="downloadFile" small
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
                <v-divider :key="i + '-divider'"></v-divider>
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </router-link>
  </material-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      selectedItem: undefined,
      downloadsData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getDownloadsData"]),
    async loadData() {
      try {
        this.loading = true;
        this.downloadsData = (
          await this.getDownloadsData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        (this.downloadsData = [
          {
            id: 1,
            name: "Benefits at Glance",
            url: "https://downloads.ca/benefits_at_glance.pdf",
          },
          {
            id: 2,
            name: "Plan Booklet 2022",
            url: "https://downloads.ca/plan_bookle_2024.pdf",
          },
          {
            id: 3,
            name: "Benefits at Glance",
            url: "https://downloads.ca/benefits_at_glance.pdf",
          },
          {
            id: 4,
            name: "Plan Booklet 2022",
            url: "https://downloads.ca/plan_bookle_2024.pdf",
          },
          {
            id: 5,
            name: "Benefits at Glance",
            url: "https://downloads.ca/benefits_at_glance.pdf",
          },
          {
            id: 6,
            name: "Plan Booklet 2022",
            url: "https://downloads.ca/plan_bookle_2024.pdf",
          },
        ]),
          (this.loading = false);
      }
    },
    printFile() {
      console.log("Print file");
    },
    downloadFile() {
      console.log("Download file");
    },
  },
};
</script>
