<template>
  <div>
    <iframe
      v-if="isYouTubeSource"
      :width="width"
      :height="height"
      :src="youTubeSource"
      allowFullScreen
    >
    </iframe>
    <video v-else ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: Number,
      default: 215,
    },
    width: {
      type: Number,
      default: 320,
    },
  },
  data() {
    return {
      player: null,
      isYouTubeSource: false,
      youTubeSource: "",
    };
  },
  mounted() {
    let internalOption = this.options;
    if (this.isYouTubeVideo(internalOption.sources[0]?.src)) {
      this.isYouTubeSource = true;
      this.youTubeSource = this.getYoutubeEmbedUrl(
        internalOption.sources[0]?.src
      );
      return;
    }
    this.player = videojs(this.$refs.videoPlayer, internalOption, () => {
      this.player.log("onPlayerReady", this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    getYoutubeEmbedUrl(source) {
      if (!source) return "";
      if (source.includes("embed")) return source;
      let url = `https://www.youtube.com/embed/${source.split("v=")[1]}`;
      return url;
    },
    isYouTubeVideo(source) {
      if (!source) return false;
      return source.includes("youtube");
    },
    isMp4Video(source) {
      if (!source) return false;
      return source.includes(".mp4");
    },
  },
};
</script>
