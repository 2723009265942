import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth.module";
import { common } from "./common.module";
import { users } from "./users.module";
import { data } from "./data.module";
import { navbar } from "./navbar.module";
import { documentsService } from "./documentsService.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    documentsService: documentsService,
    auth: auth,
    common: common,
    users: users,
    data: data,
    navbar: navbar,
  },
});
