<template>
  <v-card
    v-show="$vuetify.breakpoint.smAndUp"
    flat
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    style="position: relative; z-index: 0"
    class="material-card mt-0 mb-2 transparent"
    height="230"
  ></v-card>
</template>

<script>
export default {
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
