<template>
  <v-container class="pa-0 pt-md-4 pt-md-0 page-container">
    <v-row justify="center" no-gutters class="my-0">
      <v-card class="pa-0" flat width="1600" color="transparent">
        <v-card-text class="text-left text-subtitle-1 pa-0 pt-md-1">
          <v-row no-gutters class="px-2">
            <v-col sm="4" md="6">
              <v-col
                cols="12"
                :class="`
                  text-h6 text-md-h4
                  pb-0 
                  pt-2 pt-md-5
                  ${pageIcon.PageTitleColor}--text
                  text-left`"
              >
                {{ $t("components.contact.subtitle") }}
              </v-col>
              <v-col
                cols="12"
                :class="`text-subtitle-1 text-md-h6
                  pb-2 pt-0 pt-sm-4
                  font-weight-regular
                  text-left`"
                >{{ $t("components.contact.subtitle1") }}<br />{{
                  $t("components.contact.subtitle2")
                }}</v-col
              >
              <v-col
                v-html="contactInfo[$vuetify.lang.current.toUpperCase()]"
              ></v-col>
            </v-col>
            <v-col
              cols="12"
              sm="8"
              md="6"
              class="pr-sm-2"
              v-if="isPensionsPortal"
            >
              <!-- contact form input -->
              <contact-form-ccwipp />
            </v-col>
            <v-col v-else cols="12" sm="8" md="6" class="pr-sm-2">
              <contact-form />
            </v-col>
          </v-row>
        </v-card-text>
        <div v-if="helpVideos !== null && helpVideos?.length > 0">
          <v-card-title class="justify-start text-h6 text-md-h4">
            <!-- <v-icon large class="mr-2" color="primary">
            mdi-help-circle-outline
          </v-icon> -->
            {{ $t("components.member_registration.need_help") }}</v-card-title
          >
          <help-videos :help-videos="helpVideos" class="mt-4" />
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import ContactForm from "./ContactForm.vue";
import ContactFormCcwipp from "./ContactFormCcwipp.vue";
import HelpVideos from "./HelpVideos.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ContactForm,
    ContactFormCcwipp,
    HelpVideos,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "userProfile",
      "contactInfo",
      "leftDrawerMenuItems",
      "planAdministrator",
      "planAdministratorName",
      "portalPlanNumber",
      "certificateLabel",
      "isPensionsPortal",
      "helpVideos",
    ]),
    pageIcon() {
      const icon = this.leftDrawerMenuItems.find(
        (i) => i.Url === this.$route.path
      );
      return icon;
    },
  },
};
</script>
<style scoped>
.page-container {
  max-width: 1600px;
}
</style>
