<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    @remove-widget="$emit('remove-widget', $event)"
    :title="
      $t(
        'components.vue_material_dashboard.items.dependants_dashboard_item.title'
      )
    "
  >
    <v-card-title>
      {{
        $t(
          "components.vue_material_dashboard.items.dependants_dashboard_item.title"
        )
      }}
    </v-card-title>
    <v-card-text>
      Duis tellus libero, auctor posuere metus id, tincidunt varius lacus.
      Praesent molestie porta sagittis. Curabitur ac ipsum dapibus, auctor purus
      ac, luctus lacus. Phasellus semper ultrices nunc eu dapibus. Donec odio
      enim, interdum ut lorem ac, ullamcorper venenatis magna. Mauris ac magna
      ac risus euismod ornare. Pellentesque porttitor massa quis dui finibus
      cursus. Fusce commodo elit nec nunc faucibus, sed varius felis mattis. In
      neque augue, tincidunt ullamcorper libero ullamcorper, pretium cursus
      lacus. Donec nec ornare elit.
    </v-card-text>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    id: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
