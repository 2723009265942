<template>
  <div>
    <div v-if="!loadingFile">
      <pdf
        v-for="page in pageCount"
        :key="page"
        :src="pdfSrc"
        :page="page"
        class="mx-auto my-2"
        :style="'width: ' + previewZoom + '%;'"
      ></pdf>
    </div>
    <v-container fluid grid-list-md v-else>
      <v-layout row justify-center>
        <v-flex xs12 sm6 md4 lg3>
          <v-progress-circular indeterminate></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import pdf from "vue-pdf";
export default {
  props: ["fileItem"],
  components: {
    pdf,
  },
  data() {
    return {
      pageCount: 0,
      previewZoom: 90,
      pdfSrc: null,
      file: null,
      loadingFile: false,
    };
  },
  mounted() {
    this.loadPdfFile();
  },
  methods: {
    async loadPdfFile() {
      try {
        const blob = this.b64toBlob(this.fileItem.Base64);
        this.fileData = new File([blob], this.fileItem.FileName, {
          type: blob.type,
        });

        if (this.fileItem.FileExtension === "pdf") {
          this.loadingFile = true;
          let src = await this.readFileAsDataUrlAsync(this.fileData);
          this.pageCount = null;
          this.pdfSrc = pdf.createLoadingTask(src);
          this.pdfSrc.promise.then((p) => {
            this.pageCount = p.numPages;
            this.loadingFile = false;
          });
        }
        this.file = this.fileItem;
      } catch (error) {
        this.error = error;
      }
    },
    readFileAsDataUrlAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
};
</script>
