import axios from "axios";
import { TokenService } from "./token.service";
import store from "@/store";

const mapiTokenForTestCCWIPP =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IkJEQUNDNDk5NDYxNjNEMDgwNUI0QTBFNzIyN0I1NkM3IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODE1NjMyOTAsImV4cCI6MTY4MTY0OTY5MCwiaXNzIjoiaHR0cHM6Ly9kZXYubWFwaS5wYmFzY29ubmVjdC5jb20iLCJhdWQiOlsicG9ydGFsLXByb3h5IiwibmVvc3BpbiJdLCJjbGllbnRfaWQiOiJkZXZfbXBfY2N3aXBwX3BiYXNjb25uZWN0X2NvbSIsInN1YiI6ImFiZDdmZmJjLWQwZjEtNGMzZi1iNTkzLTdkNDNmYTYzMzhlNCIsImF1dGhfdGltZSI6MTY4MTU2MzI5MCwiaWRwIjoibG9jYWwiLCJkcmF3YnJpZGdlTWVtYmVySWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJnYXRld2F5TWVtYmVySWQiOiIxNTAwMDMwNTUyIiwibmVvc3Bpbk1lbWJlcklkIjoiNDEzNTQ1IiwicGxhbiI6IjUzYmU2MjI5LTlkYTctNDhjOS1iZTI4LTU5NTViMzI0Mzg1YSIsImdhdGV3YXlQbGFuIjoiNjYyIiwibGFuZ3VhZ2UiOiJlbiIsImlzUmVnaXN0cmF0aW9uQ29tcGxldGVkIjoiVHJ1ZSIsImNvbmZpcm1lZCI6IlRydWUiLCJyb2xlIjoiTWVtYmVyIiwiZW1haWwiOiJhbmRyZW5lb0B0ZXN0LmNvbSIsInVzZXJuYW1lIjoiYW5kcmVuZW9AdGVzdC5jb20iLCJqdGkiOiJGQURFNkEwMzQ0MDRFN0QxNzY5RDc0NDMzNzQwMTcyMCIsInNpZCI6IjY3NTBFREEwNjdGRkVFODQxMDE5RkZFNThFRjU3RkE5IiwiaWF0IjoxNjgxNTYzMjkwLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwibWVtYmVycyIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.kKft35QRlHWHZ6yeeKIxeXPa8O7d_kGmCCqbDT85TtJSF-NjJrukmmIF-mpUzZThKIHvLmlb71ZjiBW4ja8vBBVrc0oZwhLpq1wNKoGcptdFswi3irpW4l53tSW7OgC-9zBDqY5I2eW1L_yoHPD1ZRTM_yjxeXS1sLRw8bK1ahBBl5VO3lzx5T7-ZoHRvoyVZ05j9ZdZ-cmAKoKUkXBAbnHAwY6AUquhygEfECR5LWBooCeLcclnxXpKK_wxAckzwfZPM2ywyU9RAJRwngtUFE4BLEHUGPkZcJ8rbhNftiNB7pEgbBj3PbWL3Kc-2S1IIBcWUiHjtcp6D6MCfMF9wQ";

const mapiTokenForTestDrawbridge =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNDQzUyQ0JDQjhFRkE2RUQzQTdBQTJEQ0JCREQ5M0ZBIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODU2MzI4NjIsImV4cCI6MTY4NTcxOTI2MiwiaXNzIjoiaHR0cHM6Ly9kZXYubWFwaS5wYmFzY29ubmVjdC5jb20iLCJhdWQiOlsicG9ydGFsLXByb3h5IiwibmVvc3BpbiJdLCJjbGllbnRfaWQiOiJkZXZfbXBfbWFzdV9wYmFzY29ubmVjdF9jb20iLCJzdWIiOiI4ZTQ0MmU5Yi03NmQ0LTQ3MDYtOWY5Ni03OWMzMzQyMzQ3MzQiLCJhdXRoX3RpbWUiOjE2ODU2MzI4NjAsImlkcCI6ImxvY2FsIiwiZHJhd2JyaWRnZU1lbWJlcklkIjoiNzM4MWJhZmItM2VlNS00MmQ2LTk3ODktZGNmNzRkMjllMmMxIiwiZ2F0ZXdheU1lbWJlcklkIjoiMDYyNTE5ODAwIiwibmVvc3Bpbk1lbWJlcklkIjoiIiwicGxhbiI6IjM0ZGI2MzA4LWM2N2YtNDlhZi1iY2U1LWNhODIxYzA2NDIwNSIsImdhdGV3YXlQbGFuIjoiNjYyIiwibGFuZ3VhZ2UiOiJlbiIsImlzUmVnaXN0cmF0aW9uQ29tcGxldGVkIjoiVHJ1ZSIsImNvbmZpcm1lZCI6IlRydWUiLCJyb2xlIjoiTWVtYmVyIiwiZW1haWwiOiJhbmRyZUB0ZXN0LmNvbSIsInVzZXJuYW1lIjoiYW5kcmVAdGVzdC5jb20iLCJqdGkiOiI0REE5NTBDQTU5RTkxM0UwRjM5QUM2NzgwNDU1ODI3NCIsInNpZCI6IkY5REZCQzhENDAxMEQ2MjBCRTA2REU3OTJGMEVCODI1IiwiaWF0IjoxNjg1NjMyODYyLCJzY29wZSI6WyJvcGVuaWQiLCJwcm9maWxlIiwibWVtYmVycyIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.Y4dxlXvAshLL_ihKPOHx1lA4A0ShG8pHeQkstcmd9bB_nGZMrpnz_UerDo6OuuJcmGRnfvhXC9nA0PVSaIXvdE9eVQPOHHcBxR_77oFbJYbQf6CV3lrIqg2kmSO_iS3qFCXBjBCS-HJWkoWMtnxo4blwaqfPcVqR1sYMK0Jx3QUqliaQRGEAwBOwWIitx65A8eNMRQl-R8osyHM6oDkQhs5uWqzgWqeeN31VYMuKNdmDJdplPgukIhQPx-n3CUckLFnwveMYQhghJ0WrWEQeV0fCG2gIPGyRKOc2YEHE0WcaEVqp_7NynQfmT40sUhkZcnacBhxHYQ41Weu_U8x5mw";

const mapiTokenForTestDrawbridgeUAT =
  "eyJhbGciOiJSUzI1NiIsImtpZCI6IkQ0RTM2NUM5RDNBQ0Y1OUM3RkNFQkJERjAyMTQ2QTVFIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2ODU2MzI5NTMsImV4cCI6MTY4NTcxOTM1MywiaXNzIjoiaHR0cHM6Ly91YXQubWFwaS5wYmFzY29ubmVjdC5jb20iLCJhdWQiOlsicG9ydGFsLXByb3h5IiwibmVvc3BpbiJdLCJjbGllbnRfaWQiOiJ1YXRfbXBfaW5zdWxhdG9yczExMF9wYmFzY29ubmVjdF9jb20iLCJzdWIiOiI4NWRiMzBkNy1lN2RlLTQ4NmMtODFiYy0wOGRiMjcxNzcwZjEiLCJhdXRoX3RpbWUiOjE2ODU2MzI5NTIsImlkcCI6ImxvY2FsIiwiZHJhd2JyaWRnZU1lbWJlcklkIjoiNDA1OThlZTItNDExYy00ODQ2LWE2ZDQtMDFmZmJkNzE5ZmRiIiwiZ2F0ZXdheU1lbWJlcklkIjoiMDAwNzE2ODI0IiwibmVvc3Bpbk1lbWJlcklkIjoiIiwicGxhbiI6ImY5NzhkYTJmLWIwYzEtNDU2ZS05Y2IxLTZjMGY0MzMyMDc3YiIsImdhdGV3YXlQbGFuIjoiMTExIiwibGFuZ3VhZ2UiOiJlbiIsImlzUmVnaXN0cmF0aW9uQ29tcGxldGVkIjoiVHJ1ZSIsImNvbmZpcm1lZCI6IkZhbHNlIiwicm9sZSI6Ik1lbWJlciIsImVtYWlsIjoidi5hbnRvbmlvdUBkb3RieWRvdC5nciIsInVzZXJuYW1lIjoidi5hbnRvbmlvdUBkb3RieWRvdC5nciIsImp0aSI6Ijk1RjdCNUFFMkVCNTQxMTNGRTkyQ0E5NTFCMEI3NUMyIiwic2lkIjoiOTZCQjA0MEY2MzFDNjA4RjRBM0ZFRUVEOTRDMDlCQUEiLCJpYXQiOjE2ODU2MzI5NTMsInNjb3BlIjpbIm9wZW5pZCIsInByb2ZpbGUiLCJtZW1iZXJzIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbInB3ZCJdfQ.SuA3KUhFMXr9HfsOYaVhnCXfn7aNnIoFnIaNrCyPXqoPnmJdVEHjppYk-odEKtfIoyGd40ehm6LDz3fA9X2Bbl2kv0T2EKgx3oe5EhwT7bnRQjhult6kYVlJbXeMe9chDMml1lXxLHTu9TTf-1PdADHcaNlKeUkvdv_xGDQTBiM-fIV1zDePYqHM-eYeptFd0fiormOXaB9KCAnr4UIkUHsJGNT4jASWuX-S5V05vNBDhGTI3-I6ehb9CBNKck5ZvbEsuyvOmJYhhh7ZqkLLWiWQFsWO3gX9ieTRkZnVueYD7YGTcxNu83A99aN4prNAFIDJyv1NmPP3_a-TrqnbKw";

var querystring = require("querystring");

const ApiService = {
  _401interceptor: null,
  token: null,

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        /*if (error.request.status == 401) {
                    /*if (error.config.url.includes('/o/token/')) {
                      // Refresh token has failed. Logout the user
                      console.log("API SERVICE: dispatch logout: ", error)
                      store.dispatch('auth/logout')
                      throw error
                    } else {
                      // Refresh the access token
                      console.log("API SERVICE: dispatch refresh: ", error)
                      try {
                        await store.dispatch('auth/refreshToken')
                        // Retry the original request
                        return this.customRequest({
                          method: error.config.method,
                          url: error.config.url,
                          data: error.config.data
                        })
                      } catch (e) {
                        // Refresh has failed - reject the original request
                        throw error
                      }*
                    // Refresh token has failed. Logout the user
                    console.log("API SERVICE: dispatch logout: ", error)
                    store.dispatch('auth/logout')
                } else*/
        throw error;
        // eslint-disable-next-line prettier/prettier
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true;
    this.mount401Interceptor();
    //this.setAuthHeader()
  },

  setAuthHeader() {
    //for auth module
    let token = TokenService.getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    const currentlanguage = store.getters["common/language"];
    const languages = store.getters["common/languages"];
    const restOfLanguages = languages.filter(
      (l) => l.language !== currentlanguage.language
    );
    if (restOfLanguages?.length) {
      const langStrings =
        currentlanguage.language +
        ", " +
        restOfLanguages.map((l) => l.language).join(", ");
      axios.defaults.headers.common["Accept-Language"] = langStrings;
    } else {
      axios.defaults.headers.common["Accept-Language"] =
        currentlanguage.language;
    }
    // console.log("auth");
  },

  setGuestHeader() {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Guest"] = "ShareFiles";
    const currentlanguage = store.getters["common/language"];
    const languages = store.getters["common/languages"];
    const restOfLanguages = languages.filter(
      (l) => l.language !== currentlanguage.language
    );
    if (restOfLanguages?.length) {
      const langStrings =
        currentlanguage.language +
        ", " +
        restOfLanguages.map((l) => l.language).join(", ");
      axios.defaults.headers.common["Accept-Language"] = langStrings;
    } else {
      axios.defaults.headers.common["Accept-Language"] =
        currentlanguage.language;
    }
  },

  setLockHeader() {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Lock-State"] = `lock`;
  },
  setUnlockHeader(lockId) {
    axios.defaults.headers.common = {};
    axios.defaults.headers.common["Lock-State"] = `unlock`;
    axios.defaults.headers.common["Lock-Id"] = lockId;
  },

  removeHeaders() {
    axios.defaults.headers.common = {};
    let token = TokenService.getToken();
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  get(resource) {
    this.setAuthHeader();
    return axios.get(resource);
  },

  getCustom(resource) {
    this.setAuthHeader();
    return axios.get(resource, { withCredentials: false });
  },

  getDeployedProxy(resource) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (resource.includes("uat")) {
      token = mapiTokenForTestDrawbridgeUAT;
    }
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.get(resource);
  },

  getGuest(resource) {
    this.setGuestHeader();
    return axios.get(resource);
  },

  getPublic(resource) {
    console.log("get public: ", JSON.stringify(resource), resource);
    return axios.get(resource);
  },

  post(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data);
  },

  postDeployedProxy(resource, data) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.post(resource, data);
  },

  postDms(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data, { withCredentials: false });
  },

  postNoAuth(resource, data) {
    return axios.post(resource, data);
  },

  getNoAuth(resource) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
    });
  },

  postGuest(resource, data) {
    this.setGuestHeader();
    console.log(resource, data);
    return axios.post(resource, data);
  },

  postFile(resource, data) {
    this.setAuthHeader();
    return axios.post(resource, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  put(resource, data) {
    this.setAuthHeader();
    return axios.put(resource, data);
  },

  putDeployedProxy(resource, data) {
    const isPensionsPortal = store.getters["common/isPensionsPortal"];
    let token = mapiTokenForTestDrawbridge;
    if (isPensionsPortal) {
      token = mapiTokenForTestCCWIPP;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Access-Control-Allow-Credentials"] = "true";
    return axios.put(resource, data);
  },

  delete(resource, data) {
    this.setAuthHeader();
    return axios.delete(resource, { data });
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/
  customRequest(data) {
    this.setAuthHeader();
    return axios(data);
  },

  download(url) {
    // console.log(url)
    this.setAuthHeader();
    return axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    });
  },

  downloadPost(url, filename, payload) {
    // console.log(url)
    this.setAuthHeader();
    return axios({
      url: url,
      method: "POST",
      responseType: "blob", // important
      data: payload,
    });
  },

  getCanadaPost(resource) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
    });
  },

  postPaymentsForm(resource, authData, data) {
    axios.defaults.headers.common = {};
    return axios.post(resource, querystring.stringify(data), {
      withCredentials: false,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " + btoa(`${authData.Username}:${authData.Password}`),
      },
    });
  },

  getPayments(resource, token) {
    axios.defaults.headers.common = {};
    return axios.get(resource, {
      withCredentials: false,
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/vnd.fif.api.v1+json",
      },
    });
  },
};

export default ApiService;
