<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="accent"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.add_widget.contact_us')"
  >
    <contact-form class="mt-sm-n5" />
  </material-card-sba>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContactForm from "@/components/Contact/ContactForm.vue";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
export default {
  components: {
    MaterialCardSba,
    ContactForm,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      downloadsData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["downloadCentreData"]),
    latest3Documents() {
      const sortedByDate = JSON.parse(JSON.stringify(this.downloadCentreData));
      return sortedByDate
        .sort(function (a, b) {
          return new Date(b.DateUploaded) - new Date(a.DateUploaded);
        })
        .slice(0, 3);
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("common", ["getDownloadCentreData"]),
    goToDocument(doc) {
      this.$router.push({
        name: "downloadCentre",
        params: {
          doc: doc,
        },
      });
    },
    printFile() {
      console.log("Print file");
    },
    downloadFile() {
      console.log("Download file");
    },
  },
};
</script>
<style scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.action-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
</style>
