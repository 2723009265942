<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-card :loading="saving ? 'secondary' : false">
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">{{ titleIcon }}</v-icon>
          {{ title }}
        </v-card-title>
        <v-card-text class="px-5 pb-0">
          <v-row v-if="!isRegistrationCompletion">
            <v-col cols="12" md="4" class="py-0">
              <v-menu
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    clearable
                    v-mask="'####-##-##'"
                    outlined
                    v-model="internalDependant.EffectiveDate"
                    :label="$t('globals.effective_date')"
                    append-icon="mdi-calendar"
                    @click:append="dateMenu1 = true"
                    @input="
                      internalDependant.EffectiveDate?.length > 3
                        ? (pickerDate = internalDependant.EffectiveDate)
                        : ''
                    "
                    v-bind="attrs"
                    v-on="on"
                    :rules="[$rules.required, $rules.validDate]"
                    class="required"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="internalDependant.EffectiveDate"
                  :min="minEffectiveDate"
                  @change="checkRequiredProofs()"
                  @input="dateMenu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-expand-transition>
            <div
              v-show="
                internalDependant.EffectiveDate || isRegistrationCompletion
              "
            >
              <v-row>
                <v-col cols="12" class="px-0 pt-0 pb-2">
                  <v-card-title class="py-0 text-subtitle-1 font-weight-bold">
                    <v-icon class="mr-2 primary--text">mdi-account</v-icon>
                    {{ $t("components.dependants_form.basic_information") }}
                  </v-card-title>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <no-autocomplete-textfield
                    outlined
                    clearable
                    :label="$t('components.beneficiary_form.first_name')"
                    v-model.trim="internalDependant.FirstName"
                    :rules="[$rules.required, $rules.validName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <no-autocomplete-textfield
                    outlined
                    clearable
                    :label="$t('components.beneficiary_form.last_name')"
                    v-model.trim="internalDependant.LastName"
                    :rules="[$rules.required, $rules.validLastName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-autocomplete
                    outlined
                    :label="$t('components.beneficiary_form.relationship')"
                    :items="relationships"
                    v-model="internalDependant.Relationship"
                    @change="checkRequiredProofs"
                    :rules="[$rules.required]"
                    return-object
                    item-value="Id"
                    :item-text="
                      (item) => $helpers.translate(item, $vuetify.lang.current)
                    "
                    class="required"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-autocomplete
                    outlined
                    :label="$t('components.beneficiary_form.gender')"
                    v-model="internalDependant.Gender"
                    :items="enumModels.Gender"
                    :rules="[$rules.required]"
                    item-value="value"
                    :item-text="
                      $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                    "
                    return-object
                    class="required"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="py-0">
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        clearable
                        v-mask="'####-##-##'"
                        outlined
                        v-model="internalDependant.DateOfBirth"
                        :label="$t('components.beneficiary_form.date_of_birth')"
                        append-icon="mdi-calendar"
                        @click:append="dateMenu = true"
                        @input="
                          internalDependant.DateOfBirth?.length > 3
                            ? (pickerDate = internalDependant.DateOfBirth)
                            : ''
                        "
                        v-bind="attrs"
                        v-on="on"
                        :rules="[
                          $rules.required,
                          $rules.validDate,
                          $rules.dateOfBirth,
                        ]"
                        class="required"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="internalDependant.DateOfBirth"
                      :max="$helpers.toISOStringLocal(new Date())"
                      @input="dateMenu = false"
                      :picker-date.sync="pickerDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  class="chekbox-no-margin pt-0 pt-6 pb-0 mt-n2"
                  v-if="
                    internalDependant?.Relationship?.IsChild &&
                    !internalDependant?.Relationship?.IsSpouse
                  "
                >
                  <v-checkbox
                    v-model="internalDependant.Disabled"
                    :label="$t('components.dependants_form.disabled')"
                    @change="removeProof($event, 'DependantDisabilityProof')"
                  ></v-checkbox>
                </v-col>
                <v-col
                  v-if="internalDependant?.Relationship?.IsChild"
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  class="chekbox-no-margin pt-0 pt-6 pb-0 mt-n2"
                >
                  <v-checkbox
                    v-model="internalDependant.Student"
                    :label="$t('components.dependants_form.student')"
                    @change="removeProof($event, 'DependantSchoolingProof')"
                  ></v-checkbox>
                </v-col>

                <v-col
                  xs="12"
                  sm="6"
                  md="4"
                  lg="4"
                  v-if="internalDependant?.Relationship?.IsSpouse"
                  class="chekbox-no-margin pt-0 pt-6 pb-0 mt-n2"
                >
                  <v-checkbox
                    v-model="internalDependant.IsSmoker"
                    :label="$t('components.dependants_form.smoker')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <add-proofs
                v-show="proofRequired"
                :dependant.sync="internalDependant"
                :clearProofs="clearProofs"
                @save="saveDependantProof"
                @cancel="cancelDependantProofDialog"
                @remove-receipt="uploadProof(false, $event)"
              ></add-proofs>

              <v-row
                class="mt-0"
                v-if="
                  internalDependant?.IsNew && internalDependant?.Relationship
                "
              >
                <v-col cols="12" class="pa-0 py-2">
                  <v-card-title class="py-0 text-subtitle-1 font-weight-bold">
                    <v-icon class="mr-2 primary--text"
                      >mdi-hand-heart-outline</v-icon
                    >
                    {{ $t("components.dependants_form.coverage_information") }}
                  </v-card-title>
                </v-col>
                <v-col cols="12" class="chekbox-no-margin pt-0 pb-2">
                  <v-checkbox
                    v-model="internalDependant.HasCOB"
                    :label="
                      $t(
                        internalDependant?.Relationship?.IsSpouse
                          ? 'components.dependants_form.cob_spouse_label'
                          : 'components.dependants_form.cob_child_label'
                      )
                    "
                  ></v-checkbox>
                </v-col>
                <template v-if="internalDependant.HasCOB">
                  <v-col cols="12" md="4" class="py-0">
                    <no-autocomplete-textfield
                      outlined
                      :label="$t('components.dependants_form.name_of_insurer')"
                      v-model="internalDependant.NameOfInsurer"
                      :rules="[$rules.required]"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-menu
                      v-model="dateMenu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          clearable
                          v-mask="'####-##-##'"
                          outlined
                          v-model="internalDependant.CobEffectiveDate"
                          :label="$t('globals.effective_date')"
                          append-icon="mdi-calendar"
                          @click:append="dateMenu2 = true"
                          @input="
                            internalDependant.CobEffectiveDate?.length > 3
                              ? (pickerDate =
                                  internalDependant.CobEffectiveDate)
                              : ''
                          "
                          v-bind="attrs"
                          v-on="on"
                          :rules="[$rules.required, $rules.validDate]"
                          class="required"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="internalDependant.CobEffectiveDate"
                        @input="dateMenu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <no-autocomplete-textfield
                      outlined
                      :label="$t('components.dependants_form.policy_number')"
                      v-model="internalDependant.PolicyNumber"
                      class="required"
                    ></no-autocomplete-textfield>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      outlined
                      :label="$t('components.dependants_form.health')"
                      v-model="internalDependant.Health"
                      :items="enumModels?.FamilyStatus"
                      :rules="[$rules.required]"
                      item-value="value"
                      :item-text="
                        $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                      "
                      class="required"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      outlined
                      :label="$t('components.dependants_form.dental')"
                      v-model="internalDependant.Dental"
                      :items="enumModels?.FamilyStatus"
                      :rules="[$rules.required]"
                      item-value="value"
                      :item-text="
                        $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                      "
                      class="required"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <v-autocomplete
                      outlined
                      :label="$t('components.dependants_form.vision_care')"
                      v-model="internalDependant.VisionCare"
                      :items="enumModels?.FamilyStatus"
                      :rules="[$rules.required]"
                      item-value="value"
                      :item-text="
                        $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                      "
                      class="required"
                    ></v-autocomplete>
                  </v-col>
                </template>
              </v-row>
              <!-- Signature -->
              <v-row
                no-gutters
                justify="space-around"
                align="center"
                v-if="validToSave && !isRegistrationCompletion"
              >
                <v-col cols="12" class="red--text font-weight-bold pt-0 pb-2">
                  {{
                    $t(
                      "components.contact_information_expansion_panel.signatutre_warning"
                    )
                  }}
                </v-col>
                <signature-input
                  :member="member"
                  @update-signature="signature = $event"
                />
                <v-col cols="12" md="4" class="pt-6 pb-4">
                  <v-text-field
                    outlined
                    :label="$t('components.beneficiary_form.designation_date')"
                    append-icon="mdi-calendar"
                    :value="$helpers.formattedDateNow()"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions class="pa-4 pt-0 justify-end">
          <v-btn class="mr-3" @click="cancel">{{ $t("globals.cancel") }}</v-btn>
          <v-btn
            :loading="saving"
            color="primary"
            @click="save"
            :disabled="
              isRegistrationCompletion
                ? !validToSave
                : !signature || !validToSave
            "
          >
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import SignatureInput from "@/components/VueMaterialDashboard/SignatureInput.vue";
import AddProofs from "@/components/MemberInformation/items/panels/DependantsPanel/AddProofs.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NoAutocompleteTextfield,
    AddProofs,
    SignatureInput,
  },
  props: {
    member: Object,
    dependant: Object,
    minEffectiveDate: String,
    title: String,
    titleIcon: String,
    saving: Boolean,
    dependantSaved: Boolean,
    isRegistrationCompletion: Boolean,
    remainingPercentage: Number,
    dependants: Array,
  },
  data() {
    return {
      internalDependant: {},
      internalRelationships: null,
      valid: false,
      dateMenu: false,
      dateMenu1: false,
      dateMenu2: false,
      pickerDate: "1985-06",
      uploadProofDialog: false,
      proofCategory: null,
      clearProofs: false,
      signature: null,
      newDependant: {
        FirstName: "",
        LastName: "",
        Relationship: "",
        DateOfBirth: "",
        Gender: "",
      },
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels"]),
    canAddSpouse() {
      const isSpouseDeps = this.dependants.filter((x) => {
        const relationship = this.enumModels?.Relationship?.find((y) => {
          const id = x.Relationship?.Id ? x.Relationship?.Id : x.Relationship;
          return y.Id?.toUpperCase() === id?.toUpperCase();
        });
        return (
          relationship?.IsSpouse && !x.IsRemoved && x.Id !== this.dependant?.Id
        );
      });
      return isSpouseDeps.length === 0;
    },
    relationships() {
      if (this.dependant?.IsNew && this.canAddSpouse) {
        return this.enumModels?.Relationship;
      }
      if (this.currentDependatRelationship?.IsSpouse) {
        return this.enumModels?.Relationship?.filter((x) => x.IsSpouse);
      }
      if (this.currentDependatRelationship?.IsChild) {
        return this.enumModels?.Relationship?.filter((x) => x.IsChild);
      }
      if (this.canAddSpouse || this.internalDependant?.Relationship?.IsSpouse) {
        return this.internalRelationships;
      } else
        return this.internalRelationships.map((x) => {
          x.IsSpouse ? (x.disabled = true) : null;
          return x;
        });
      // } else return this.enumModels?.Relationship?.filter((x) => x.IsChild);
    },
    currentDependatRelationship() {
      const relationshipId = this.internalDependant?.Relationship?.Id
        ? this.internalDependant?.Relationship?.Id
        : this.internalDependant?.Relationship;
      return this.enumModels?.Relationship?.find((x) => {
        return x.Id?.toUpperCase() === relationshipId?.toUpperCase();
      });
    },
    proofRequired() {
      return (
        this.internalDependant.Student ||
        this.internalDependant.Disabled ||
        this.internalDependant.CommonLawProof ||
        this.internalDependant.LifeEventProof
      );
    },
    validToSave() {
      const validStudent = this.internalDependant.Student
        ? !!this.internalDependant.Qualification?.ProofOfSchooling
        : true;
      const validDisabled = this.internalDependant.Disabled
        ? !!this.internalDependant.Qualification?.ProofOfDisability
        : true;
      const validCommonLaw = this.internalDependant.CommonLawProof
        ? !!this.internalDependant.Qualification?.ProofOfCommonLawStatus
        : true;
      const validLifeEvent = this.internalDependant.LifeEventProof
        ? !!this.internalDependant.Qualification?.ProofOfLifeEvent
        : true;
      return (
        validStudent &&
        validDisabled &&
        validCommonLaw &&
        validLifeEvent &&
        this.valid
      );
    },
  },
  watch: {
    dependantSaved: {
      handler() {
        if (this.dependantSaved && !this.saving) {
          this.cancel();
        }
      },
      deep: true,
    },
    dependant: {
      handler() {
        this.internalDependant = JSON.parse(JSON.stringify(this.dependant));
        if (typeof this.dependant?.Relationship === "string") {
          const relationship = this.enumModels?.Relationship?.find(
            (x) =>
              x.Id?.toUpperCase() === this.dependant.Relationship?.toUpperCase()
          );
          this.internalDependant.Relationship = relationship;
        }
        this.internalDependant.DateOfBirth =
          this.dependant?.DateOfBirth?.split("T")[0];
        this.internalDependant.EffectiveDate = "";
        this.$refs.form?.resetValidation();
        this.internalRelationships = JSON.parse(
          JSON.stringify(this.enumModels?.Relationship)
        );
        if (this.internalDependant?.Qualification) {
          this.internalDependant.Student =
            !!this.internalDependant?.Qualification?.ProofOfSchooling;
          this.internalDependant.Disabled =
            !!this.internalDependant?.Qualification?.ProofOfDisability;
          // this.internalDependant.CommonLawProof =
          //   !!this.internalDependant?.Qualification?.ProofOfCommonLawStatus;
          // this.internalDependant.LifeEventProof =
          //   !!this.internalDependant?.Qualification?.ProofOfLifeEvent;
        }
        this.clearProofs = false;
      },
      immediate: true,
    },
  },
  // mounted() {
  //   this.internalDependant = JSON.parse(JSON.stringify(this.dependant));
  // },
  methods: {
    cancel() {
      this.$emit("cancel-dialog");
      this.internalDependant = {};
      this.uploadProofDialog = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    uploadProof(isTrue, proofCategory) {
      if (!isTrue) {
        if (proofCategory === "DependantCommonLawStatusProof") {
          delete this.internalDependant?.Qualification?.ProofOfCommonLawStatus;
        }
        if (proofCategory === "DependantLifeEventProof") {
          delete this.internalDependant?.Qualification?.ProofOfLifeEvent;
        }
        if (proofCategory === "DependantDisabilityProof") {
          delete this.internalDependant?.Qualification?.ProofOfDisability;
        }
        if (proofCategory === "DependantSchoolingProof") {
          delete this.internalDependant?.Qualification?.ProofOfSchooling;
        }
        this.internalDependant = JSON.parse(
          JSON.stringify(this.internalDependant)
        );
        return;
      }
      this.proofCategory = proofCategory;
      this.uploadProofDialog = true;
    },
    save() {
      this.uploadProofDialog = false;
      const dependant = JSON.parse(JSON.stringify(this.internalDependant));
      dependant.Relationship = dependant.Relationship?.Id
        ? dependant.Relationship?.Id
        : dependant.Relationship;
      dependant.Gender = dependant.Gender?.value
        ? dependant.Gender?.value
        : dependant.Gender;
      dependant.Signature = this.signature;
      this.$emit("save-dependant", dependant);
      this.clearProofs = true;
    },
    saveDependantProof(proof) {
      console.log("saveDependantProof", proof);
      if (!this.internalDependant?.Qualification) {
        this.internalDependant.Qualification = {};
      }
      if (proof?.ProofType === "DependantCommonLawStatusProof") {
        this.internalDependant.Qualification.ProofOfCommonLawStatus = proof;
      } else if (proof?.ProofType === "DependantDisabilityProof") {
        this.internalDependant.Qualification.ProofOfDisability = proof;
      } else if (proof?.ProofType === "DependantSchoolingProof") {
        this.internalDependant.Qualification.ProofOfSchooling = proof;
      } else if (proof?.ProofType === "DependantLifeEventProof") {
        this.internalDependant.Qualification.ProofOfLifeEvent = proof;
      }
      this.uploadProofDialog = false;
      this.internalDependant = JSON.parse(
        JSON.stringify(this.internalDependant)
      );
    },
    cancelDependantProofDialog() {
      this.uploadProofDialog = false;
      // console.log(this.proofCategory);
      switch (this.proofCategory) {
        case "DependantDisabilityProof":
          this.internalDependant.Disabled = false;
          break;
        case "DependantSchoolingProof":
          this.internalDependant.Student = false;
          break;
        case "DependantCommonLawStatusProof":
          this.internalDependant.CommonLawProof = false;
          break;
        case "DependantLifeEventProof":
          this.internalDependant.LifeEventProof = false;
          break;
      }
      this.proofCategory = null;
    },
    removeProof(doNotRemove, type) {
      if (doNotRemove) return;
      // if (!this.internalDependant?.Qualification) {
      //   this.internalDependant.Qualification = {};
      // }
      switch (type) {
        case "DependantDisabilityProof":
          delete this.internalDependant?.Qualification?.ProofOfDisability;
          break;
        case "DependantSchoolingProof":
          delete this.internalDependant?.Qualification?.ProofOfSchooling;
          break;
        case "DependantCommonLawStatusProof":
          delete this.internalDependant?.Qualification?.ProofOfCommonLawStatus;
          break;
        case "DependantLifeEventProof":
          delete this.internalDependant?.Qualification?.ProofOfLifeEvent;
          break;
      }
      this.internalDependant = JSON.parse(
        JSON.stringify(this.internalDependant)
      );
    },
    validate() {
      this.$refs.form.validate();
    },
    checkRequiredProofs() {
      if (
        this.internalDependant?.Relationship?.IsSpouse &&
        !this.isRegistrationCompletion
      ) {
        this.internalDependant.CommonLawProof = true;
      } else {
        this.internalDependant.CommonLawProof = false;
      }
      if (
        this.internalDependant?.Relationship?.IsChild &&
        !this.isRegistrationCompletion
      ) {
        this.internalDependant.LifeEventProof = true;
      } else {
        this.internalDependant.LifeEventProof = false;
      }
      this.internalDependant = JSON.parse(
        JSON.stringify(this.internalDependant)
      );
      this.validate();
    },
  },
};
</script>
<style scoped>
.right-text-input >>> input {
  text-align: right;
}
.v-input--is-disabled {
  color: rgba(0, 0, 0, 0.87);
}
.chekbox-no-margin /deep/ .v-input--checkbox {
  margin-top: 0 !important;
}
</style>
