<template>
  <div>
    <v-form v-model="valid">
      <v-container class="py-4">
        <v-row align-content="center">
          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.firstname')"
              v-model="internalMember.FirstName"
              :rules="[$rules.required, $rules.validName]"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-text-field
              disabled
              outlined
              :value="internalMember.Dob?.split('T')[0]"
              :label="
                $t('components.registration_completion_form.date_of_birth')
              "
              append-icon="mdi-calendar"
              readonly
              class="required"
            ></v-text-field>
            <!-- <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.middlename')"
              v-model="internalMember.MiddleName"
              :rules="[$rules.required, $rules.validLastName]"
              class="required"
            ></no-autocomplete-textfield> -->
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.lastname')"
              v-model="internalMember.LastName"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.gender')"
              v-model="internalMember.Gender"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              outlined
              disabled
              maxlength="4"
              :label="$t('components.member_validation_form.sin')"
              v-model="internalMember.SIN"
              type="number"
              :rules="sinRules"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="
                $t('components.registration_completion_form.marital_status')
              "
              v-model="internalMember.MaritalStatus"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="
                $t('components.registration_completion_form.employer_name')
              "
              v-model="internalMember.MemberCorresLocName"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="py-0">
            <v-text-field
              disabled
              outlined
              :value="internalMember.HireDate?.split('T')[0]"
              :label="
                $t('components.registration_completion_form.date_of_hire')
              "
              append-icon="mdi-calendar"
              readonly
              class="required"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.local_union')"
              v-model="internalMember.LocalUnionNo"
              class="required"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="py-0">
            <v-text-field
              disabled
              outlined
              :value="internalMember.JoinDate?.split('T')[0]"
              :label="$t('components.registration_completion_form.date_joined')"
              append-icon="mdi-calendar"
              readonly
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    member: Object,
    savingImage: Boolean,
  },
  data() {
    return {
      dateMenu: false,
      internalMember: {},
      valid: false,
      showPhotoCrop: false,
      sinRules: [
        this.$rules.required,
        (v) => (v && v.length === 4) || "Must be 4 digits",
      ],
    };
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.member !== null) {
      this.internalMember = JSON.parse(
        JSON.stringify(this.member ? this.member : {})
      );
    }
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-btn__content .v-icon {
  margin-right: 0 !important;
}
</style>
