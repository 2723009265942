<template>
  <material-card-sba-small
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="warning"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.items.provider_search.title')"
  >
    <v-card-text>
      <v-form v-model="valid" class="pa-4 pl-2 pt-2">
        <v-row no-gutters justify="center">
          <v-col class="pa-0" cols="10">
            <v-col cols="12" class="text-left pa-0">
              <label
                for="location"
                class="font-weight-bold text-uppercase input-label"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.provider_search.location"
                  )
                }}</label
              >
              <!-- <no-autocomplete-textfield
                clearable
                hide-details
                name="location"
                dense
                outlined
                v-model="location"
                :rules="[$rules.required]"
                class="required rounded-lg search-input mr-2"
              ></no-autocomplete-textfield> -->
              <v-text-field
                name="location"
                hide-details
                dense
                outlined
                v-model="theLocation"
                append-icon="close"
                @click:append="doClearLocation"
                class="required rounded-lg search-input mr-2"
                id="pac-input-dashboard"
              ></v-text-field>
              <gmap-autocomplete
                placeholder="This is a placeholder text"
                @place_changed="setPlace"
                class="d-none"
              >
              </gmap-autocomplete>
            </v-col>
          </v-col>
          <v-col cols="1" class="pa-0 mb-1" align-self="end">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  depressed
                  @click="useCurrentLocation"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="warning" small>mdi-crosshairs-gps</v-icon>
                </v-btn>
              </template>
              <span>{{
                $t(
                  `components.vue_material_dashboard.items.provider_search.use_current_location`
                )
              }}</span>
            </v-tooltip>
          </v-col>
          <v-col class="pa-0" cols="10">
            <v-col cols="12" class="text-left mt-1 pa-0">
              <label
                for="service"
                class="text-uppercase font-weight-bold input-label"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.provider_search.service"
                  )
                }}</label
              >
              <!-- <no-autocomplete-textfield
                clearable
                hide-details
                dense
                name="service"
                outlined
                v-model="service"
                :rules="[$rules.required]"
                class="mr-2 rounded-lg search-input"
              /> -->
              <v-autocomplete
                name="service"
                :loading="loadingTypes"
                :menu-props="{ contentClass: 'text-left' }"
                hide-details
                dense
                outlined
                v-model="theKeyword"
                id="ps-input-dashboard"
                :items="providerTypes"
                item-text="Benefit"
                item-value="Benefit"
                class="required mr-2 rounded-lg search-input"
                :rules="[$rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-col>
          <v-col cols="1" class="pa-0 mb-1" align-self="end">
            <v-btn
              min-width="40"
              color="secondary"
              @click="search"
              :disabled="!theKeyword || !theLocation"
              ><v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- <GmapMarker
        ref="myMarker"
        :position="google && new google.maps.LatLng(1.38, 103.8)"
      /> -->
    </v-card-text>
  </material-card-sba-small>
</template>

<script>
import MaterialCardSbaSmall from "@/components/VueMaterialDashboard/material/MaterialCardSmallSbaDashboard.vue";
// import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "@/event-bus.js";
import { gmapApi } from "vue2-google-maps";

export default {
  components: {
    MaterialCardSbaSmall,
    // NoAutocompleteTextfield,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      valid: false,
      location: null,
      service: null,
      loading: false,
      loadingTypes: false,
      providerType: null,
      keywordToSearch: null,
      address: null,
      theLocation: "",
      theKeyword: "",
      providerTypes: [],
      cityState: "",
      curPlace: null,
      geocoder: null,
      myCityData: null,
      autocomplete: null,
      myCoordinates: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    google: gmapApi,
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    google: {
      handler(v) {
        if (v) {
          this.initMapAutocomplete();
        }
      },
      immediate: true,
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    this.getProviderTypes();
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    // eventBus.$on("googleInit", () => {
    //   this.initMapAutocomplete();
    // });

    // this.initMapAutocomplete();

    eventBus.$on("mapAddress", (payload) => {
      this.updateAddressFromMap(payload);
    });
  },
  methods: {
    ...mapActions("data", ["getProviderServices"]),
    async search() {
      this.loading = true;
      this.$router.push({
        path: "provider-search",
        query: {
          location: this.theLocation,
          service: this.theKeyword,
        },
      });
    },

    async useCurrentLocation() {
      try {
        this.myCoordinates = await this.$getLocation();

        this.geocoder.geocode(
          { location: this.myCoordinates },
          // eslint-disable-next-line no-unused-vars
          (results, status) => {
            if (results) {
              eventBus.$emit("mapAddress", results);
            }
          }
        );
      } catch (error) {
        console.log("no Location access granted", error);
      }
      if (!this.myCoordinates.lat) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("latitude", position.coords.latitude);
            console.log("longitude", position.coords.longitude);
            this.myCoordinates = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
          (error) => {
            console.log("error message:", error.message);
          }
        );
      }
    },

    formatProviderTypes(types) {
      const providerTypes = types.map((t) =>
        this.$helpers.translate2(
          t,
          "GoogleSearchName",
          this.$vuetify.lang.current
        )
      );
      return providerTypes.sort();
    },

    async getProviderTypes() {
      try {
        this.loadingTypes = true;
        const data = await this.getProviderServices({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
        });
        if (data && data.length) {
          this.providerTypes = this.formatProviderTypes(data);
          this.theKeyword = this.providerTypes[0];
        } else this.providerTypes = [];
        // this.totalCount = data["@odata.count"];
      } catch (error) {
        this.loadingTypes = false;
        // const mockProviderServices = [
        //   {
        //     PlanId: "ba830167-1fec-43fc-a50c-2ebc78ff6c25",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "86f1990c-145e-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "86f1990c-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Chiropractor",
        //         Id: "d9a8f88e-145e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "86f1990c-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Chiropractor",
        //         Id: "d9a8f7c6-145e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ba830167-1fec-43fc-a50c-2ebc78ff6c25",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "86f19830-145e-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "86f19830-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Chiropodist/Podiatrist",
        //         Id: "d9a8f708-145e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "86f19830-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Chiropodist/Podiatrist",
        //         Id: "d9a8f60e-145e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ba830167-1fec-43fc-a50c-2ebc78ff6c25",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "86f1943e-145e-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "86f1943e-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Brace-Custom",
        //         Id: "d9a8f028-145e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "86f1943e-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Brace-Custom",
        //         Id: "d9a8f0fa-145e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ba830167-1fec-43fc-a50c-2ebc78ff6c25",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "86f19542-145e-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "86f19542-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Casts/Walkers",
        //         Id: "d9a8f294-145e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "86f19542-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Casts/Walkers",
        //         Id: "d9a8f1cc-145e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ba830167-1fec-43fc-a50c-2ebc78ff6c25",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "86f191b4-145e-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "86f191b4-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Acupuncturist",
        //         Id: "d9a8ed08-145e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "86f191b4-145e-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Acupuncturist",
        //         Id: "d9a8ef2e-145e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ee2756aa-e0b4-4926-8bc3-6b67d267570a",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "a0c0bc38-0c8d-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "a0c0bc38-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Chiropractor",
        //         Id: "08e9360a-0c8e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "a0c0bc38-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Chiropractor",
        //         Id: "08e936f0-0c8e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ee2756aa-e0b4-4926-8bc3-6b67d267570a",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "a0c0b558-0c8d-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "a0c0b558-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Acupuncturist",
        //         Id: "08e92bba-0c8e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "a0c0b558-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Acupuncturist",
        //         Id: "08e92de0-0c8e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ee2756aa-e0b4-4926-8bc3-6b67d267570a",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "a0c0bb5c-0c8d-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "a0c0bb5c-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Chiropodist/Podiatrist",
        //         Id: "08e934b6-0c8e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "a0c0bb5c-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Chiropodist/Podiatrist",
        //         Id: "08e933ee-0c8e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ee2756aa-e0b4-4926-8bc3-6b67d267570a",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "a0c0b95e-0c8d-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "a0c0b95e-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Brace-Custom",
        //         Id: "08e92fac-0c8e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "a0c0b95e-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Brace-Custom",
        //         Id: "08e92eda-0c8e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        //   {
        //     PlanId: "ee2756aa-e0b4-4926-8bc3-6b67d267570a",
        //     JsonCoverageIds: null,
        //     IsActive: true,
        //     Id: "a0c0ba6c-0c8d-11eb-adc1-0242ac120002",
        //     Translations: [
        //       {
        //         ProviderServiceId: "a0c0ba6c-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "fr",
        //         GoogleSearchName: "Casts/Walkers",
        //         Id: "08e93326-0c8e-11eb-adc1-0242ac120002",
        //       },
        //       {
        //         ProviderServiceId: "a0c0ba6c-0c8d-11eb-adc1-0242ac120002",
        //         LanguageCode: "en",
        //         GoogleSearchName: "Casts/Walkers",
        //         Id: "08e9306a-0c8e-11eb-adc1-0242ac120002",
        //       },
        //     ],
        //   },
        // ];
        // this.providerTypes = this.formatProviderTypes(mockProviderServices);
        this.error = error;
        console.log(error);
      } finally {
        this.loadingTypes = false;
      }
    },

    setPlace() {
      console.log("place changed");
    },

    // toss this over to the Map component
    launchPlacesSearch() {
      eventBus.$emit("launchKeywordSearch", this.theKeyword);
    },

    doClearLocation() {
      this.theLocation = "";
      this.curPlace = "";
    },

    getGeoJSON() {
      this.getCityData(this.cityState);
    },

    cityStateHelper(str) {
      let city = "";
      let state = "";

      if (!str) return "";

      const addressParts = str.split(",");
      city = addressParts[0];
      // just the first two characters...
      state = addressParts[1].trim().substring(0, 2);
      return `${city},${state}`;
    },

    getCityState(address) {
      let cs = "";

      // this is not foolproof.. do a deep dive into the address components
      // and their type, if you are going to be more thorough.. I am just
      // working off of the formatted address
      // more info at https://developers.google.com/maps/documentation/geocoding/start?csw=1
      address.map((curAddress) => {
        if (curAddress.types[0] === "locality") {
          cs = this.cityStateHelper(curAddress.formatted_address);
        }
      });
      return cs;
    },

    // getCityData(theCity) {
    //   let apiPath = "https://nominatim.openstreetmap.org/search.php";

    //   let params = {
    //     q: `${theCity},USA`,
    //     polygon_geojson: 1,
    //     format: "json",
    //   };

    //   console.log(params);

    //   let polygonType = null;

    //   axios.get(apiPath, { params: params }).then((response) => {
    //     let responseIndex = this.getCityIndex(response.data);
    //     let geoJSONDataChunk = response.data[responseIndex];

    //     polygonType = geoJSONDataChunk.geojson.type || null;

    //     // geojson data from http://nominatim.openstreetmap.org/ needs
    //     // to be wrapped, so that the google addGeoJson() call
    //     // can handle it properly
    //     const geoConf = {
    //       type: "FeatureCollection",
    //       features: [
    //         { type: "Feature", geometry: geoJSONDataChunk.geojson, id: "city" },
    //       ],
    //     };

    //     this.myCityData = new google.maps.Data();
    //     this.myCityData.addGeoJson(geoConf, "city");
    //     this.myCityData.setStyle({
    //       fillColor: "green",
    //       fillOpacity: 0.1,
    //       strokeWeight: 1,
    //     });

    //     // we want to get geometry into our local objects, and create
    //     // google geometry objects with it.. hence the naming...
    //     let localCity = this.myCityData.getFeatureById("city");
    //     let allLocalMultiPolys = [];
    //     let localGeometry = null;

    //     // if a Polygon, our coordinates are at coordinates[0]
    //     // if a MultiPolygon, we loop through coordinates[0]...coordinates[n]
    //     if (polygonType === "Polygon") {
    //       localGeometry = localCity.getGeometry();
    //       let numArrays = localGeometry.getLength();

    //       allLocalMultiPolys[0] = [];

    //       for (let i = 0; i < numArrays; i++) {
    //         allLocalMultiPolys[0].push(localGeometry.getAt(i).getArray());
    //       }

    //       this.googleGeometryMultiPoly[0] = new google.maps.Polygon({
    //         paths: allLocalMultiPolys[0],
    //       });

    //       this.numGeometries = 1;
    //     } else {
    //       localGeometry = localCity.getGeometry();
    //       let localGeometryArray = localCity.getGeometry().getArray();

    //       localGeometryArray.map((item, i) => {
    //         allLocalMultiPolys[i] = [];
    //         let curPolyNum = item.getLength();
    //         for (let j = 0; j < curPolyNum; j++) {
    //           allLocalMultiPolys[i].push(item.getAt(j).getArray());
    //         }
    //         this.googleGeometryMultiPoly[i] = [];
    //         this.googleGeometryMultiPoly[i] = new google.maps.Polygon({
    //           paths: allLocalMultiPolys[i],
    //         });
    //       });

    //       this.numGeometries = localGeometry.getLength();
    //     }

    //     // send boundary geometry and polygon data to our Map component
    //     eventBus.$emit("sendCityData", {
    //       coordinates: this.myCityData,
    //       multiPoly: this.googleGeometryMultiPoly,
    //       numGeometries: this.numGeometries,
    //     });
    //   });
    // },

    /*
     ** getCityIndex -  figure out which chunk of data contains city boundaries,
     ** or punt to administrative (we cant assume that data[0]
     */
    getCityIndex(data) {
      let retIndex = null;
      let adminIndex = null;

      console.log(data);

      data.map((curData, index) => {
        // console.log(curData.type);
        switch (curData.type) {
          case "city":
            retIndex = index;
            break;
          case "administrative":
            adminIndex = index;
            break;
          default:
            break;
        }
      });

      // ... we punt to administrative...
      if (retIndex === null && adminIndex !== null) {
        retIndex = adminIndex;
      }

      return retIndex;
    },

    // eslint-disable-next-line no-unused-vars
    updateAddressFromMap(payload) {
      if (payload === null || payload === undefined) return;
      // TODO: error checking...
      this.theLocation = payload[0].formatted_address;
      // if we have something here, it makes the 'get city' button visible
      this.curPlace = this.theLocation;
      // parse the address to get city and state
      this.cityState = this.getCityState(payload);
    },

    initMapAutocomplete() {
      // eslint-disable-next-line no-undef
      this.geocoder = new this.google.maps.Geocoder();

      const input = document.getElementById("pac-input-dashboard");
      // eslint-disable-next-line no-undef
      this.autocomplete = new this.google.maps.places.Autocomplete(input);

      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.autocomplete, "place_changed", () => {
        this.curPlace = this.autocomplete.getPlace();
        this.theLocation = this.curPlace.formatted_address;

        if (typeof this.curPlace.formatted_address !== "undefined") {
          // parse the address to get city and state
          this.cityState = this.cityStateHelper(
            this.curPlace.formatted_address
          );

          // we need to update the map
          eventBus.$emit("newTextAddress", this.curPlace);
        }
      });
      console.log("google autocomplete initialized");
    },
  },
};
</script>
<style scoped>
.search-input >>> fieldset {
  height: 40px;
}
.search-input >>> .v-input__append-inner {
  margin-top: 6px !important;
}
.v-input__icon--append {
  cursor: pointer;
  pointer-events: auto;
}
.v-tooltip__content {
  max-width: 120px !important;
  font-size: 10px;
  line-height: 1.1;
}
.input-label {
  font-size: 12px;
}
</style>
