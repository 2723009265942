<template>
  <v-row justify="space-around">
    <v-row justify="space-around" class="pt-0">
      <v-col
        cols="12"
        sm="6"
        md="4"
        class="pt-0 pr-5"
        v-for="(video, i) in helpVideos"
        :key="i"
      >
        <v-row>
          <v-col>
            <v-row>
              <v-col class="text-start" cols="11">
                <v-btn text class="text-none text-h6" @click="expand(i)">
                  <span class="custom-underline">{{
                    $vuetify.lang.current === "fr"
                      ? video.TitleFr
                      : video.TitleEn
                  }}</span>
                </v-btn>
                <div>
                  {{
                    $vuetify.lang.current === "fr"
                      ? video.DescriptionFr
                      : video.DescriptionEn
                  }}
                </div>
              </v-col>
              <v-col cols="1">
                <v-icon @click="expand(i)">{{
                  expanded[i] ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-col v-if="expanded[i]">
                <video-player :options="videoOptions(i)" />
              </v-col>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue";
export default {
  name: "HelpVideos",
  components: {
    VideoPlayer,
  },
  props: {
    helpVideos: {
      type: Array,
    },
  },
  data() {
    return {
      expanded: [],
    };
  },
  methods: {
    expand(i) {
      this.$set(this.expanded, i, !this.expanded[i]);
    },
    videoOptions(i) {
      console.log(i);
      let source =
        this.$vuetify.lang.current === "fr"
          ? this.helpVideos[i].SourceFr
          : this.helpVideos[i].SourceEn;
      let options = {
        autoplay: true,
        controls: true,
        fluid: true,
        sources: [
          {
            src: source,
            type: "video/mp4",
          },
        ],
      };
      return options;
    },
  },
};
</script>

<style lang="scss" scoped></style>
