<template>
  <v-card flat max-height="180" max-width="300" class="pa-0">
    <v-card-text>
      <v-col
        v-show="card.Name"
        cols="12"
        style="text-align: center"
        :id="cardId"
        class="animate__animated animate__faster benefit-card"
      >
        <v-img :src="card.Source" contain @load="loading = false" />
        <v-progress-circular
          class="mt-16"
          color="info"
          size="40"
          v-if="loading"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    card: Object,
    position: Boolean,
    cardId: {
      type: String,
      default: "benefitCard",
    },
  },
  watch: {
    position: {
      handler(v) {
        this.moveCard(v);
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    moveCard(positionBottom) {
      const card = document.getElementById(this.cardId);
      if (card) {
        card.classList.remove("animate__fadeIn");
        card.classList.add("animate__fadeOut");
        if (positionBottom) {
          setTimeout(() => {
            card.style.transform = "translateY(-50%)";
            card.classList.remove("animate__fadeOut");
            card.classList.add("animate__fadeIn");
          }, 300);
        } else {
          setTimeout(() => {
            card.style.transform = "translateY(0px)";
            card.classList.remove("animate__fadeOut");
            card.classList.add("animate__fadeIn");
          }, 300);
        }
      }
    },
  },
};
</script>
<style scoped>
.benefit-card {
  cursor: pointer;
}
</style>
