<template>
  <v-container fluid class="pa-0 px-2">
    <fetched-address
      :edit-mode="editMode"
      :fetched-address="fetchedAddress"
      :saving="savingMember"
      @set-edit-mode="setEditMode"
    />

    <template v-if="editMode">
      <template v-if="useCanadaPost">
        <v-row no-gutters align="center">
          <v-icon color="primary"> mdi-magnify </v-icon>
          <span class="text-title-1 ml-1 secondary--text bolder-text">
            {{ $t("components.address_expansion_panel.search_for_address") }}
          </span>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" lg="3" class="py-0">
            <v-select
              :label="$t('components.address_expansion_panel.country')"
              v-model="selectedCountry"
              @change="resetAddressLines"
              :items="enumModels.Country"
              :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
              item-value="value"
              :rules="[$rules.required]"
              class="required"
              return-object
            ></v-select>
          </v-col>
          <template v-if="selectedCountry">
            <v-col
              cols="12"
              md="8"
              lg="9"
              v-if="selectedCountry.isOther"
              class="py-0"
            >
              <v-autocomplete
                clearable
                :items="countries"
                :label="$t('components.address_expansion_panel.country')"
                :rules="[$rules.required]"
                v-model="otherCountry"
                @change="
                  searchResults = [];
                  resetAddressLines();
                "
                item-text="text"
                item-value="value"
                class="required"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              :md="selectedCountry.isOther ? 12 : 8"
              :lg="selectedCountry.isOther ? 12 : 9"
              class="py-0 text--secondary"
              v-if="
                (selectedCountry.isOther && otherCountry) ||
                !selectedCountry.isOther
              "
            >
              <v-autocomplete
                :label="
                  $t(
                    'components.address_expansion_panel.start_typing_your_address'
                  )
                "
                v-model="selectedAddress"
                :items="searchResults"
                return-object
                :loading="isAddressSearchLoading"
                :search-input.sync="searchValue"
                item-text="Description"
                item-value="Id"
                clearable
                append-outer-icon="mdi-magnify"
                ref="addressList"
              ></v-autocomplete>
            </v-col>
          </template>
        </v-row>

        <!-- Verify lookup civic address form -->
        <address-entry-form
          :selected-country="selectedCountry"
          :fetched-address="fetchedAddress"
          :address-category="addressCategory"
          :member-saved="memberSaved"
          :error-message="errorMessage"
          :valid.sync="valid"
          :member="internalMember"
          :retry="retry"
          @update-member="internalMember = $event"
        />
      </template>
    </template>

    <template v-if="editMode">
      <v-row v-if="editMode && isNotFirstSearch">
        <v-col cols="12" class="secondary--text text-left pt-0">
          <a
            @click="setAddressCategory('Civic')"
            class="custom-underline"
            v-if="addressCategory === 'Non-Civic'"
          >
            {{ $t("components.address_expansion_panel.address_civic") }}
          </a>
          <a
            @click="setAddressCategory('Non-Civic')"
            class="custom-underline"
            v-if="addressCategory === 'Civic'"
          >
            {{ $t("components.address_expansion_panel.address_non_civic") }}
          </a>
        </v-col>
      </v-row>
      <v-row justify="end" v-if="!hideSave">
        <v-btn
          class="mr-2 mb-2"
          :loading="savingMember"
          color="primary"
          @click="save"
          :disabled="!isValid"
        >
          {{ $t("globals.save") }}
        </v-btn>
        <v-btn :disabled="savingMember" text @click="cancel" class="mr-3">
          {{ $t("globals.cancel") }}
        </v-btn>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import countries from "@/countries";
import streetTypes from "@/streetTypesNeospin";
import streetDirections from "@/streetDirections";
import streetTypesList from "@/streetTypesList";
import streetTypesMostCommon from "@/streetTypesMostCommon";
import streetTypesMostCommonList from "@/streetTypesMostCommonList";
import AddressEntryForm from "./AddressEntryForm.vue";
import FetchedAddress from "./FetchedAddress.vue";
export default {
  components: { AddressEntryForm, FetchedAddress },

  props: {
    member: Object,
    savingMember: Boolean,
    hideSave: Boolean,
    memberSaved: Boolean,
    updateMemberAddress: Boolean,
    cancelAddressEdit: Boolean,
    errorMessage: Array,
  },

  data() {
    return {
      error: null,
      countries: countries,
      streetTypes: streetTypes,
      streetTypesList: streetTypesList,
      streetTypesMostCommon: streetTypesMostCommon,
      streetTypesMostCommonList: streetTypesMostCommonList,
      streetDirections: streetDirections,
      editMode: false,
      dateMenu: false,
      isNotFirstSearch: false,
      internalMember: { Address: {} },
      valid: false,
      useCanadaPost: true,
      selectedCountry: undefined,
      searchValue: undefined,
      searchResults: [],
      selectedAddress: undefined,
      isAddressSearchLoading: false,
      isAddressRetrieveLoading: false,
      fetchedAddress: undefined,
      canadaPostAddress: undefined,
      otherCountry: null,
      retry: false,
      addressCategories: [
        "Civic",
        "Non-Civic",
        "Non-Civic US",
        "Non-Civic International",
      ],
      addressCategory: "Civic",
    };
  },

  computed: {
    ...mapGetters("common", [
      "enumModels",
      "settings",
      "canadaPostStreetTypes",
      "canadaPostStreetTypesFR",
      "usStreetTypes",
    ]),

    isValid() {
      return this.valid;
    },
  },

  watch: {
    member: {
      handler(v) {
        if (v) {
          this.internalMember = JSON.parse(JSON.stringify(v));
          this.setMemberData(v);
          //if (this.hideSave) this.editMode = true;
        }
      },
      deep: true,
      immediate: true,
    },

    updateMemberAddress: {
      handler(v) {
        if (v) {
          this.save();
        }
      },
      immediate: true,
    },

    valid: {
      handler(v) {
        this.$emit("update-valid", v);
      },
      immediate: true,
    },

    fetchedAddress: {
      handler(v) {
        if (!v) this.$emit("update-valid", false);
      },
    },

    memberSaved: {
      handler(v) {
        if (v) {
          console.log("member saved");
          this.cancel();
          this.editMode = false;
        } else {
          console.log("member is not saved");
          this.retry = false;
          this.editMode = true;
        }
      },
    },

    cancelAddressEdit: {
      handler(v) {
        if (v) this.cancel();
      },
    },

    searchValue: {
      async handler(v) {
        if (!v) {
          return;
        }
        if (this.selectedCountry?.code === "CAN")
          this.setAddressCategory("Civic");
        if (this.isAddressSearchLoading || this.isAddressRetrieveLoading) {
          return;
        }
        this.isAddressSearchLoading = true;
        try {
          this.searchResults = (
            await this.canadaPostFind({
              payload: {
                Key: this.settings.CanadaPostKey,
                SearchTerm: this.searchValue,
                Country: this.selectedCountry.isOther
                  ? this.otherCountry
                  : this.selectedCountry.code,
                LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
              },
            })
          ).data.Items.map((o) => {
            o.Description = `${o.Text} ${o.Description}`;
            return o;
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.isAddressSearchLoading = false;
        }
      },
    },

    selectedAddress: {
      async handler(v) {
        this.$emit("clear-error");
        if (!v || v === this.fetchedAddress) {
          return;
        }
        this.isNotFirstSearch = true;
        if (this.isAddressRetrieveLoading) {
          return;
        }
        if (this.selectedAddress.Next === "Find") {
          this.isAddressSearchLoading = true;
          try {
            let payload = {
              Key: this.settings.CanadaPostKey,
              SearchTerm: `${this.selectedAddress.Description}`,
              Country: this.selectedCountry.isOther
                ? this.otherCountry
                : this.selectedCountry.code,
              LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
            };
            if (this.selectedAddress.Id) {
              payload.LastId = this.selectedAddress.Id;
            }
            this.searchResults = (
              await this.canadaPostFind({
                payload: payload,
              })
            ).data.Items.map((o) => {
              o.Description = `${o.Text} ${o.Description}`;
              return o;
            });
            this.searchValue = null;
            this.onFocus();
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressSearchLoading = false;
          }
        } else if (this.selectedAddress.Next === "Retrieve") {
          this.isAddressRetrieveLoading = true;
          try {
            this.canadaPostAddress = (
              await this.canadaPostRetrieve({
                payload: {
                  Key: this.settings.CanadaPostKey,
                  Id: this.selectedAddress.Id,
                  LanguagePreference: this.$vuetify.lang.current.toUpperCase(),
                },
              })
            ).data.Items;
            //this.fetchedAddress = this.canadaPostAddress[0].Label;
            // this.valid = true;
          } catch (error) {
            console.log(error);
          } finally {
            this.isAddressRetrieveLoading = false;
            // this.editMode = false;
            this.fillMemberAddress();
            this.internalMember.Address.Category = this.addressCategory;
            this.fetchedAddress = this.$helpers.formatCcwippAddress(
              this.internalMember.Address,
              this.$vuetify.lang.current.toUpperCase()
            );
          }
        }
      },
    },

    selectedCountry: {
      handler(v) {
        this.$emit("clear-error");
        if (v === null || v === undefined) {
          this.addressCategory = "Civic";
          this.internalMember.Address.Country = "CANADA";
          this.internalMember.Address.Category = "Civic";
          return;
        }
        if (this.editMode) {
          this.fetchedAddress = undefined;
          this.selectedAddress = undefined;
          this.canadaPostAddress = undefined;
        }
        if (v.isOther) {
          this.addressCategory = "Non-Civic International";
          this.internalMember.Address.Country = null;
          this.internalMember.Address.Category = "Non-Civic International";
        } else {
          if (v.code === "CAN") {
            this.addressCategory = "Civic";
            this.internalMember.Address.Country = "CANADA";
            this.internalMember.Address.Category = "Civic";
          } else if (v.code === "USA") {
            this.addressCategory = "Non-Civic US";
            this.internalMember.Address.Country = "UNITED STATES";
            this.internalMember.Address.Category = "Non-Civic US";
          }
        }
        this.searchResults = [];
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.member) {
      this.internalMember = JSON.parse(JSON.stringify(this.member));
      this.setMemberData(this.member);
      if (this.fetchedAddress) this.$emit("update-valid", true);
    }
    // if (this.fetchedAddress?.length) this.valid = true;
  },

  methods: {
    ...mapActions("data", ["canadaPostFind", "canadaPostRetrieve"]),

    setMemberData(member) {
      // console.log("setting member data");
      this.fetchedAddress = this.$helpers.formatCcwippAddress(
        member.Address,
        this.$vuetify.lang.current.toUpperCase()
      );
      if (member?.Address?.Country?.toUpperCase() === "CANADA") {
        this.selectedCountry = this.enumModels.Country.filter(
          (o) => o.code === "CAN"
        )[0];
        if (
          member.Address?.StreetType &&
          typeof member.Address.StreetType === "string"
        ) {
          let streetTypes = this.canadaPostStreetTypes.filter(
            (o) => o.abbreviation === member.Address.StreetType
          );
          let ccwippStreetTypes = [];
          if (streetTypes.length > 0) {
            ccwippStreetTypes = this.enumModels.StreetType.filter(
              (o) =>
                o.textEn?.toUpperCase() === streetTypes[0].name?.toUpperCase()
            );
          } else {
            streetTypes = this.canadaPostStreetTypesFR.filter(
              (o) => o.abbreviation === member.Address.StreetType
            );
            if (streetTypes.length > 0) {
              ccwippStreetTypes = this.enumModels.StreetType.filter(
                (o) =>
                  o.textFr?.toUpperCase() === streetTypes[0].name?.toUpperCase()
              );
            }
          }
          if (ccwippStreetTypes.length > 0) {
            this.internalMember.Address.StreetType = ccwippStreetTypes[0];
          }
        }
      } else if (member?.Address?.Country?.toUpperCase() === "UNITED STATES") {
        this.selectedCountry = this.enumModels.Country.filter(
          (o) => o.code === "USA"
        )[0];
      } else if (!member?.Address?.Country) {
        this.selectedCountry = this.enumModels.Country.filter(
          (o) => o.code === "CAN"
        )[0];
      } else {
        this.selectedCountry = this.enumModels.Country.filter(
          (o) => o.isOther
        )[0];
      }
      // this.otherCountry = member.Address.Country;
    },

    setAddressCategory(category) {
      this.addressCategory = category;
      if (category === "Civic") {
        if (!this.internalMember.Address) this.internalMember.Address = {};
        this.internalMember.Address.Category = "Civic";
        this.internalMember.Address.AddressLine = null;
        this.internalMember.Address.AddressLine2 = null;
        this.internalMember.Address.AddressLine3 = null;
        this.fillMemberAddress();
      }
      if (category === "Non-Civic") {
        this.retry = true;
        if (!this.internalMember.Address) this.internalMember.Address = {};
        this.internalMember.Address.Category = "Non-Civic";
        // this.internalMember.Address.AddressLine = null;
        // this.internalMember.Address.AddressLine2 = null;
        // const address = this.internalMember.Address;
        // this.internalMember.Address.AddressLine = `${
        //   address.AptUnitSuite
        //     ? address.AptUnitSuite + "-"
        //     : "" + address.StreetNumber
        // } ${address.StreetName} ${address.StreetType}`;
        // this.internalMember.Address.AddressLine2 = `${address.City} ${address.Province} ${address.PostalCode}`;
        // this.internalMember.Address.AddressLine3 = null;
        // this.internalMember.Address.PostalCode = null;
        // this.internalMember.Address.AptUnitSuite = null;
        // this.internalMember.Address.StreetNumber = null;
        // this.internalMember.Address.StreetName = null;
        // this.internalMember.Address.StreetType = null;
        this.fillMemberAddress();
      }
    },

    async save() {
      // if (this.useCanadaPost) {
      //   if (this.canadaPostAddress) {
      //     this.fillMemberAddress();
      //   }
      // }
      // this.internalMember.Address.SavingAddress = true;
      // if (this.selectedCountry && this.selectedCountry.code === "USA") {
      //   this.internalMember.Address.SavingUsAddress = true;
      // }
      this.internalMember.Address.Category = this.addressCategory;
      this.internalMember.EffectiveDate = this.$helpers.formattedDateNow();
      this.$emit("save-address", this.internalMember);
      this.searchResults = [];
    },

    cancel() {
      this.$emit("address-edited", false);
      this.editMode = false;
      this.fetchedAddress = this.$helpers.formatCcwippAddress(
        this.member.Address,
        this.$vuetify.lang.current.toUpperCase()
      );
      this.internalMember.Address = this.member.Address;
      this.setMemberData(this.member);
    },

    // eslint-disable-next-line no-unused-vars
    getAddressData(addressData, placeResultData, id) {
      // this.errorMessage = "";
      this.address = addressData;
      this.internalMember.Address.StreetNumber = addressData.street_number;
      console.log(addressData);
      const streetArr = addressData.route.split(" ");
      const maybeDirection = streetArr[streetArr.length - 1];
      if (
        this.enumModels.StreetDirection.findIndex(
          (d) => d === maybeDirection
        ) !== -1
      ) {
        streetArr.pop();
        this.internalMember.Address.StreetName = streetArr.join(" ");
        this.internalMember.Address.StreetDirection = maybeDirection;
      } else {
        this.internalMember.Address.StreetName = addressData.route;
        this.internalMember.Address.StreetDirection = null;
      }

      const maybeStreetType = streetArr[streetArr.length - 1];
      if (
        this.enumModels.StreetType.findIndex((d) => d === maybeStreetType) !==
        -1
      ) {
        streetArr.pop();
        this.internalMember.Address.StreetName = streetArr.join(" ");
        this.internalMember.Address.StreetType = maybeStreetType;
      } else {
        this.internalMember.Address.StreetName = addressData.route;
        this.internalMember.Address.StreetType = null;
      }

      this.internalMember.Address.City = addressData.locality;
      this.internalMember.Address.Province =
        addressData.administrative_area_level_1;
      this.internalMember.Address.PostalCode = addressData.postal_code;
      this.internalMember.Address.Country = addressData.country;
    },

    setEditMode() {
      this.$emit("address-edited", true);
      this.editMode = true;
      this.useCanadaPost = true;
      this.fetchedAddress = undefined;
      this.selectedAddress = undefined;
      this.otherCountry = undefined;
      if (this.$refs.addressSearch) {
        this.$refs.addressSearch.clear();
      }
    },

    setEditNonCivic() {
      this.editMode = true;
      this.addressCategory = "Non-Civic";
      this.setAddressCategory("Non-Civic");
      this.useCanadaPost = false;
      this.fetchedAddress = undefined;
      this.selectedAddress = undefined;
      if (this.$refs.addressSearch) {
        this.$refs.addressSearch.clear();
      }
    },

    fillMemberAddress() {
      if (!this.canadaPostAddress || !this.canadaPostAddress[0]) return;
      const address = this.canadaPostAddress[0];

      const streetNumber = parseInt(address?.BuildingNumber);
      const streets = this.$helpers.parseStreetByCountryAndLanguage(
        address,
        this.selectedCountry
      );
      // console.log("streets", streets);
      const lines = address.Label.split(/\r?\n/);
      switch (this.addressCategory) {
        case "Civic": {
          if (streets.length === 0) {
            this.addressCategory = "Non-Civic";
            return this.saveNonCivic(address, lines);
          } else if (streets.length === 1) {
            return this.saveCivic(address, lines, streetNumber, streets);
          } else {
            // throw `multiple matches found! ${JSON.stringify(streets)}`;
            // console.log(`multiple matches found! ${JSON.stringify(streets)}`);
            let street = streets.filter((s) =>
              streetTypesMostCommonList.includes(s.streetType.toUpperCase())
            );
            return this.saveCivic(address, lines, streetNumber, street);
            // throw `multiple matches found! ${JSON.stringify(streets)}`;
          }
        }
        case "Non-Civic": {
          return this.saveNonCivic(address, lines);
        }
        case "Non-Civic US": {
          return this.saveNonCivicUs(address, lines);
        }
        case "Non-Civic International": {
          return this.saveNonCivicInternational(address, lines);
        }
      }
    },

    saveCivic(address, lines, streetNumber, streets) {
      const addressPayload = {
        StreetNumber: !isNaN(streetNumber) ? streetNumber : null,
        StreetName: this.$helpers.replaceFrenchChars(streets[0].streetName),
        FullStreetName: address.Street,
        StreetType: streets[0].streetType,
        City: this.$helpers.replaceFrenchChars(address.City),
        Province: address.Province,
        PostalCode: address.PostalCode,
        Country: address.CountryName.toUpperCase(),
        AptUnitSuite: address.SubBuilding,
        AddressLine: null,
        AddressLine2: null,
        AddressLine3: null,
        // AddressLine: lines.length > 0 ? lines[0] : null,
        // AddressLine2: lines.length > 1 ? lines[1] : null,
        // AddressLine3: lines.length > 2 ? lines[2] : null,
      };
      this.internalMember.Address = addressPayload;
      // console.log(
      //   "saving civic street name",
      //   this.internalMember.Address.StreetName
      // );
      if (addressPayload.StreetName?.split(" ")?.length > 1) {
        const { streetName, streetDirection } = this.$helpers.cleanUpStreetName(
          addressPayload.StreetName
        );
        this.internalMember.Address.StreetName = streetName;
        this.internalMember.Address.StreetDirection = streetDirection;
        console.log("cleaned up name", this.internalMember.Address.StreetName);
      }
    },

    saveNonCivic(address, lines) {
      const addressPayload = {
        City: this.$helpers.replaceFrenchChars(address.City),
        Country: address.CountryName.toUpperCase(),
        AddressLine: lines.length > 0 ? lines[0] : null,
        //AddressLine2: lines.length > 1 ? lines[1] : null,0
        //AddressLine3: lines.length > 2 ? lines[2] : null,
        PostalCode: address.PostalCode,
        // PostalCode: null,
        Province: address.Province,
      };
      this.internalMember.Address = addressPayload;
    },

    saveNonCivicUs(address, lines) {
      const addressPayload = {
        // Province: null,
        // PostalCode: null,
        StreetName: address.Street,
        FullStreetName: address.Street,
        StreetNumber: parseInt(address?.BuildingNumber),
        AptUnitSuite: address?.SubBuilding || null,
        Province: null,
        PostalCode: null,
        ZipCode: address.PostalCode,
        City: address.City,
        Country: address.CountryName.toUpperCase(),
        State: address.ProvinceName,
        AddressLine: lines.length > 0 ? lines[0] : null,
        // AddressLine2: lines.length > 1 ? lines[1] : null,
        // AddressLine3: lines.length > 2 ? lines[2] : null,
      };
      this.internalMember.Address = addressPayload;
    },

    saveNonCivicInternational(address, lines) {
      const postalCode = address.PostalCode ? address.PostalCode : "";
      const province = address.Province
        ? address.Province
        : address.ProvinceName
        ? address.ProvinceName
        : "";
      const city = address.City ? address.City : "";
      const addressLine = lines.length > 0 ? lines[0] : null;
      const addressLine2 = lines.length > 1 ? lines[1] : null;
      const line2 = lines.length > 2 ? lines[2] : "";

      let addressLine3 = "";
      if (
        line2 &&
        line2.toUpperCase().includes(address.CountryName.toUpperCase())
      ) {
        addressLine3 = line2
          .toUpperCase()
          .replace(address.CountryName.toUpperCase(), "");
      } else {
        addressLine3 = line2;
      }

      if (
        !addressLine.includes(postalCode) &&
        !addressLine2.includes(postalCode) &&
        !addressLine3.includes(postalCode)
      ) {
        addressLine3 = addressLine3
          ? addressLine3 + " " + postalCode
          : postalCode;
      }

      // if (
      //   !addressLine?.toUpperCase().includes(city.toUpperCase()) &&
      //   !addressLine2?.toUpperCase().includes(city.toUpperCase()) &&
      //   !addressLine3?.toUpperCase().includes(city.toUpperCase())
      // ) {
      //   addressLine3 = addressLine3 ? addressLine3 + " " + city : city;
      // }

      if (
        !addressLine?.toUpperCase().includes(province.toUpperCase()) &&
        !addressLine2?.toUpperCase().includes(province.toUpperCase()) &&
        !addressLine3?.toUpperCase().includes(province.toUpperCase())
      ) {
        addressLine3 = addressLine3 ? addressLine3 + " " + province : province;
      }

      const addressPayload = {
        Province: province,
        PostalCode: postalCode,
        City: city,
        // Province: null,
        // PostalCode: null,
        // City: null,
        StreetName: address.Street,
        FullStreetName: address.Street,
        StreetNumber: parseInt(address?.BuildingNumber),
        AptUnitSuite: address?.SubBuilding || null,
        Country: address.CountryName.toUpperCase(),
        AddressLine: addressLine,
        AddressLine2: addressLine2,
        AddressLine3: addressLine3,
      };
      this.internalMember.Address = addressPayload;
    },

    resetAddressLines() {
      this.internalMember.Address.StreetNumber = null;
      this.internalMember.Address.StreetName = null;
      this.internalMember.Address.StreetType = null;
      this.internalMember.Address.StreetDirection = null;
      this.internalMember.Address.City = null;
      this.internalMember.Address.Province = null;
      this.internalMember.Address.PostalCode = null;
      this.internalMember.Address.State = null;
      this.internalMember.Address.ZipCode = null;
      this.internalMember.Address.AptUnitSuite = null;
      this.internalMember.Address.AddressLine = null;
      this.internalMember.Address.AddressLine2 = null;
      this.internalMember.Address.AddressLine3 = null;
    },

    // isValid() {
    //   let valid =
    //     this.addressCategory !== null && this.addressCategory !== undefined;
    //   if (this.useCanadaPost) {
    //     valid =
    //       valid &&
    //       this.fetchedAddress !== null &&
    //       this.fetchedAddress !== undefined &&
    //       this.canadaPostAddress !== null &&
    //       this.canadaPostAddress !== undefined &&
    //       this.canadaPostAddress.length > 0 &&
    //       this.valid &&
    //       this.fetchedAddress.includes(this.canadaPostAddress[0].Label);
    //   } else {
    //     console.log(this.addressCategory, this.valid);
    //     valid = valid && this.valid;
    //   }
    //   return valid;
    // },

    unsavedAddress() {
      let memberAddress = this.$helpers.formatCcwippAddress(
        this.member.Address,
        this.$vuetify.lang.current.toUpperCase()
      );
      return this.fetchedAddress !== memberAddress;
    },

    onFocus() {
      this.$refs.addressList.isMenuActive = true;
    },
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.bolder-text {
  font-weight: 600;
}
</style>
