<template>
  <v-card
    v-bind="$attrs"
    v-on="$listeners"
    style="position: relative"
    class="material-card ma-0"
    :height="cardHeight"
  >
    <v-card-title
      v-if="title || widgetIcon"
      :class="`${color} py-2
        px-2
        white--text
        font-weight-medium
        text-subtitle-2
        text-subtitle-sm-1
        rounded-b-0`"
    >
      <v-row no-gutters>
        <v-col cols="auto" class="text-left">
          <v-icon dark>{{ widgetIcon }}</v-icon>
        </v-col>
        <v-col
          v-if="title"
          class="text-left ml-2 card-title text-uppercase"
          align-self="center"
        >
          {{ title }}
        </v-col>
      </v-row>
    </v-card-title>
    <slot />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    widgetId: String,
    widgetIcon: String,
    title: String,
    size: Number,
    height: Number,
    color: String,
    order: Number,
    maxOrderValue: Number,
    tabbed: Boolean,
    moreText: Boolean,
    isBenefitCardsWidget: Boolean,
  },
  data() {
    return {
      customizeDialog: false,
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      widgetAction: undefined,
      addWidgetDialog: false,
      resetWidgetsDialog: false,
      widgetToAdd: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        //console.log("order change", v);
        this.$emit("update:order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  computed: {
    ...mapGetters("common", ["availableDashboardItemTypes"]),
    cardHeight() {
      return this.$vuetify.breakpoint.mdAndUp
        ? this.height * 200
        : this.isBenefitCardsWidget
        ? "100%"
        : this.height * 200;
    },
  },
  methods: {
    ...mapActions("common", ["resetDashboardItems"]),
    // closeClick() {
    //   console.log("click close");
    // },
    // removeWidget() {
    //   this.customizeDialog = false;
    //   this.$emit("remove-widget", this.widgetId);
    // },
    addRemoveResetWidget(action) {
      console.log("action", action, this.widgetAction);
      if (action === "remove") this.$emit("remove-widget", this.widgetId);
      if (action === "add") this.addWidgetDialog = true;
      if (action === "reset") this.resetWidgetsDialog = true;
      this.widgetToAdd = "";
      this.widgetAction = null;
    },
    addWidget(widgetName) {
      const widget = this.availableDashboardItemTypes.find(
        (w) => w.Name === widgetName
      );
      this.addWidgetDialog = false;
      this.$emit("add-widget", widget);
    },
    cancel() {
      this.resetWidgetsDialog = false;
    },
    resetLayout() {
      this.resetDashboardItems();
      this.resetWidgetsDialog = false;
    },
  },
};
</script>
<style scoped>
.v-card {
  margin-top: 10px;
  margin-bottom: 30px;
}
.v-dialog .v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
/* .v-card__title {
  padding: 0 !important;
  position: relative;
  display: block;
  word-break: keep-all;
} */
.v-card-icon {
  border-radius: 3px;
  padding: 20px;
  top: -14px;
  max-width: 64px;
  left: 15px;
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  position: absolute;
}
.v-card__title h4 {
  color: #3c4858;
  font-weight: 300;
  font-size: 1.125rem;
  padding: 12px 5px 10px 10px;
  display: block;
  text-align: right;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-tooltip__content {
  width: 100px !important;
  font-size: 12px !important;
}
.material-card >>> .v-card__text {
  padding: 0 0px !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 16px 16px !important;
}
.v-expansion-panel-header {
  min-height: 32px;
}
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  z-index: 2;
  position: absolute !important;
  bottom: 0;
  width: 100%;
}
.card-title {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}
@media only screen and (min-width: 300px) and (max-width: 700px) {
  .card-title {
    font-size: 10px !important;
  }
}
</style>
