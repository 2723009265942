<template>
  <v-card :loading="saving ? 'secondary' : false">
    <v-card-title class="primary white--text mb-6">
      <v-icon dark class="mr-2">mdi-account-alert-outline</v-icon>
      {{ title }}
    </v-card-title>
    <v-card-title class="px-5 mb-5">
      <v-row>
        <v-col>{{ dialogMessage }}</v-col>
      </v-row>
    </v-card-title>
    <v-card-actions class="pa-4 pt-0">
      <v-btn
        v-show="!deleteAll"
        :loading="saving"
        color="primary"
        class="mr-3"
        @click="deleteBeneficiary"
        >{{ $t("globals.delete") }}</v-btn
      >
      <v-btn
        v-show="deleteAll"
        :loading="saving"
        color="primary"
        class="mr-3"
        @click="deleteAllBeneficiaries"
        >{{ $t("globals.delete_all") }}</v-btn
      >
      <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    beneficiary: Object,
    saving: Boolean,
    title: String,
    dialogMessage: String,
    memberSaved: Boolean,
    deleteAll: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    cancel() {
      this.$emit("cancel-dialog");
    },
    deleteBeneficiary() {
      this.$emit("delete-beneficiary", this.beneficiary);
      this.cancel();
    },
    deleteAllBeneficiaries() {
      this.$emit("delete-all-beneficiaries");
      this.cancel();
    },
  },
};
</script>
