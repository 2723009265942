<template>
  <section class="pdf-content">
    <section class="report-body">
      <section class="report-info">
        <section class="image-container">
          <img
            v-if="$vuetify.lang.current === 'fr'"
            class="user-image"
            :src="require('@/assets/CCWIPP-primary-logo.png')"
            alt="Logo"
            ref="logo"
          />
          <img
            v-else
            class="user-image"
            :src="require('@/assets/CCWIPP-primary-logo.png')"
            alt="Logo"
            ref="logo"
          />
        </section>

        <section class="user-details">
          <h4 class="user-name">{{ reportData?.Broker }}</h4>

          <v-row class="detail-container" justify="end">
            <v-col class="detail-label pa-0">{{
              reportData?.HeaderTitle
            }}</v-col>
          </v-row>
        </section>
      </section>
      <div class="hr-divider"></div>

      <v-row
        no-gutters
        style="margin: 10px 20px 10px 20px"
        class="justify-space-around"
      >
        <v-col cols="5">
          <v-row
            no-gutters
            class="text-left"
            v-for="(p, i) in Object.entries(reportData?.MemberDetailsLeft)"
            :key="i"
          >
            <v-col style="font-size: 12px" v-if="p[1]">{{ p[0] }}</v-col>
            <v-col style="font-size: 12px" v-if="p[1]"
              ><strong>{{ p[1] }}</strong></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="6">
          <v-row
            no-gutters
            class="text-left"
            v-for="(p, i) in Object.entries(reportData?.MemberDetailsRight)"
            :key="i"
          >
            <v-col style="font-size: 12px; padding-left: 50px" v-if="p[1]">
              {{ p[0] }}
            </v-col>
            <v-col
              v-if="p[1]"
              cols="auto"
              style="font-size: 12px; text-align: right; max-width: 200px"
              ><strong>{{ p[1] }}</strong></v-col
            >
          </v-row>
        </v-col>
      </v-row>

      <div class="hr-divider"></div>

      <section class="pdf-item">
        <v-row class="report-title" no-gutters>
          {{ reportData?.Title }}
        </v-row>
        <v-row class="report-note" no-gutters>
          <span>{{ reportData?.Subtitle }}</span>
          <span style="margin-left: 5px">
            <strong>
              (
              {{ reportData?.LatestRemittanceDate }}
              ).
            </strong>
          </span>
        </v-row>

        <div v-for="(table, i) in reportData?.Tables" :key="'table-' + i">
          <div v-if="table.Show">
            <div v-if="i > 2" class="html2pdf__page-break" />
            <v-row class="report-subtitle" no-gutters v-if="table.TableTitle">
              {{ `${table.TableTitle}&nbsp;`
              }}<strong>
                {{ $helpers.formatDate(table.TableDate) || "" }}</strong
              >
            </v-row>
            <v-data-table
              dense
              :headers="table.Headers"
              :items="table.Items"
              class="table"
              hide-default-footer
            >
              <template v-slot:[`item.MonthlyPension`]="{ item }">
                <td class="text-subtitle-2">
                  {{ $helpers.toCurrency(item?.MonthlyPension) }}
                </td>
              </template>
              <template v-slot:[`item.AnnualPension`]="{ item }">
                <td class="text-subtitle-2">
                  {{ $helpers.toCurrency(item?.AnnualPension) }}
                </td>
              </template>
              <template v-slot:[`item.MemberContribution`]="{ item }">
                <td class="text-subtitle-2">
                  {{ $helpers.toCurrency(item?.MemberContribution) }}
                </td>
              </template>
              <!-- <template v-slot:[`item.TotalMemberContributions`]="{ item }">
                <td class="text-subtitle-2 white--text">
                  {{ $helpers.toCurrency(item?.TotalMemberContributions) }}
                </td>
              </template> -->
            </v-data-table>
          </div>
        </div>
      </section>

      <section class="report-footer">
        <p>
          {{ $t("components.pension_pdf_content.footer_text1") }}
          <!-- <a
            v-if="$vuetify.lang.current === 'fr'"
            href="mailto:contactez-nous@rrecc.ca"
            class="custom-underline"
          >
            contactez-nous@rrecc.ca
          </a>
          <a v-else href="mailto:contactus@ccwipp.ca" class="custom-underline">
            contactus@ccwipp.ca
          </a>
          {{ $t("components.pension_pdf_content.footer_text2") }} -->
        </p>
        <v-row style="padding-bottom: 10px; padding-top: 10px">
          <v-col
            class="address"
            cols="6"
            v-for="(address, i) in footerAddresses"
            :key="'address-' + i"
          >
            <div>{{ address.line1 }}</div>
            <div>{{ address.line2 }}</div>
            <div>{{ address.line3 }}</div>
            <div>{{ address.line4 }}</div>
            <div>{{ address.line5 }}</div>
          </v-col>
        </v-row>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    reportData: {
      typr: Object,
      default: () => {},
    },
    memberStatus: String,
  },
  data() {
    return {
      footerAddresses: [
        {
          line1: "110-61 International Boulevard",
          line2: "Toronto, Ontario M9W 6K4",
          line3: "contact@ccwipp.ca",
          line4: "1-888-7CCWIPP",
          line5: "ccwipp.ca",
        },
        {
          line1: "201-1200, boul. Crémazie Est",
          line2: "Montréal, QC  H2P 3A5",
          line3: "contactez@rrecc.ca",
          line4: "1-888-97RRECC",
          line5: "rrecc.ca",
        },
      ],
    };
  },
  computed: {
    table1Names() {
      return this.getColumnNames(this.reportData.Table1);
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
  methods: {
    getColumnNames(data) {
      const names = new Set();
      for (const row of data) {
        for (const key of Object.keys(row)) {
          names.add(key);
        }
      }
      return names;
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  color: red !important;
}

.primary {
  background-color: red;
}
.pdf-content {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  width: 100%;
  background: #fff;

  .report-body {
    .report-info {
      display: flex;

      padding-bottom: 0px;
      justify-content: space-between;

      .image-container {
        width: 350px;
        height: 80px;
        overflow: hidden;
        position: relative;
        margin-right: 25px;

        .user-image {
          max-width: 350px;
          height: auto;
        }
      }

      .user-details {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        font-size: 18px;

        .user-name {
          margin-bottom: 10px;
          flex-shrink: 0;
        }

        .detail-container {
          flex-shrink: 0;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .detail-label {
            font-weight: 600;
            margin-right: 10px;
          }
          .detail-text {
            font-size: 13px;
          }
        }
      }
    }

    .hr-divider {
      border-bottom: 1px solid rgb(163, 163, 163);
    }

    .report-title {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0 5px 0;
    }
    .report-note {
      font-size: 14px;
      font-weight: 400;
      margin: 5px 0 5px 0;
    }

    .report-subtitle {
      font-size: 16px;
      font-weight: 400;
      margin: 5px 0;
    }
  }
}
</style>
<style scoped>
.table >>> th {
  background-color: red !important;
  color: white !important;
  border: 1px solid rgb(187, 187, 187);
  height: 25px !important;
}
.table >>> td {
  border: 1px solid rgb(187, 187, 187);
  height: 30px !important;
  text-align: center;
}
.footer-image {
  max-width: 150px;
  height: auto;
  margin-left: 50%;
  transform: translateX(-50%);
}
.report-footer {
  margin-top: 20px;
  padding-right: 5px;
}
p {
  font-size: 12px;
  margin: 10px 0;
  text-align: justify;
}
.address {
  font-size: 12px;
  text-align: left;
  padding-bottom: 6px;
  padding-top: 4px;
}
.address-image {
  font-size: 12px;
  text-align: left;
  margin: auto;
}
.custom-underline {
  text-decoration: none;
  padding-bottom: 0px;
  border-bottom: 1px solid;
  cursor: pointer;
}
</style>
