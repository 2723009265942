var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',_vm._g(_vm._b({staticClass:"material-card ma-0",staticStyle:{"position":"relative"},attrs:{"height":_vm.cardHeight}},'v-card',_vm.$attrs,false),_vm.$listeners),[(_vm.title || _vm.widgetIcon)?_c('v-card-title',{class:`${_vm.color} py-2
      px-2
      white--text
      font-weight-medium
      text-subtitle-2
      text-subtitle-sm-1
      rounded-b-0`},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(_vm.widgetIcon))])],1),(_vm.title)?_c('v-col',{staticClass:"text-left ml-2 card-title text-uppercase",attrs:{"align-self":"center"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1)],1):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }