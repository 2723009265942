<template>
  <v-col
    class="pa-0 px-1"
    cols="auto"
    :class="`
                  animate__animated
                  animate__fadeIn
                  animate__faster
                  ${disabled ? 'disabled' : ''}`"
  >
    <v-card
      height="150"
      width="200"
      class="py-1 mt-4 rounded-lg"
      outlined
      @drop="getDroppedFiles"
      @dragenter.prevent="dragEnter"
      @dragleave.prevent="dragLeave"
      @dragover.prevent="dragOver"
    >
      <div
        v-if="(proof === null || proof === undefined) && !disabled"
        :class="`
                required-text
                animate__animated animate__heartBeat animate__fast`"
      >
        {{ $t("globals.required") }}
      </div>
      <div
        v-if="disabled"
        :class="`
                not-required-text`"
      >
        {{ $t("globals.not_required") }}
      </div>
      <div
        v-if="proof !== null && proof !== undefined && !disabled"
        class="required-text"
      >
        <v-icon
          :class="`
                          animate__animated animate__heartBeat
                          success--text`"
          >mdi-check</v-icon
        >
      </div>
      <v-card-text
        @dragover.prevent
        :class="`
                fill-height
                d-flex
                justify-space-between
                flex-column
                align-center py-0`"
      >
        <v-icon class="d-block" color="secondary"> mdi-tray-arrow-down </v-icon>
        <div v-if="!uploading || !uploadReceipt">
          <div class="small-text secondary--text">
            <span
              v-if="dragEntered && proof === null && !disabled"
              class="error--text"
              >{{ $t("components.new_claim.library.drop_here") }}</span
            >
            <span v-else>{{
              $t("components.new_claim.library.drag_and_drop")
            }}</span>
            <div class="pt-1">{{ $t("globals.or") }}</div>
          </div>

          <v-btn
            v-if="
              proofs.length === 0 && (proof === null || proof === undefined)
            "
            color="accent"
            dark
            small
            height="42"
            class="rounded-lg select-btn"
            :disabled="disabled"
            @click="
              uploadReceipt = true;
              $emit('upload-receipt', {
                type: uploadType,
                source: 'upload',
              });
            "
          >
            {{ $t("globals.select_files") }}
          </v-btn>
        </div>

        <div v-if="uploading && uploadReceipt">
          <div class="small-text mt-n2 mb-8 secondary--text">
            {{ $t("components.new_claim.library.uploading_files") }}
          </div>
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>

        <v-menu
          offset-y
          v-if="proofs.length !== 0 && !(uploading && uploadReceipt)"
          :disabled="proof !== null && proof !== undefined"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="accent"
              dark
              small
              height="42"
              class="rounded-lg select-btn"
              :disabled="disabled"
              max-width="150"
            >
              <span v-if="proof === null || proof === undefined">
                {{ $t("globals.select_files") }}
              </span>
              <div v-else>
                <span class="upload-name">{{ proof?.UploadName }}</span>
                <v-icon
                  class="pl-1 action-icon"
                  @click.stop="$emit('preview-receipt', proof)"
                  >visibility</v-icon
                >
                <v-icon
                  class="action-icon"
                  @click.stop="$emit('remove-receipt', proofType)"
                  >close</v-icon
                >
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title
                  @click="
                    uploadReceipt = true;
                    $emit('upload-receipt', {
                      type: uploadType,
                      source: 'upload',
                    });
                  "
                  >{{
                    $t("components.new_claim.library.upload_files")
                  }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  @click="
                    $emit('upload-receipt', {
                      type: uploadType,
                      source: 'library',
                    })
                  "
                  >{{
                    $t("components.new_claim.library.select_from_library")
                  }}</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <!-- Case when proof is attached from inital profile data -->
        <v-menu
          offset-y
          v-if="
            proofs.length === 0 &&
            proof !== null &&
            proof !== undefined &&
            !(uploading && uploadReceipt)
          "
          :disabled="proof !== null && proof !== undefined"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="accent"
              dark
              small
              :loading="loading"
              height="42"
              class="rounded-lg select-btn"
              :disabled="disabled"
              max-width="150"
            >
              <span v-if="proof === null || proof === undefined">
                {{ $t("globals.select_files") }}
              </span>
              <div v-else>
                <span class="upload-name pt-1">{{
                  proof?.FileSegments[0].FileName
                }}</span>
                <v-icon
                  class="pl-1 action-icon"
                  @click.stop="$emit('preview-proof-files', proof)"
                  >visibility</v-icon
                >
                <v-icon
                  class="action-icon"
                  @click.stop="$emit('remove-receipt', proofType)"
                  >close</v-icon
                >
              </div>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title
                  @click="
                    uploadReceipt = true;
                    $emit('upload-receipt', {
                      type: uploadType,
                      source: 'upload',
                    });
                  "
                  >{{
                    $t("components.new_claim.library.upload_files")
                  }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  @click="
                    $emit('upload-receipt', {
                      type: uploadType,
                      source: 'library',
                    })
                  "
                  >{{
                    $t("components.new_claim.library.select_from_library")
                  }}</v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <div
          class="upload-label text-uppercase"
          :class="proof === null || proof === undefined ? 'error--text' : ''"
        >
          {{ title }}
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  props: {
    proof: {
      type: Object,
      default: () => {},
    },
    proofs: {
      type: Array,
      default: () => [],
    },
    valid: Boolean,
    uploading: Boolean,
    loading: Boolean,
    uploadType: String,
    proofType: String,
    title: String,
    disabled: Boolean,
  },
  data() {
    return {
      uploadReceipt: false,
      dragEntered: false,
    };
  },
  watch: {
    uploading: {
      handler(v) {
        if (!v) this.uploadReceipt = false;
      },
      immediate: true,
    },
  },
  methods: {
    getShortUploadName(name) {
      if (name?.includes("Disability")) {
        return "Disability Proof";
      }
      if (name?.includes("SchoolingProof")) {
        return "Schooling Proof";
      }

      if (name?.includes("CommonLaw")) {
        return "Status Proof";
      }
    },
    getDroppedFiles(e) {
      console.log("item dropped", e);
      this.uploadReceipt = true;
      this.dragEntered = false;
      this.$emit("upload-receipt", {
        type: this.uploadType,
        source: "upload",
        droppedFiles: e.dataTransfer.files,
      });
    },
    dragEnter() {
      this.dragEntered = true;
    },
    dragOver() {
      this.dragEntered = true;
    },
    dragLeave() {
      this.dragEntered = false;
    },
  },
};
</script>
<style scoped>
.upload-label {
  font-size: 0.7rem;
  font-weight: 700;
  line-height: 1;
}
.v-sheet--outlined {
  border: 3px dashed var(--accent) !important;
}
.disabled /deep/ .v-sheet--outlined {
  border: 3px dashed rgba(0, 0, 0, 0.12);
}
.upload-checkbox /deep/ label {
  max-width: 155px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.1;
}
.small-text {
  max-width: 155px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.1;
}
.note-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.1;
}
.v-tooltip__content {
  max-width: 120px !important;
}
.preview-image:hover {
  cursor: pointer;
}
.upload-name {
  font-size: 8px !important;
  max-width: 80px !important;
  padding-top: 2px !important;
}
.required-text {
  position: absolute;
  top: 2px;
  right: 5px;
  color: var(--error);
  font-size: 12px;
}
.not-required-text {
  position: absolute;
  top: 2px;
  right: 5px;
  color: rgb(173, 173, 173);
  font-size: 12px;
}
.segment {
  font-size: 10px;
  font-weight: 600;
}
.success-icon {
  position: absolute;
  top: 2px;
  right: 5px;
  color: var(--success);
  font-size: 12px;
}
.select-btn /deep/ .v-btn__content {
  position: relative;
  margin: 0 auto;
  min-width: 130px;
  white-space: nowrap;
}
.select-btn /deep/ .upload-name {
  float: left;
  font-size: 12px;
  line-height: 1;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.select-btn.v-btn.v-btn--disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.action-icon:hover {
  color: var(--primary);
}
</style>
