<template>
  <material-card-custom
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="primary"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :widgetId="widgetId"
    :title="
      $t('components.vue_material_dashboard.items.my_info_dashboard_item.title')
    "
  >
    <!-- <v-card tile height="1" color="white"></v-card> -->
    <v-progress-circular
      class="mt-16"
      size="50"
      v-if="loading"
      indeterminate
    ></v-progress-circular>
    <v-col
      v-else
      class="pa-0 card-link"
      @click.stop="$router.push('member-information')"
    >
      <v-card
        flat
        tile
        height="60"
        width="100%"
        :color="
          userProfile && userProfile.AccountStatus === 'Active'
            ? 'primary'
            : 'secondary'
        "
        class="white--text rounded-b-0"
      >
        <v-col
          cols="9"
          :class="`float-right
            text-subtitle-1 full-name
            pt-6
            font-weight-medium`"
        >
          {{
            userProfile &&
            userProfile?.FirstName?.toUpperCase() +
              " " +
              userProfile?.LastName?.toUpperCase()
          }}
        </v-col>
      </v-card>
      <v-card
        flat
        width="100%"
        height="145px"
        class="white secondary--text rounded-t-0"
      >
        <v-row no-gutters justify="end" class="mt-2 mr-6 mr-xl-12 pr-xl-6">
          <!-- <v-col cols="9" class="float-right text_title text-right">
            <span class="mr-1">
              {{
                $t(
                  "components.vue_material_dashboard.items.member_information.since"
                )
              }}
            </span>
            <span class="font-weight-medium">
              {{
                userProfile && $helpers.formatDate(userProfile.PlanEntryDate)
              }}
            </span>
          </v-col> -->
          <v-col cols="9" class="text_title pa-0 font-weight-light text-right">
            {{
              $t(
                "components.vue_material_dashboard.items.member_information.membership_id"
              )
            }}:
            <strong class="font-weight-medium">
              {{ userProfile.Certificate }}
            </strong>
          </v-col>
          <v-col cols="9" class="text_title pa-0 font-weight-light text-right">
            {{
              $t(
                "components.vue_material_dashboard.items.member_information.dob"
              )
            }}:
            <strong class="font-weight-medium">
              {{ $helpers.formatDate(userProfile.Dob) }}
            </strong>
          </v-col>
          <v-col
            cols="9"
            class="text_title pa-0 font-weight-light text-right"
            v-if="phone"
          >
            {{ $t("globals.phone") }}:
            <strong class="font-weight-medium">
              {{ phone }}
            </strong>
          </v-col>
          <v-col
            cols="9"
            class="float-right text_title text-right font-weight-light"
            v-for="(line, i) of addressLines"
            :key="'line-' + i"
          >
            <span v-if="i === 0"> {{ $t("globals.address") }}: </span>
            <span class="font-weight-medium">
              {{ line }}
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-avatar
      v-if="!loading"
      class="avatar"
      :size="$vuetify.breakpoint.mdAndUp ? 95 : 75"
      v-show="userProfile.FirstName"
      color="white"
    >
      <img
        v-if="userImage"
        :src="'data:image/jpg;base64,' + userImage"
        alt="member-img"
      />
      <v-avatar v-else color="secondary" size="93">
        <span class="text-h4 white--text">
          {{
            userProfile.FirstName &&
            userProfile.FirstName.charAt(0) +
              (userProfile.LastName && userProfile.LastName.charAt(0))
          }}
        </span>
      </v-avatar>
    </v-avatar>

    <!-- Beneficiaries -->
    <v-card height="200px" flat>
      <v-row no-gutters class="fill-height" align="center">
        <v-col>
          <v-card-text
            class="text-left font-weight-bold"
            v-if="!loading && beneficiariesData.length === 1"
          >
            {{ $t("globals.beneficiary") }}
          </v-card-text>
          <v-card-text
            class="text-left font-weight-bold"
            v-if="!loading && beneficiariesData.length > 1"
          >
            {{ $t("globals.beneficiaries") }}
          </v-card-text>
          <v-card-text class="text-left pb-0">
            <v-row justify="center" v-show="loading">
              <v-progress-circular
                size="50"
                class="mt-10"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
            <v-list three-line max-height="190" class="overflow-y-auto">
              <v-list-item-group>
                <div v-if="!loading && beneficiariesData.length > 0">
                  <template v-for="(beneficiary, i) in beneficiariesData">
                    <v-list-item
                      class="px-0 px-lg-2"
                      :key="i"
                      v-show="beneficiary && beneficiary.Relationship"
                    >
                      <v-list-item-avatar color="primary">
                        <v-icon
                          v-if="beneficiary?.Relationship === 'Estate'"
                          dark
                          >mdi-home-account</v-icon
                        >
                        <v-icon v-else dark>mdi-account</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content class="py-0">
                        <router-link
                          class="secondary--text text-decoration-none"
                          to="member-information"
                        >
                          <v-list-item-title
                            v-if="beneficiary?.Relationship !== 'Estate'"
                          >
                            <span
                              class="font-weight-medium full-name text_title text-uppercase"
                            >
                              {{
                                (beneficiary.FirstName ?? "") +
                                " " +
                                (beneficiary.MiddleName ?? "") +
                                " " +
                                (beneficiary.LastName ?? "")
                              }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-title v-else class="pb-4">
                            <span
                              class="font-weight-medium full-name text_title text-uppercase"
                            >
                              {{ beneficiary.Relationship }}
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="text_subtitle"
                            v-text="
                              beneficiary &&
                              $helpers.formatDate(beneficiary.DateOfBirth)
                            "
                          ></v-list-item-subtitle>
                          <v-list-item-subtitle
                            v-if="beneficiary?.Relationship !== 'Estate'"
                            class="text_subtitle"
                            v-text="
                              beneficiary &&
                              $helpers.formatEnumModel(
                                beneficiary.Relationship,
                                'Relationship',
                                $vuetify.lang.current
                              )
                            "
                          ></v-list-item-subtitle>
                        </router-link>
                      </v-list-item-content>

                      <v-list-item-action class="ma-0">
                        <v-col align-self="center" class="px-0">
                          <v-row no-gutters justify="center">
                            <span
                              :class="`pt-1
                                text-subtitle-1
                                grey--text
                                font-weight-medium`"
                            >
                              {{ beneficiary && beneficiary.Percentage }}
                            </span>
                            <v-icon
                              color="grey"
                              class="text-subtitle-1 font-weight-medium pt-1"
                            >
                              mdi-percent-outline
                            </v-icon>
                          </v-row>
                          <v-chip
                            color="success"
                            dark
                            x-small
                            v-show="
                              beneficiary &&
                              beneficiary.BeneficiaryType === 'Primary'
                            "
                          >
                            <span>{{
                              $t(
                                "components.vue_material_dashboard.items.member_information.primary"
                              )
                            }}</span>
                            <!-- <v-icon class="d-flex d-sm-none" x-small>
                              mdi-key
                            </v-icon> -->
                          </v-chip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                to="/life-event/Members/category/2/question/1"
                                color="secondary lighten-2"
                                v-bind="attrs"
                                v-on="on"
                                dark
                                x-small
                                v-show="
                                  beneficiary &&
                                  beneficiary.BeneficiaryType === 'Contingent'
                                "
                              >
                                <span>{{
                                  $t(
                                    "components.vue_material_dashboard.items.member_information.contingent"
                                  )
                                }}</span>
                                <!-- <v-icon class="d-flex d-sm-none" x-small>
                              mdi-key
                            </v-icon> -->
                              </v-chip>
                            </template>
                            <span>{{
                              $t(
                                "components.vue_material_dashboard.items.member_information.contingent_tooltip"
                              )
                            }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-show="i < beneficiariesData.length"
                      :key="i + '-divider'"
                    ></v-divider>
                  </template>
                </div>
                <v-list-item v-else>
                  <v-row justify="center">
                    <div class="text-subtitle-2">
                      {{
                        $t(
                          "components.vue_material_dashboard.items.my_info_dashboard_item.no_beneficiaries1"
                        )
                      }}
                      <router-link
                        to="download-centre-new?doc=registration form"
                      >
                        {{
                          $t(
                            "components.vue_material_dashboard.items.my_info_dashboard_item.no_beneficiaries2"
                          )
                        }}
                      </router-link>
                      {{
                        $t(
                          "components.vue_material_dashboard.items.my_info_dashboard_item.no_beneficiaries3"
                        )
                      }}
                    </div>
                  </v-row>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-row no-gutters class="bottom-btn">
      <v-btn
        class="secondary lighten-2"
        width="150px"
        to="download-centre-new?doc=registration form"
      >
        {{
          $t(
            "components.vue_material_dashboard.items.member_information.update"
          )
        }}
      </v-btn>
    </v-row>
  </material-card-custom>
</template>

<script>
import { mapGetters } from "vuex";
import MaterialCardCustom from "@/components/VueMaterialDashboard/material/MaterialCardCustomDashboard.vue";
import { tokens } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
export default {
  components: {
    MaterialCardCustom,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      loading: true,
      beneficiariesData: [],
      userImage: "",
      telephoneMask: "(###) ### - ####",
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile", "addressLine"]),
    addressLines() {
      let addressLines = [];
      const fetchedAddress = this.$helpers.formatCcwippAddress(
        this.userProfile?.Address
      );
      // if (this.userProfile?.Address?.Category === "Civic") {
      //   addressLines[0] = `${
      //     this.userProfile?.Address?.AptUnitSuite
      //       ? this.userProfile?.Address?.AptUnitSuite + "-"
      //       : ""
      //   }${this.userProfile?.Address?.StreetNumber ?? ""} ${
      //     this.userProfile?.Address?.StreetName ?? ""
      //   } ${this.userProfile.Address?.StreetDirection ?? ""}${
      //     this.userProfile.Address?.StreetType ?? ""
      //   }`.trim();
      //   addressLines[1] = `${
      //     this.userProfile?.Address.City
      //       ? this.userProfile?.Address.City + ", "
      //       : ""
      //   }${this.userProfile?.Address?.Province ?? ""}`.trim();
      //   addressLines[2] = `${
      //     this.userProfile?.Address?.PostalCode
      //       ? this.userProfile?.Address?.PostalCode + ", "
      //       : ""
      //   }${this.userProfile?.Address?.Country ?? ""}`.trim();
      // } else {
      //   addressLines[0] = this.userProfile?.Address?.AddressLine;
      //   addressLines[1] = this.userProfile?.Address?.AddressLine2;
      //   addressLines[2] = this.userProfile?.Address?.AddressLine3;
      //   if (
      //     addressLines[1] &&
      //     !addressLines[1]
      //       .toLowerCase()
      //       .includes(this.userProfile?.Address?.Province.toLowerCase())
      //   ) {
      //     addressLines[1] =
      //       addressLines[1] + ", " + this.userProfile?.Address?.Province;
      //   }
      //   if (
      //     addressLines[2] &&
      //     !addressLines[2]
      //       .toLowerCase()
      //       .includes(this.userProfile?.Address?.Country.toLowerCase())
      //   ) {
      //     addressLines[2] =
      //       addressLines[2] + ", " + this.userProfile?.Address?.Country;
      //   }
      // }
      addressLines = fetchedAddress.split("\n");
      return addressLines;
    },
    phone() {
      let phone =
        this.userProfile.PreferredPhone === "Home"
          ? this.userProfile.HomePhone
          : this.userProfile.MobilePhone;
      return this.formatPhone(phone);
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v !== null && v !== undefined) {
          this.beneficiariesData = v && v.Beneficiaries;
          this.userImage = v && v.Image;
          this.loading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.userProfile) {
      this.userImage = this.userProfile.Image;
      this.beneficiariesData = this.userProfile.Beneficiaries;
    }
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    formatPhone(v) {
      return masker(v, this.telephoneMask, true, tokens);
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 750px) {
  .avatar {
    left: 10px;
  }
}
@media screen and (min-width: 751px) {
  .avatar {
    left: 30px;
  }
}
.avatar {
  position: absolute;
  top: 70px;
  border: 3px white solid;
  z-index: 1;
}
.card-link {
  cursor: pointer;
}
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.v-list-item__title {
  white-space: normal;
}
.text_title {
  font-size: 0.875rem;
}
.text_subtitle {
  font-size: 0.75rem;
}
.v-list::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}

.v-list::-webkit-scrollbar-track-piece {
  background-color: white;
  border-color: white;
  border-width: 2px 7px 2px 7px;
  border-style: solid;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.v-list::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--primary) !important;
  border-color: white;
  border-style: solid;
  border-width: 1px 4px 1px 4px;
}
.v-tooltip__content {
  max-width: 250px !important;
}
</style>
