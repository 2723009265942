<template>
  <div>
    <material-card
      style="z-index: 2"
      :size.sync="internalSize"
      :isPensions="true"
      :height.sync="internalHeight"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :color="`primary`"
      dark
      :title="
        $t('components.vue_material_dashboard.items.pension_summary.title')
      "
    >
      <v-card height="432" flat class="rounded-t-0" light>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-show="!loading"
              class="printer-icon"
              v-bind="attrs"
              v-on="on"
              @click="print"
              color="rgba(0,0,0,0.3)"
              >mdi-printer</v-icon
            >
          </template>
          <span>{{
            $t(
              "components.vue_material_dashboard.items.pension_summary.tooltip_print"
            )
          }}</span>
        </v-tooltip>
        <v-row no-gutters class="fill-height" justify="center">
          <v-col v-if="loading" align-self="center">
            <v-progress-circular size="50" indeterminate></v-progress-circular>
          </v-col>
          <v-col
            v-else
            cols="12"
            class="pa-0 pa-sm-4 pa-xl-8 pt-2 pt-lg-10"
            align-self="center"
          >
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="pensionSummaryData.servicePeriodsData"
              class="table rounded-b-0 mx-0 mx-sm-12"
              dense
              hide-default-footer
            >
              <template v-slot:[`item.name`]="{ item }">
                <td
                  :class="`text-right pr-5 text-subtitle-2 text-xl-subtitle-1 text-uppercase`"
                  style="border: none; width: 300px"
                >
                  {{ item.name }}
                </td>
              </template>
              <template v-slot:[`item.hours`]="{ item }">
                <td class="px-1 text-right text-subtitle-2 text-xl-subtitle-1">
                  {{ $helpers.numberWithCommas(item.hours) }}
                </td>
              </template>
              <template v-slot:[`item.equal`]="{}">
                <td class="px-1 text-right text-subtitle-2 text-xl-subtitle-1">
                  =
                </td>
              </template>
              <template v-slot:[`item.pension`]="{ item }">
                <td class="px-1 text-right text-subtitle-2 text-xl-subtitle-1">
                  {{ "$" + $helpers.numberWithCommas(item.pension) }}
                </td>
              </template>
              <template slot="body.append">
                <tr>
                  <td
                    :class="`
                    text-subtitle-2 text-xl-subtitle-1 text-right text-uppercase thick-border
                    font-weight-bold pl-1 pt-2`"
                  >
                    {{
                      $t(
                        "components.vue_material_dashboard.items.pension_summary.total"
                      )
                    }}
                  </td>
                  <td
                    :class="`
                    px-1
                    text-right text-subtitle-2 text-xl-subtitle-1
                    font-weight-bold
                    thick-border pt-2`"
                  >
                    {{
                      $helpers.numberWithCommas(
                        this.pensionSummaryData.totalCreditedHours
                      )
                    }}
                  </td>
                  <td class="thick-border"></td>
                  <td class="thick-border"></td>
                </tr>
                <v-expand-transition>
                  <tr v-show="$vuetify.breakpoint.lgAndUp && !leftDrawerOpen">
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </v-expand-transition>
                <tr style="">
                  <td
                    :class="`
                    text-subtitle-2 text-xl-subtitle-1 text-right text-uppercase
                    font-weight-bold pl-0`"
                  >
                    {{
                      $t(
                        "components.vue_material_dashboard.items.pension_summary.total_monthly"
                      )
                    }}
                  </td>
                  <td
                    colspan="3"
                    :class="`
                    px-5
                    text-h6
                    font-weight-bold text-right thick-red-border
                    `"
                  >
                    {{
                      "$" +
                      $helpers.numberWithCommas(
                        this.pensionSummaryData.totalMonthlyPension
                      )
                    }}
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
            <v-row
              no-gutters
              justify="end"
              class="pt-5 pr-8 pb-2 white rounded-b-1"
            >
              <v-btn
                class="secondary lighten-2"
                width="150px"
                to="pension-earned-to-date"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.pension_summary.see_details"
                  )
                }}</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </material-card>
    <pdf-generator
      class="pdf-generator"
      :report="reportData"
      :printPdf="printPdf"
      :savePdf="savePdf"
      @pdf-generated="
        printPdf = false;
        savePdf = false;
      "
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
import PdfGenerator from "@/components/PdfGenerator/PdfGenerator.vue";
export default {
  components: {
    MaterialCard,
    PdfGenerator,
  },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      pensionSummaryData: {},
      loading: false,
      printPdf: false,
      savePdf: false,
      reportData: null,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["leftDrawerOpen"]),
    headers() {
      return [
        {
          text: this.$t(
            "components.vue_material_dashboard.items.pension_summary.header.service_period"
          ),
          sortable: false,
          value: "name",
          class: "secondary--texttext-uppercase font-weight-regular d-none",
          width: "150px",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.pension_summary.header.hours"
          ),
          align: "end",
          sortable: false,
          value: "hours",
          class: "secondary--text text-uppercase font-weight-regular d-none",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.pension_summary.header.hours"
          ),
          sortable: false,
          value: "equal",
          class: "secondary--text text-uppercase font-weight-regular d-none",
        },
        {
          text: "Pension",
          align: "end",
          sortable: false,
          value: "pension",
          class: "secondary--text text-uppercase font-weight-regular d-none",
        },
      ];
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getPensionSummaryData"]),
    async loadData() {
      try {
        this.loading = true;
        this.pensionSummaryData = (
          await this.getPensionSummaryData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.pensionSummaryData = {
          totalCreditedHours: "62444.56",
          totalMonthlyPension: "2253.75",
          servicePeriodsData: [
            {
              name: "Up To December, 1999",
              hours: "3125.00",
              pension: "96.88",
            },
            {
              name: "For 1999",
              hours: "1983.00",
              pension: "69.41",
            },
            {
              name: "For 2000 - 2010",
              hours: "24177.50",
              pension: "967.00",
            },
            {
              name: "For 2011-2019",
              hours: "21086.00",
              pension: "769.33",
            },
            {
              name: "For 2020",
              hours: "2022.00",
              pension: "72.79",
            },
          ],
        };
        this.loading = false;
      }
    },
    downloadReport() {
      console.log("Download Report");
    },
    viewReport() {
      console.log("View Report");
    },
    print() {
      console.log("Print Report");
      this.printPdf = true;
    },
  },
};
</script>
<style scoped>
.table >>> tr:hover {
  background-color: transparent !important;
}
hr {
  border: none !important;
}
td {
  border: none !important;
}
td.thick-border {
  border-top: 3px solid black !important;
}
td.thick-red-border {
  border: 3px solid red !important;
}
.printer-icon {
  position: absolute !important;
  top: 15px;
  right: 15px;
}
.ask-ben {
  cursor: pointer;
}
.v-tooltip__content {
  max-width: 120px !important;
  width: 120px !important;
}
.pdf-generator {
  z-index: 11;
  position: fixed;
}
</style>
