<template>
  <v-container fluid class="pa-0">
    <v-data-table
      class="beneficiaries-table"
      :headers="headers"
      :items="internalMember?.BeneficiaryStatement?.Beneficiaries"
      hide-default-footer
      mobile-breakpoint="960"
    >
      <template v-slot:[`item.BeneficiaryTypeId`]="{ item }">
        <td class="text-subtitle-2 text-left">
          {{
            $helpers.formatDrawbridgeEnumModel(
              item?.BeneficiaryTypeId,
              "BeneficiaryType",
              $vuetify.lang.current
            )
          }}
        </td>
      </template>
      <template v-slot:[`item.BeneficiaryRelationshipId`]="{ item }">
        <td class="text-subtitle-2">
          {{
            $helpers.formatDrawbridgeEnumModel(
              item?.BeneficiaryRelationshipId,
              "BeneficiaryRelationship",
              $vuetify.lang.current
            )
          }}
        </td>
      </template>
      <!-- <template v-slot:[`item.FirstName`]="{ item }">
        <td class="text-subtitle-2 text-center">
          {{
            item.FirstName +
            " " +
            (item.MiddleName ? item.MiddleName : "") +
            " " +
            item.LastName
          }}
        </td>
      </template> -->
      <template v-slot:[`item.TitleId`]="{ item }">
        <td class="text-subtitle-2 text-left">
          {{
            $helpers.formatDrawbridgeEnumModel(
              item?.TitleId,
              "Title",
              $vuetify.lang.current
            )
          }}
        </td>
      </template>
      <template v-slot:[`item.SuffixId`]="{ item }">
        <td class="text-subtitle-2 text-left">
          {{
            $helpers.formatDrawbridgeEnumModel(
              item?.SuffixId,
              "Suffix",
              $vuetify.lang.current
            )
          }}
        </td>
      </template>

      <template v-slot:[`item.DateOfBirth`]="{ item }">
        <td class="text-subtitle-2">
          {{ $helpers.formatDate(item.DateOfBirth, "YYYY-MM-DD") }}
        </td>
      </template>

      <!-- <template v-slot:[`item.DateOfDeath`]="{ item }">
        <td class="text-subtitle-2">
          {{ $helpers.formatDate(item.DateOfDeath, "YYYY-MM-DD") }}
        </td>
      </template> -->

      <!-- <template v-slot:[`item.Gender`]="{ item }">
        <td class="text-subtitle-2">
          {{
            $helpers.formatEnumModel(
              item.GenderId,
              "Gender",
              $vuetify.lang.current
            )
          }}
        </td>
      </template> -->

      <template v-slot:[`item.IsPrimary`]="{ item }">
        <td class="text-lowercase">
          <v-row justify="center" class="pb-3">
            <v-checkbox v-model="item.IsPrimary" readonly></v-checkbox>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.IsRevocable`]="{ item }">
        <td class="text-lowercase text-center">
          <v-row justify="center" class="pb-3">
            <v-checkbox v-model="item.IsRevocable" readonly></v-checkbox>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.AddTrustee`]="{ item }">
        <td class="text-lowercase text-center">
          <v-row justify="center" class="pb-3">
            <v-checkbox v-model="item.AddTrustee" readonly></v-checkbox>
          </v-row>
        </td>
      </template>

      <template v-slot:[`item.BeneficiaryType`]="{ item }">
        <td class="text-lowercase">
          <v-chip
            small
            :color="
              item.BeneficiaryType === 'Primary' ? 'success' : 'grey lighten-2'
            "
            class="text-subtitle-2"
          >
            {{ item.BeneficiaryType }}
          </v-chip>
        </td>
      </template>

      <template v-slot:[`item.Percentage`]="{ item }">
        <td class="text-subtitle-1 font-weight-bold px-0" style="width: 50px">
          {{ item.Percentage * 100 + "%" }}
        </td>
      </template>

      <template v-slot:[`item.Trustee`]="{ item }">
        <td>
          <div v-if="item.Trustee">
            {{
              item.Trustee?.FirstName +
              " " +
              (item.Trustee?.LastName ? item.Trustee?.LastName : "")
            }}
          </div>
        </td>
      </template>

      <template v-slot:[`item.Actions`]="{ item }">
        <td
          class="text-subtitle-1 font-weight-bold text-right px-0"
          style="width: 80px"
        >
          <v-btn icon color="primary" @click="editBeneficiary(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="confirmDeleteBeneficiary(item)">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </td>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <!-- Show warning about primary total percentage -->
        <v-row no-gutters class="ma-2 mb-0">
          <v-btn
            text
            @click="add"
            class="ml-2"
            :disabled="remainingPercentage <= 0"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            {{ $t("globals.add") }}
          </v-btn>
          <v-btn
            v-if="beneficiariesBeforeEdited?.length > 0"
            :disabled="saving"
            text
            @click="confirmDeleteAllBeneficiaries"
            color="primary"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
            {{ $t("globals.delete_all") }}
          </v-btn>
        </v-row>
        <v-row
          no-gutters
          justify="center"
          v-if="primaryBeneficiaryPercentageTotal != 1"
        >
          <v-col
            cols="12"
            class="red--text font-weight-bold pt-4 animate__animated animate__shakeX animate__fast"
          >
            {{
              $t(
                "components.beneficiary_sba.primary_beneficiary_percentage_warning"
              )
            }}
          </v-col>
        </v-row>
        <!-- Show warning about contingent total percentage -->
        <v-row
          no-gutters
          justify="center"
          v-if="contingentBeneficiaryPercentageTotal != 1"
        >
          <v-col
            cols="12"
            class="red--text font-weight-bold pt-4 animate__animated animate__shakeX animate__fast"
          >
            {{
              $t(
                "components.beneficiary_sba.contingent_beneficiary_percentage_warning"
              )
            }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          justify="space-around"
          align="center"
          v-if="
            !isRegistrationCompletion &&
            hasBeenEdited &&
            contingentBeneficiaryPercentageTotal === 1 &&
            primaryBeneficiaryPercentageTotal === 1
          "
        >
          <v-col cols="12" class="red--text font-weight-bold pt-4 pb-2">
            {{
              isRegistrationCompletion
                ? $t("components.beneficiary_sba.has_been_edited_registration")
                : $t("components.beneficiary_sba.has_been_edited")
            }}
          </v-col>
          <v-col cols="12" class="py-2">
            {{ $t("components.beneficiary_sba.i_certify") }}
          </v-col>
          <signature-input
            :member="internalMember"
            @update-signature="signature = $event"
          />
          <v-col cols="12" md="4" class="pt-6 pb-4">
            <v-text-field
              outlined
              :label="$t('components.beneficiary_form.designation_date')"
              append-icon="mdi-calendar"
              :value="$helpers.formattedDateNow()"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters class="ma-2" ref="bottomOfTable">
          <v-spacer></v-spacer>
          <v-btn
            v-if="!memberSaved && !isRegistrationCompletion"
            @click="confirmUnrevocable"
            class="ml-2 primary"
            :loading="saving"
            :disabled="
              signatureIsCaptured === null ||
              !hasBeenEdited ||
              primaryBeneficiaryPercentageTotal !== 1 ||
              contingentBeneficiaryPercentageTotal !== 1
            "
          >
            <v-icon>mdi-save</v-icon>
            {{ $t("globals.save") }}
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-overlay :value="saving && !savingImage" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <!-- Add / Edit Dialog -->
    <v-dialog
      max-width="1000"
      v-if="editDialog"
      v-model="editDialog"
      persistent
    >
      <beneficiary-form-sba
        :beneficiary="selectedBeneficiary"
        @cancel-dialog="editDialog = false"
        :title="title"
        :titleIcon="titleIcon"
        @save-beneficiary="saveBeneficiary"
        :saving="saving"
        :memberSaved="memberSaved"
        :remainingPercentage="remainingPercentage"
        :beneficiaries="internalMember?.BeneficiaryStatement?.Beneficiaries"
        :dependants="internalMember.Dependants"
        :province="getProvinceCode(internalMember.Address?.Province)"
      ></beneficiary-form-sba>
    </v-dialog>

    <!-- Confirm delete Dialog -->
    <v-dialog max-width="800" v-model="deleteDialog" persistent>
      <delete-beneficiary-dialog
        :beneficiary="selectedBeneficiary"
        @cancel-dialog="deleteDialog = false"
        :title="title"
        :dialogMessage="dialogMessage"
        @delete-beneficiary="deleteBeneficiary"
        @delete-all-beneficiaries="deleteAllBeneficiaries"
        :saving="saving"
        :deleteAll="deleteAll"
        :memberSaved="memberSaved"
      ></delete-beneficiary-dialog>
    </v-dialog>
    <popup-text-dialog
      :model="unrevocableWarningDialog"
      textClass="text-subtitle-1 secondary--text"
      titleIcon="mdi-message-alert-outline"
      :title="$t('components.beneficiary_sba.unrevocable_warning_title')"
      :html="$t('components.beneficiary_sba.unrevocable_warning_message')"
      :width="800"
      :close-button-text="$t('globals.cancel')"
      :continueCallback="saveBeneficiariesAndSignature"
      @close="unrevocableWarningDialog = false"
    />
  </v-container>
</template>

<script>
import BeneficiaryFormSba from "@/components/MemberInformation/items/panels/BeneficiariesPanel/BeneficiaryFormSBA.vue";
import SignatureInput from "@/components/VueMaterialDashboard/SignatureInput.vue";
import DeleteBeneficiaryDialog from "@/components/MemberInformation/items/panels/BeneficiariesPanel/DeleteBeneficiaryDialog.vue";
import PopupTextDialog from "@/components/controls/PopupTextDialog.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    member: Object,
    saving: Boolean,
    savingImage: Boolean,
    memberSaved: Boolean,
    isRegistrationCompletion: Boolean,
    confirmUnrevocableToSave: Boolean,
  },
  components: {
    BeneficiaryFormSba,
    DeleteBeneficiaryDialog,
    PopupTextDialog,
    SignatureInput,
  },
  expose: ["hasBeenEdited"],
  data() {
    return {
      tab: 0,
      dateMenu: false,
      internalMember: {},
      valid: false,
      editMode: false,
      editDialog: false,
      unrevocableWarningDialog: false,
      deleteDialog: false,
      selectedBeneficiary: {},
      deleteAll: false,
      dialogMessage: "",
      title: "",
      titleIcon: "",
      signature: null,
      signatureTyped: null,
      beneficiariesBeforeEdited: null,
      contingentBeneficiaryPercentageTotal: 1,
      primaryBeneficiaryPercentageTotal: 1,
      newBeneficiary: {
        BeneficiaryType: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Relationship: "",
        BeneficiarySIN: null,
        DateOfBirth: "",
        // Gender: "",
        LanguagePreference: "",
        Percentage: null,
      },
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels"]),
    ...mapGetters("auth", ["user"]),
    remainingPercentage() {
      let percent = 100;
      if (
        this.internalMember &&
        this.internalMember.BeneficiaryStatement &&
        this.internalMember.BeneficiaryStatement.Beneficiaries
      )
        this.internalMember.BeneficiaryStatement.Beneficiaries.forEach(
          (b) => (percent = percent - b.Percentage)
        );
      return percent;
    },
    hasBeenEdited() {
      if (this.beneficiariesBeforeEdited === null) {
        return false;
      }
      return (
        JSON.stringify(
          this.internalMember?.BeneficiaryStatement?.Beneficiaries
        ) !== JSON.stringify(this.beneficiariesBeforeEdited)
      );
    },
    validToSave() {
      return (
        ((this.isRegistrationCompletion ? true : this.signature) &&
          this.hasBeenEdited &&
          this.contingentBeneficiaryPercentageTotal === 1 &&
          this.primaryBeneficiaryPercentageTotal === 1) ||
        false
      );
    },
    headers() {
      return [
        {
          text: this.$t("components.beneficiary_sba.headers.type"),
          align: "center",
          sortable: false,
          value: "BeneficiaryTypeId",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        // {
        //   text: this.$t("components.beneficiary_sba.headers.sin"),
        //   align: "center",
        //   sortable: false,
        //   value: "SIN",
        //   cellClass: "text-subtitle-2",
        //   class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        // },
        {
          text: this.$t("components.beneficiary_sba.headers.relationship"),
          align: "center",
          sortable: false,
          value: "BeneficiaryRelationshipId",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        // {
        //   text: this.$t("components.beneficiary_sba.headers.title"),
        //   align: "center",
        //   sortable: false,
        //   value: "TitleId",
        //   cellClass: "text-subtitle-2",
        //   class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        // },
        {
          text: this.$t("components.beneficiary_sba.headers.first_name"),
          align: "center",
          sortable: false,
          value: "FirstName",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("components.beneficiary_sba.headers.middle_name"),
          align: "center",
          sortable: false,
          value: "MiddleName",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("components.beneficiary_sba.headers.last_name"),
          align: "center",
          sortable: false,
          value: "LastName",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        // {
        //   text: this.$t("components.beneficiary_sba.headers.suffix"),
        //   align: "center",
        //   sortable: false,
        //   value: "SuffixId",
        //   cellClass: "text-subtitle-2",
        //   class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        // },
        {
          text: this.$t("components.beneficiary_sba.headers.dob"),
          align: "center",
          sortable: false,
          value: "DateOfBirth",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        // {
        //   text: this.$t("components.beneficiary_sba.headers.gender"),
        //   align: "center",
        //   sortable: false,
        //   value: "Gender",
        //   cellClass: "text-subtitle-2",
        //   class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        // },
        {
          text: this.$t("components.beneficiary_sba.headers.primary"),
          align: "center",
          sortable: false,
          value: "IsPrimary",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("components.beneficiary_sba.headers.revocable"),
          align: "center",
          sortable: false,
          value: "IsRevocable",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("components.beneficiary_sba.headers.add_trustee"),
          align: "center",
          sortable: false,
          value: "AddTrustee",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("components.beneficiary_sba.headers.share"),
          align: "center",
          sortable: false,
          value: "Percentage",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        // {
        //   text: this.$t("components.beneficiary_sba.headers.policy"),
        //   align: "center",
        //   sortable: false,
        //   value: "PolicyIdentities",
        //   cellClass: "text-subtitle-2",
        //   class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        // },
        {
          text: this.$t("components.beneficiary_sba.headers.trustee"),
          align: "center",
          sortable: false,
          value: "Trustee",
          cellClass: "text-subtitle-2",
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
        {
          text: this.$t("globals.actions"),
          value: "Actions",
          align: "center",
          sortable: false,
          class: "secondary--text text-uppercase text-subtitle-2 name-heading",
        },
      ];
    },
    hasUnrevocableBeneficiary() {
      return this.internalMember?.BeneficiaryStatement?.Beneficiaries?.some(
        (b) => !b.IsRevocable
      );
    },
    signatureIsCaptured() {
      return this.signature;
    },
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
        // console.log("member changed");
        if (
          v.BeneficiaryStatement?.Beneficiaries?.some(
            (b) => !b.UniqueIdentifier
          )
        ) {
          this.internalMember?.BeneficiaryStatement?.Beneficiaries.forEach(
            (b) => {
              b.UniqueIdentifier = this.$helpers.randomID();
            }
          );
          this.sortBeneficiaries();
          this.beneficiariesBeforeEdited = JSON.parse(
            JSON.stringify(
              this.internalMember?.BeneficiaryStatement?.Beneficiaries
            )
          );
        }
      },
      deep: true,
    },
    valid: {
      handler(v) {
        this.$emit("update-valid", v);
      },
    },
    memberSaved(v) {
      if (v) {
        if (this.internalMember?.BeneficiaryStatement?.Beneficiaries) {
          this.beneficiariesBeforeEdited = JSON.parse(
            JSON.stringify(
              this.internalMember?.BeneficiaryStatement?.Beneficiaries
            )
          );
        } else {
          this.beneficiariesBeforeEdited = null;
        }
      }
      this.selectedBeneficiary = {};
    },
    validToSave(v) {
      if (v) {
        this.$emit("update-valid-to-save", v);
      } else {
        this.$emit("update-valid-to-save", false);
      }
    },
    confirmUnrevocableToSave(v) {
      if (v) {
        this.confirmUnrevocable();
      }
    },
    hasBeenEdited(v) {
      this.$emit("update-has-been-edited", v);
    },
  },
  mounted() {
    this.internalMember = JSON.parse(JSON.stringify(this.member));
    this.internalMember?.BeneficiaryStatement?.Beneficiaries?.forEach((b) => {
      b.UniqueIdentifier = this.$helpers.randomID();
    });
    this.sortBeneficiaries();
    if (this.internalMember?.BeneficiaryStatement?.Beneficiaries) {
      this.beneficiariesBeforeEdited = JSON.parse(
        JSON.stringify(this.internalMember?.BeneficiaryStatement?.Beneficiaries)
      );
    }
  },
  methods: {
    ...mapActions("data", ["updateBeneficiaries"]),
    add() {
      this.title = this.$t("components.beneficiary_form.title.add");
      this.titleIcon = "mdi-account-plus-outline";
      this.selectedBeneficiary = {
        AddTrustee: false,
        UniqueIdentifier: this.$helpers.randomID(),
        IsRevocable: true,
      };
      this.editDialog = true;
    },
    save() {
      this.editMode = false;
      this.editDialog = false;
      this.deleteDialog = false;
      this.deleteAll = false;
      this.sortBeneficiaries();
    },
    confirmUnrevocable() {
      if (this.hasUnrevocableBeneficiary) {
        this.unrevocableWarningDialog = true;
      } else {
        this.saveBeneficiariesAndSignature();
      }
    },
    calculatePrimaryBeneficiaryPercentageTotal() {
      let hasPrimaryBeneficiary =
        this.internalMember?.BeneficiaryStatement?.Beneficiaries?.some(
          (b) => b.IsPrimary
        );
      if (!hasPrimaryBeneficiary) {
        return 1;
      }
      return this.internalMember?.BeneficiaryStatement?.Beneficiaries?.reduce(
        (acc, b) => {
          if (b.IsPrimary) {
            return acc + b.Percentage;
          }
          return acc;
        },
        0
      );
    },
    calculateContingentBeneficiaryPercentageTotal() {
      let hasContingentBeneficiary =
        this.internalMember?.BeneficiaryStatement?.Beneficiaries?.some((b) => {
          return !b.IsPrimary;
        });
      if (!hasContingentBeneficiary) {
        return 1;
      }
      return this.internalMember?.BeneficiaryStatement?.Beneficiaries?.reduce(
        (acc, b) => {
          if (!b.IsPrimary) {
            return acc + b.Percentage;
          }
          return acc;
        },
        0
      );
    },
    saveBeneficiariesAndSignature() {
      const signatureToSend = this.signature?.split(",")[1];
      let beneficiariesPayload = {
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.drawbridgeMemberId,
        payload: {
          Beneficiaries:
            this.internalMember?.BeneficiaryStatement?.Beneficiaries,
          MemberSignatureBase64: signatureToSend,
          Id: this.internalMember?.BeneficiaryStatement?.Id,
        },
      };
      this.unrevocableWarningDialog = false;
      if (!this.isRegistrationCompletion) {
        this.internalMember.EffectiveDate = this.$helpers.formattedDateNow();
        this.internalMember.Signature = signatureToSend;
        const options = {
          saveFunction: this.updateBeneficiaries,
          updateBeneficiaries: true,
          payload: beneficiariesPayload,
        };
        this.$emit("save", options);
      } else {
        this.$emit(
          "save-beneficiaries",
          this.internalMember?.BeneficiaryStatement?.Beneficiaries,
          signatureToSend,
          this.internalMember?.BeneficiaryStatement?.Id
        );
      }
    },
    saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },
    cancel() {
      this.editMode = false;
      this.internalMember = JSON.parse(JSON.stringify(this.member));
    },
    deleteBeneficiary(beneficiary) {
      const filteredBeneficiaries =
        this.internalMember?.BeneficiaryStatement?.Beneficiaries.filter(
          (b) => b.UniqueIdentifier !== beneficiary.UniqueIdentifier
        );
      this.internalMember.BeneficiaryStatement.Beneficiaries =
        filteredBeneficiaries;
      this.save();
    },
    deleteAllBeneficiaries() {
      this.internalMember.BeneficiaryStatement.Beneficiaries = [];
      this.deleteAll = false;
      this.save();
    },
    confirmDeleteBeneficiary(beneficiary) {
      this.title = this.$t("components.delete_beneficiary_dialog.title_{0}", [
        (beneficiary.FirstName ?? "") + " " + (beneficiary.LastName ?? ""),
      ]);
      this.dialogMessage = this.$t(
        "components.delete_beneficiary_dialog.are_you_sure_record"
      );
      this.selectedBeneficiary = beneficiary;
      this.deleteDialog = true;
    },
    confirmDeleteAllBeneficiaries() {
      this.title = this.$t("components.delete_all_beneficiaries_dialog.title");
      this.dialogMessage = this.$t(
        "components.delete_all_beneficiary_dialog.are_you_sure"
      );
      this.deleteAll = true;
      this.deleteDialog = true;
    },
    editBeneficiary(beneficiary) {
      let beneficiaryToEdit = JSON.parse(JSON.stringify(beneficiary));
      this.title = this.$t("components.beneficiary_form.title.edit_{0}", [
        (beneficiaryToEdit.FirstName ?? "") +
          " " +
          (beneficiaryToEdit.LastName ?? ""),
      ]);
      this.titleIcon = "mdi-account-edit-outline";
      beneficiaryToEdit.DateOfBirth =
        beneficiaryToEdit?.DateOfBirth?.split("T")[0];
      if (beneficiaryToEdit.AddTrustee) {
        beneficiaryToEdit.Trustee.DateOfBirth =
          beneficiaryToEdit?.Trustee?.DateOfBirth?.split("T")[0];
      }
      beneficiaryToEdit.Percentage = beneficiaryToEdit.Percentage * 100;
      this.selectedBeneficiary = beneficiaryToEdit;
      this.editDialog = true;
    },
    saveBeneficiary(beneficiary) {
      console.log("saving beneficiary", beneficiary);
      if (this.beneficiariesBeforeEdited === null) {
        this.beneficiariesBeforeEdited = [];
      }
      if (beneficiary.BeneficiaryType?.Name === "Person") {
        beneficiary.DateOfBirth = beneficiary.DateOfBirth
          ? beneficiary.DateOfBirth + "T00:00:00.000Z"
          : null;
      }

      if (beneficiary.AddTrustee) {
        beneficiary.Trustee.DateOfBirth = beneficiary?.Trustee?.DateOfBirth
          ? beneficiary?.Trustee?.DateOfBirth + "T00:00:00.000Z"
          : null;
      }

      beneficiary.Percentage = beneficiary.Percentage / 100;
      const filteredBeneficiaries =
        this.internalMember?.BeneficiaryStatement?.Beneficiaries?.filter(
          (b) => b.UniqueIdentifier !== beneficiary.UniqueIdentifier
        ) || [];
      filteredBeneficiaries.push(beneficiary);
      if (!this.internalMember?.BeneficiaryStatement?.Beneficiaries) {
        this.internalMember.BeneficiaryStatement = {
          Beneficiaries: [],
        };
      }
      this.internalMember.BeneficiaryStatement.Beneficiaries =
        filteredBeneficiaries;
      this.sortBeneficiaries();
      this.$nextTick(() => {
        this.$refs.bottomOfTable.scrollIntoView({ behavior: "smooth" });
      });
      this.contingentBeneficiaryPercentageTotal =
        this.calculateContingentBeneficiaryPercentageTotal();
      this.primaryBeneficiaryPercentageTotal =
        this.calculatePrimaryBeneficiaryPercentageTotal();
      this.signature = null;
      this.save();
      // this.clearSignature();
    },
    sortBeneficiaries() {
      this.internalMember?.BeneficiaryStatement?.Beneficiaries?.sort((a, b) => {
        if (a.BeneficiaryTypeId > b.BeneficiaryTypeId) {
          return -1;
        }
        if (a.BeneficiaryTypeId < b.BeneficiaryTypeId) {
          return 1;
        }
        return 0;
      });
    },
    getProvinceCode(province) {
      let value = this.enumModels?.Province?.find(
        (e) => e.value?.toUpperCase() === province?.toUpperCase()
      )?.code;
      return value;
    },
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.beneficiaries-table >>> th {
  padding: 0 5px !important;
}
.beneficiaries-table >>> td {
  padding: 0 2px !important;
}
.beneficiaries-table >>> tr:hover {
  background-color: transparent !important;
}
.beneficiaries-table >>> .name-heading {
  font-size: 10px !important;
  line-height: 1.5 !important;
}
</style>
