<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    color="primary"
    :widgetId="widgetId"
    dark
    :title="
      $t('components.vue_material_dashboard.items.news_dashboard_item.title')
    "
  >
    <v-progress-circular
      class="mt-16"
      v-if="loading"
      indeterminate
      size="50"
    ></v-progress-circular>
    <v-timeline v-else align-top dense class="pt-2">
      <v-timeline-item
        class="pb-2"
        v-for="i in 2"
        :key="i - 1"
        :color="newsData && newsData[i - 1].color"
        fill-dot
      >
        <v-card
          :color="(i - 1) % 2 === 0 ? 'red lighten-2' : 'blue lighten-2'"
          dark
          class="news-card"
          max-width="480px"
          width="110%"
          :to="'news/' + (i - 1)"
        >
          <v-card-title
            class="text-subtitle-2 text-lg-subtitle-1 py-1"
            style="word-break: keep-all !important"
          >
            {{ newsData && newsData[i - 1].title }}
          </v-card-title>
          <v-card-text
            class="white text--primary text-left two-lines text-captions"
            >{{ newsData && newsData[i - 1].text }}</v-card-text
          >
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </material-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      newsData: undefined,
      loading: false,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getNewsData"]),
    async loadData() {
      try {
        this.loading = true;
        this.newsData = (
          await this.getNewsData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.newsData = [
          {
            color: "red lighten-2",
            title: "Our new members portal is here!",
            text: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.`,
          },
          {
            color: "blue lighten-2",
            title: "Operational update",
            text: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.`,
          },
          {
            color: "primary lighten-2",
            title: "Mark this date!",
            text: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola
            imperdiet nec ut, sed euismod convenire principes at. Est et nobis
            iisque percipit, an vim zril disputando voluptatibus, vix an
            salutandi sententiae.`,
          },
        ];
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.v-timeline {
  padding-top: 24px;
  position: relative;
  left: -24px;
}
.v-card.news-card {
  position: relative;
  left: -22px;
}
.v-timeline-item__dot {
  transform: translateY(11px) !important;
  height: 16px !important;
  width: 16px !important;
}
.two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: normal;
}
</style>
