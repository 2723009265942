<template>
  <div>
    <div v-if="!image">
      <v-file-input
        v-model="uploadUrl"
        :label="label"
        v-on:change="uploadImg"
        ref="fileIcon"
        accept=".jpg, .png"
        :rules="rules"
      ></v-file-input>
    </div>
    <div v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            :class="`
              v-input v-input--is-label-active v-input--is-dirty
              theme--light
              v-text-field v-text-field--is-booted`"
          >
            <div class="v-input__slot">
              <div class="v-text-field__slot" style="margin: 0 auto">
                <label
                  :for="imgControlId"
                  class="v-label v-label--active theme--light mb-1"
                  style="left: 0px; right: auto; position: absolute"
                  >{{ $t("components.member_image_dialog.clear") }}</label
                >
                <img
                  :id="imgControlId"
                  :src="image"
                  style="height: 100px; cursor: pointer; margin: 0 auto"
                  @click="$emit('image-data', null)"
                  v-bind="attrs"
                  v-on="on"
                />
              </div>
            </div>
          </div>
        </template>
        <span>{{ $t("globals.clear") }}</span>
      </v-tooltip>
    </div>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <div style="height: 200px; mx-auto">
          <vueCropper
            ref="cropper"
            :img="imageData"
            :canMove="true"
            :canMoveBox="true"
            :info="true"
            :autoCrop="true"
            :autoCropWidth="160"
            :autoCropHeight="160"
            outputType="png"
            @realTime="realTime"
          ></vueCropper>
        </div>
        <div
          v-if="previews"
          class="show-preview"
          :style="{
            width: previews.w + 'px',
            height: previews.h + 'px',
            overflow: 'hidden',
            margin: '5px auto',
          }"
        >
          <div :style="previews.div">
            <img :src="previews.url" :style="previews.img" />
          </div>
        </div>

        <div class="col-auto align-self-center">
          <div ref="icon" style="width: 50px" class="svg-icon"></div>
        </div>
        <v-row no-gutters justify="end" class="pb-5 pr-5">
          <div class="col-auto align-self-center">
            <v-btn @click="cancel" class="mr-2">
              {{ $t("globals.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="saveImageHandler"
              :disabled="!imageData"
            >
              {{ $t("globals.save") }}
            </v-btn>
          </div>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    VueCropper,
  },
  data: () => ({
    dialog: false,
    uploadUrl: undefined,
    imageData: undefined,
    previews: undefined,
    imgControlId: "img-" + Math.random().toString().substr(2, 8),
  }),
  computed: {
    ...mapGetters("common", { selectedPlan: "plan", settings: "settings" }),
  },
  props: {
    image: String,
    rules: Array,
    label: String,
  },
  methods: {
    ...mapActions("downloadCenter", ["uploadFile", "saveImage"]),
    async saveImageHandler() {
      const data = await new Promise((resolve) => {
        this.$refs.cropper.getCropData((data) => {
          resolve(data);
        });
      });
      this.$emit("image-data", data);
      this.dialog = false;
      this.uploadUrl = undefined;
      this.imageData = undefined;
      this.previews = undefined;
    },

    cancel() {
      this.dialog = false;
      this.uploadUrl = null;
      this.$emit("cancel-upload");
    },

    async uploadImg($event) {
      const reader = new FileReader();
      const file = $event;
      reader.readAsDataURL(file);
      const contents = await new Promise((resolve) => {
        reader.onload = function () {
          resolve(reader.result);
        };
      });
      this.imageData = contents; //.split(',')[1];

      this.dialog = true;
    },
    realTime(data) {
      this.previews = data;
    },
  },
};
</script>
<style scoped>
.vue-cropper {
  background-repeat: repeat;
}
.v-tooltip__content {
  max-width: 150px !important;
}
</style>
