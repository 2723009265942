/* eslint-disable no-unused-vars */
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service.js";

const state = {};

const getters = {};

const actions = {
  getUser(notused, options) {
    var settings = SettingsService.settings;
    const url =
      settings.APIURL +
      "brokers/" +
      options.broker +
      "/users/" +
      options.user +
      "?$expand=Employers($select=Name,Id,UniqueIdentifier),Stores($select=Name,Id),Broker($select=Name,Id),Plans($select=Name,Id,UniqueIdentifier;$expand=Translations($select=Name,LanguageCode),Tags($select=Name,Id;$expand=Plans($select=Id)))&$select=Id,UserName,FirstName,LastName,Email,RegistrationDate,LastLoginDate,LoginStatus,Roles,IsConfirmed,IsActive";
    return ApiService.get(url);
  },
  getUserByEmail(notused, options) {
    var settings = SettingsService.settings;
    const url = settings.APIURL + "users/getByEmail/" + options.email;
    return ApiService.get(url);
  },
  // updateUser(notused, options) {
  //   var settings = SettingsService.settings;
  //   const url =
  //     settings.APIURL +
  //     "brokers/" +
  //     options.broker +
  //     "/users/" +
  //     options.user.Id;
  //   return ApiService.put(url, options.user);
  // },

  newUser(notused, options) {
    var settings = SettingsService.settings;
    const url = settings.APIURL + "brokers/" + options.broker + "/users";
    return ApiService.post(url, options.user);
  },

  registerWithToken(notused, options) {
    var settings = SettingsService.settings;
    const url =
      settings.APIURL +
      "brokers/" +
      options.broker +
      "/users/registerWithToken";
    return ApiService.post(url, options.payload);
  },

  // resetPassword(notused, options) {
  //   var settings = SettingsService.settings;
  //   var url =
  //     settings.APIURL + "brokers/" + options.broker + "/users/resetPassword";
  //   return ApiService.post(url, options.payload);
  // },
  resetPasswordByEmail(notused, options) {
    var settings = SettingsService.settings;
    var url = settings.APIURL + "users/resetPasswordByEmail";
    return ApiService.post(url, options.payload);
  },
  forgotEmail(notused, options) {
    var settings = SettingsService.settings;
    //var url = settings.APIURL + "users/forgotEmail";
    const url = `${settings.AUTH}api/authenticate/ForgotEmail`;
    return ApiService.post(url, options.payload);
  },
  forgotUsername(notused, options) {
    var settings = SettingsService.settings;
    var url = settings.APIURL + "users/forgotUsername";
    return ApiService.post(url, options.payload);
  },
  sendMessage(notused, options) {
    var settings = SettingsService.settings;
    //var url = settings.APIURL + "users/forgotEmail";
    const url = `${settings.AUTH}api/authenticate/SendMessage`;
    return ApiService.post(url, options.payload);
  },
  sendHtml(notused, options) {
    var settings = SettingsService.settings;
    //var url = settings.APIURL + "users/forgotEmail";
    const url = `${settings.AUTH}api/authenticate/SendWelcomeKit`;
    return ApiService.post(url, options.payload);
  },
  setResetPassword(notused, options) {
    var settings = SettingsService.settings;
    var url =
      settings.APIURL + "brokers/" + options.broker + "/users/setResetPassword";
    return ApiService.post(url, options.payload);
  },
  registerUser({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/Register`;
    options.payload.ReturnUrl = window.location.origin;
    return ApiService.postGuest(url, options.payload);
  },
  forgotPasswordRequest({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/ForgotPassword`;
    options.payload.ReturnUrl = window.location.origin;
    return ApiService.post(url, options.payload);
  },
  resetPassword({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/ResetPassword`;
    return ApiService.post(url, options.payload);
  },
  changePassword({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/ChangePassword`;
    return ApiService.post(url, options.payload);
  },
  changeEmail({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/ChangeEmail`;
    options.payload.ReturnUrl = window.location.origin;
    return ApiService.post(url, options.payload);
  },
  updateUser({ state }, options) {
    console.log("updating user options:", options);
    const settings = SettingsService.settings;
    const url = `${settings.AUTH}api/authenticate/${options.memberIdentityId}`;
    return ApiService.put(url, options.payload);
  },
};

const mutations = {};

export const users = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
