<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    :title="
      $t(
        'components.vue_material_dashboard.items.benefits_balance_dashboard_item.title'
      )
    "
  >
    <v-card-title>
      {{
        $t(
          "components.vue_material_dashboard.items.benefits_balance_dashboard_item.title"
        )
      }}
    </v-card-title>
    <v-card-text>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id
      facilisis lorem. Donec iaculis vel leo a mattis. Curabitur placerat, nisl
      id blandit vulputate, turpis felis scelerisque massa, eget porta tellus
      sapien suscipit risus. Suspendisse quis dapibus mauris. Ut ultrices eros
      ac lacinia semper. Maecenas ex magna, pretium ac tellus vitae, aliquet
      blandit mi. Duis mauris ex, dictum ut ipsum quis, sollicitudin pulvinar
      sem. Ut venenatis neque ac ultricies tincidunt. Donec ultrices enim felis,
      nec venenatis orci blandit vitae. Quisque feugiat efficitur ligula, nec
      sagittis dolor tempor et. Ut aliquet malesuada odio, at vestibulum odio.
      Nam eu lacus venenatis, placerat mauris ac, fringilla tortor. Vestibulum
      ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
      curae; Nam at interdum tortor. Praesent ornare elementum bibendum. Integer
      ipsum dui, iaculis quis ex a, commodo aliquam mauris. In pharetra lorem
      nec mi vehicula, ut porttitor ante ullamcorper. Orci varius natoque
      penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec
      mi nibh, pellentesque sed eros et, placerat hendrerit erat. Fusce augue
      purus, blandit nec rhoncus sed, sodales egestas justo. Donec ac odio sit
      amet massa semper eleifend. Sed mollis volutpat eros, ac posuere ligula
      pretium id. Fusce interdum ipsum arcu, vitae placerat velit lacinia ut.
      Integer suscipit arcu ac neque vestibulum, non venenatis quam porttitor.
      Mauris nec cursus dolor. Aenean dictum accumsan mi, eu placerat velit
      mattis sit amet. Integer ac velit id urna pulvinar convallis.
    </v-card-text>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    id: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
