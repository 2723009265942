var render = function render(){var _vm=this,_c=_vm._self._c;return _c('material-card-sba',{attrs:{"size":_vm.internalSize,"order":_vm.internalOrder,"height":_vm.internalHeight,"maxOrderValue":_vm.maxOrderValue,"color":"accent","widgetIcon":_vm.widgetIcon,"title":_vm.$t('components.vue_material_dashboard.items.hsa.title', [
      _vm.hsaLabel
        ? _vm.$vuetify.lang.current === 'fr'
          ? _vm.hsaLabel?.Fr
          : _vm.hsaLabel?.En
        : 'HSA',
    ])},on:{"update:size":function($event){_vm.internalSize=$event},"update:order":function($event){_vm.internalOrder=$event},"update:height":function($event){_vm.internalHeight=$event}}},[_c('v-row',{staticClass:"fill-height mx-2",attrs:{"no-gutters":"","justify":"space-around"}},[_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"pa-0 title1 text-uppercase"},[_vm._v(_vm._s(_vm.$t("components.vue_material_dashboard.items.hsa.remaining")))]),_c('v-col',{staticClass:"pa-0 text-h6 font-weight-bold accent--text"},[_vm._v(_vm._s(_vm.$helpers.toCurrency(_vm.hsaData?.CurrentBalance) || "--")+" ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"pa-0 title1 text-uppercase"},[_vm._v(_vm._s(_vm.$t("components.vue_material_dashboard.items.hsa.upcoming_credits")))]),_c('v-col',{staticClass:"pa-0 text-h6 font-weight-bold accent--text"},[_vm._v(_vm._s(_vm.$helpers.toCurrency(_vm.hsaData?.HSACreditToExpire?.Amount) || "--")+" ")])],1),_c('v-col',{staticClass:"mb-12",attrs:{"cols":"12"}},[_c('v-col',{staticClass:"pa-0 title1 text-uppercase"},[_vm._v(_vm._s(_vm.$t("components.vue_material_dashboard.items.hsa.expiration_date")))]),_c('v-col',{staticClass:"pa-0 text-h6 font-weight-bold info--text mb-12"},[_vm._v(_vm._s(_vm.HSAExpiryDate ? _vm.$helpers.formatDate(_vm.HSAExpiryDate) || "--" : _vm.$helpers.formatDate( _vm.hsaData?.HSACreditToExpire?.ExpirationDate ) || "--")+" ")])],1),_c('v-btn',{staticClass:"action-btn",attrs:{"color":"accent","to":'hsa-balance'}},[_vm._v(_vm._s(_vm.$t("globals.more")))]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"loader",attrs:{"size":"50","color":"primary","indeterminate":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }