const PLAN_KEY = "selected_plan";

const PlanService = {
  setPlan(plan) {
    localStorage.setItem(PLAN_KEY, JSON.stringify(plan));
  },
  getPlan() {
    return JSON.parse(localStorage.getItem(PLAN_KEY));
  },
  removePlan() {
    localStorage.removeItem(PLAN_KEY);
  },
};

export default PlanService;
