<template>
  <material-card
    :isAssistant="true"
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    @add-widget="$emit('add-widget', $event)"
    :widgetId="widgetId"
    flat
    color="rgb(0,0,0,0)"
  >
    <v-card-text class="text-left pb-0">
      <v-container>
        <v-row>
          <!-- <v-col>
            <v-img
              contain
              width="400px"
              content-class="pa-0"
              :src="require('@/assets/ben-welcome.png')"
            />
          </v-col> -->
          <v-col align-self="center" class="text-center">
            <v-col class="text-h5 text--secondary lighten-2 pt-0">{{
              $t("components.vue_material_dashboard.items.assistant.title")
            }}</v-col>
            <v-col class="text-subtitle-1 text--secondary lighten-2 pt-0">{{
              $t("components.vue_material_dashboard.items.assistant.subtitle")
            }}</v-col>
            <v-col class="pt-0 pt-lg-5">
              <v-btn color="primary" to="faq"
                ><span>
                  {{
                    $t(
                      "components.vue_material_dashboard.items.assistant.learn_more"
                    )
                  }}
                </span></v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      selectedItem: undefined,
      downloadsData: [],
      loading: false,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
