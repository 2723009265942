<template>
  <div>
    <v-dialog
      scrollable
      v-model="confirmBankAccountDialog"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">warning</v-icon>
          {{
            $t(
              "components.direct_deposit_expansion_panel.confirm_bank_account_dialog_failed_title"
            )
          }}
        </v-card-title>
        <v-card-text>
          {{
            $t(
              "components.direct_deposit_expansion_panel.confirm_bank_account_dialog_failed_text"
            )
          }}
        </v-card-text>
        <v-card-actions>
          <v-btn color text @click="confirmBankAccountDialog = false">
            {{ $t("globals.close") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            @click="
              confirmBankAccountDialog = false;
              saveMember();
            "
          >
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-form
      v-model="valid"
      v-if="internalBankAccount"
      class="pa-2 my-2"
      ref="bankAccountForm"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          class="py-0"
          v-if="
            Object.keys(headersDictionary).indexOf('Institution Number') > -1
          "
        >
          <no-autocomplete-textfield
            clearable
            outlined
            v-mask="'###'"
            :label="
              $t('components.direct_deposit_expansion_panel.institution_number')
            "
            v-model="internalBankAccount.InstitutionNumber"
            :rules="
              this.headersDictionary['Institution Number']?.IsOptional
                ? [$rules.canadianBankInstitutionNumber]
                : [$rules.canadianBankInstitutionNumber, $rules.required]
            "
            :class="getRequiredClass('Institution Number')"
            @blur="isBankAccountRequired()"
          ></no-autocomplete-textfield>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          class="py-0"
          v-if="Object.keys(headersDictionary).indexOf('Account Number') > -1"
        >
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.direct_deposit_expansion_panel.account_number')
            "
            v-mask="'############'"
            v-model="internalBankAccount.AccountNumber"
            :rules="
              this.headersDictionary['Account Number']?.IsOptional
                ? [$rules.canadianBankAccountNumber]
                : [$rules.canadianBankAccountNumber, $rules.required]
            "
            :class="getRequiredClass('Account Number')"
            @blur="isBankAccountRequired()"
          ></no-autocomplete-textfield>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="4"
          class="py-0"
          v-if="Object.keys(headersDictionary).indexOf('Transit Number') > -1"
        >
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.direct_deposit_expansion_panel.transit_number')
            "
            v-mask="'#####'"
            v-model="internalBankAccount.TransitNumber"
            :rules="
              this.headersDictionary['Transit Number']?.IsOptional
                ? [$rules.canadianBankTransitNumber]
                : [$rules.canadianBankTransitNumber, $rules.required]
            "
            :class="getRequiredClass('Transit Number')"
            @blur="isBankAccountRequired()"
          ></no-autocomplete-textfield>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="auto"
          class="py-0 mt-0 note"
          v-if="
            Object.keys(headersDictionary).indexOf('Verify Direct Deposit') > -1
          "
        >
          <v-checkbox
            v-model="internalBankAccount.VerifyDirectDeposit"
            @change="(v) => (v ? $refs.bankAccountForm.validate() : null)"
            :rules="[$rules.required]"
          >
          </v-checkbox>
        </v-col>
        <v-col class="py-0 mt-0 note required">
          {{ $t("components.direct_deposit_expansion_panel.authorize_text") }}
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapActions } from "vuex";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    saving: Boolean,
    memberBankAccount: Object,
    formFields: Array,
  },
  data() {
    return {
      dateMenu: false,
      internalBankAccount: {},
      valid: false,
      editMode: false,
      errorMessage: "",
      confirmBankAccountDialog: "",
      validating: false,
      authorize: false,
      headersDictionary: {},
    };
  },
  watch: {
    // bankAccount: {
    //   handler(v) {
    //     this.internalBankAccount = v;
    //   },
    //   deep: true,
    // },
    formFields: {
      handler(v) {
        this.createSections(v);
      },
      immediate: true,
    },
    internalBankAccount: {
      handler(v) {
        this.$emit("update-bank-account", v);
      },
      deep: true,
    },
    valid: {
      handler(v) {
        this.$emit("update-valid", v);
      },
    },
  },
  mounted() {
    if (this.memberBankAccount) {
      this.internalBankAccount = JSON.parse(
        JSON.stringify(this.memberBankAccount)
      );
      this.internalBankAccount.VerifyDirectDeposit = false;
    }
    this.$refs.bankAccountForm.resetValidation();
  },
  methods: {
    ...mapActions("data", ["validateBankAccount"]),
    isBankAccountRequired() {
      const bankAccountIsMandatory =
        this.headersDictionary["Institution Number"]?.IsOptional === false &&
        this.headersDictionary["Account Number"]?.IsOptional === false &&
        this.headersDictionary["Transit Number"]?.IsOptional === false;
      if (bankAccountIsMandatory) {
        return true;
      }
      for (let key of Object.keys(this.internalBankAccount)) {
        if (
          this.internalBankAccount[key] === null ||
          this.internalBankAccount[key] === "" ||
          this.internalBankAccount[key] === undefined ||
          this.internalBankAccount[key] === false
        ) {
          delete this.internalBankAccount[key];
        }
      }
      if (Object.keys(this.internalBankAccount).length === 0) {
        if (this.$refs.bankAccountForm) {
          this.$refs.bankAccountForm.resetValidation();
        }
        this.$emit("update-valid", true);
        return false;
      }
      if (!this.valid) this.$emit("update-valid", false);
      return true;
    },
    createSections(headers) {
      for (const header of headers) {
        this.headersDictionary[header.Text] = header;
      }
    },
    getRequiredClass(fieldName) {
      return this.isBankAccountRequired()
        ? this.headersDictionary[fieldName].IsOptional === false
          ? "required"
          : []
        : "";
    },
  },
};
</script>
<style scoped>
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> .v-select__selection {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 600 !important;
}
.v-input--selection-controls__ripple {
  margin-right: 0px !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
.note {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}
.note > .v-input--selection-controls {
  margin-top: 0;
}
</style>
