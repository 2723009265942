<template>
  <v-card
    v-bind="$attrs"
    :color="color"
    v-on="$listeners"
    style="position: relative"
    class="material-card my-0"
    :height="cardHeight"
  >
    <v-card-title :class="color">
      <v-row no-gutters>
        <v-col class="text-left ml-4 text-subtitle-1 mt-2" align-self="center">
          {{ title }}
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                style="z-index: 1"
                class="pr-0"
                v-if="!tabbed"
                text
                small
              >
                <v-tooltip top max-width="50px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">apps</v-icon>
                  </template>
                  <span>{{
                    $t(
                      "components.vue_material_dashboard.material.material_card.customize"
                    )
                  }}</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group
                class="text-left"
                v-model="widgetAction"
                @change="addRemoveResetWidget"
              >
                <v-list-item
                  value="add"
                  :disabled="availableDashboardItemTypes.length === 0"
                >
                  <v-list-item-title>{{
                    $t(
                      "components.vue_material_dashboard.material.material_card.add"
                    )
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item value="remove" :disabled="isAssistant">
                  <v-list-item-title>{{
                    $t(
                      "components.vue_material_dashboard.material.material_card.remove"
                    )
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item value="reset">
                  <v-list-item-title>{{
                    $t(
                      "components.vue_material_dashboard.material.material_card.reset"
                    )
                  }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card-title>
    <slot />
    <v-dialog v-model="addWidgetDialog" width="400">
      <v-card>
        <v-card-title>
          {{ $t("components.vue_material_dashboard.add_widget_dialog.title") }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-select
              :label="
                $t('components.vue_material_dashboard.add_widget_dialog.select')
              "
              :no-data-text="
                $t(
                  'components.vue_material_dashboard.add_widget_dialog.select.no_data'
                )
              "
              v-model="widgetToAdd"
              class="align-center"
              item-text="Title"
              item-value="Name"
              :items="availableDashboardItemTypes"
              @change="addWidget"
            />
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="resetWidgetsDialog" width="400">
      <v-card>
        <v-card-title class="mb-4 pt-4">
          {{
            $t("components.vue_material_dashboard.reset_widgets_dialog.title")
          }}
        </v-card-title>
        <v-card-text>
          {{
            $t("components.vue_material_dashboard.reset_widgets_dialog.text")
          }}
        </v-card-text>
        <v-card-actions class="pa-4 pt-0">
          <v-btn color="primary" class="mr-3" @click="resetLayout">{{
            $t("globals.reset")
          }}</v-btn>
          <v-btn @click="cancel">{{ $t("globals.cancel") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-actions class="pa-0 card-actions">
      <slot name="card-actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    widgetId: String,
    title: String,
    size: Number,
    height: Number,
    color: String,
    order: Number,
    maxOrderValue: Number,
    tabbed: Boolean,
    moreText: Boolean,
    isPensions: Boolean,
    isAssistant: Boolean,
  },
  data() {
    return {
      customizeDialog: false,
      internalSize: undefined,
      internalOrder: undefined,
      widgetAction: undefined,
      addWidgetDialog: false,
      resetWidgetsDialog: false,
      widgetToAdd: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        //console.log("order change", v);
        this.$emit("update:order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  computed: {
    ...mapGetters("common", ["availableDashboardItemTypes"]),
    cardHeight() {
      return this.$vuetify.breakpoint.smAndUp
        ? 230
        : this.isPensions
        ? "1310"
        : 230;
    },
  },
  methods: {
    ...mapActions("common", ["resetDashboardItems"]),
    // closeClick() {
    //   console.log("click close");
    // },
    // removeWidget() {
    //   this.customizeDialog = false;
    //   this.$emit("remove-widget", this.widgetId);
    // },
    addRemoveResetWidget(action) {
      console.log("action", action, this.widgetAction);
      if (action === "remove") this.$emit("remove-widget", this.widgetId);
      if (action === "add") this.addWidgetDialog = true;
      if (action === "reset") this.resetWidgetsDialog = true;
      this.widgetToAdd = "";
      this.widgetAction = null;
    },
    addWidget(widgetName) {
      const widget = this.availableDashboardItemTypes.find(
        (w) => w.Name === widgetName
      );
      this.addWidgetDialog = false;
      this.$emit("add-widget", widget);
    },
    cancel() {
      this.resetWidgetsDialog = false;
    },
    resetLayout() {
      this.resetDashboardItems();
      this.resetWidgetsDialog = false;
    },
  },
};
</script>
<style scoped>
.v-card {
  margin-top: 10px;
  margin-bottom: 30px;
}
.v-dialog .v-card {
  margin-top: 0px;
  margin-bottom: 0px;
}
.v-card__title {
  padding: 0 !important;
  position: relative;
  display: block;
  word-break: keep-all;
}
.v-card-icon {
  border-radius: 3px;
  padding: 20px;
  top: -14px;
  max-width: 64px;
  left: 15px;
  box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28),
    0 4px 20px 0 rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2);
  position: absolute;
}
.v-card__title h4 {
  color: #3c4858;
  font-weight: 300;
  font-size: 1.125rem;
  padding: 12px 5px 10px 10px;
  display: block;
  text-align: right;
}
.v-application .mx-3 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.v-tooltip__content {
  width: 100px !important;
  font-size: 12px !important;
}
.material-card >>> .v-card__text {
  padding: 0 16px !important;
}

.material-card >>> .v-card__text.more-text {
  padding: 0 0px !important;
}
.v-expansion-panel-content__wrap {
  padding: 0 16px 16px !important;
}
.v-expansion-panel-header {
  min-height: 32px;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: rgb(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.87);
}
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  z-index: 2;
  position: absolute !important;
  bottom: 0;
  width: 100%;
}
</style>
<style>
.v-dialog--scrollable {
  overflow: visible !important;
}
</style>
