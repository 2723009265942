<template>
  <div>
    <div class="d-flex flex-row">
      <input
        :id="id"
        multiple
        ref="uploader"
        style="display: none"
        type="file"
        accept="image/png, image/jpeg, .pdf, .heic"
        @change="(e) => handleFileChange(e.target.files)"
      />
    </div>
    <file-preview
      :fullScreen="false"
      v-if="showPreview"
      :view="showPreview"
      :fileItem="fileItem"
      @close="close"
    />
    <v-dialog persistent scrollable v-model="fileExistsWarning" max-width="480">
      <material-card
        :title="$t('components.multifileupload2.file_exists_warning_title')"
        color="#007cb5"
        icon="warning"
      >
        <v-card-text>
          {{ $t("components.multifileupload2.file_exists_warning") }}
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="fileExistsWarning = false">
            {{ $t("globals.close") }}
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
// import pdf from "vue-pdf";
import { saveAs } from "file-saver";
import AuthService from "@/services/auth.service";
// import { TokenService } from "@/services/token.service";
import FilePreview from "./FilePreview.vue";
import { mapActions, mapGetters } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCard.vue";
export default {
  name: "multi-file-upload2",
  props: [
    "id",
    "uploadMsg",
    "rules",
    "label",
    "keepRevisions",
    "fileUploadUrl",
    "zipDownloadUrl",
    "getFilesUrl",
    "ownerId",
    "categories",
    "tags",
    "groups",
    "filesIds",
    "disabled",
    "currentFolder",
    "files",
    "public",
    "uploadedFiles",
    "droppedFiles",
    "maxFileSizeMB",
  ],
  components: {
    // pdf,
    FilePreview,
    MaterialCard,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      fileItem: null,
      error: false,
      uploading: false,
      selectedFiles: [],
      internalFiles: [],
      src: null,
      showPreview: false,
      fileData: null,
      pageCount: 0,
      fileExistsWarning: false,
    };
  },
  watch: {
    files: {
      handler(v) {
        for (const f of v) {
          f.description = this.createDescription(f);
        }
        this.internalFiles = v;
      },
    },
    filesIds: {
      handler() {
        console.log(
          "multi file upload fileIds wathcer: ",
          JSON.stringify(this.filesIds)
        );
        this.getFiles();
      },
    },
    uploadedFiles: {
      handler(v) {
        this.internalFiles = JSON.parse(JSON.stringify(v));
        this.selectedFiles = JSON.parse(JSON.stringify(v));
      },
    },
  },
  mounted() {
    if (this.files !== null && this.files !== undefined) {
      for (const f of this.files) {
        f.description = this.createDescription(f);
      }
      this.internalFiles = this.files;
    }
    this.getFiles();
    if (this.droppedFiles) {
      this.handleFileChange(this.droppedFiles);
    } else {
      this.onAddClick();
    }
  },
  methods: {
    ...mapActions("documentsService", ["getFile", "uploadFile"]),
    close() {
      this.showPreview = false;
      this.fileItem = null;
    },
    createDescription(item) {
      return (
        item.file.Name.substring(0, 10) +
        "..." +
        item.file.Name.slice(item.file.Name.length - 10) +
        "--" +
        new Date(Date.parse(item.file.DateCreated)).toLocaleString()
      );
    },
    emitFiles(data) {
      this.uploading = false;
      // for (const f of data) {
      //   delete f.description;
      // }
      this.$emit("emit-files", data);
    },
    emitUploadStart() {
      this.uploading = true;
      this.$emit("emit-upload-start");
    },
    emitUploadEnd() {
      this.uploading = false;
      this.$emit("emit-upload-end");
    },
    emitUploadError(error) {
      this.uploading = false;
      this.$emit("emit-upload-error", error);
    },
    // pdfError(error) {
    //   console.log("pdf error: ", error);
    // },
    // pdfLoaded() {
    //   console.log("pdf loaded");
    // },
    // pdfProgress(progress) {
    //   console.log("pdf progress: ", progress);
    // },
    async preview(item) {
      // console.log("preview triggered: ", item);
      // try {
      //   this.showPreview = true;

      //   // const res = await axios.get(`${this.fileUploadUrl}/${item.file.Id}`);
      //   // const blob = this.b64toBlob(res.data);

      //   const file = (await this.getFile(item.file.Id)).data;
      //   const blob = this.b64toBlob(file);

      //   this.fileData = new File([blob], item.Name, {
      //     type: blob.type,
      //   });

      //   if (item.file.ExtensionType.FileType === "text") {
      //     this.src = await this.readFileAsTextAsync(this.fileData);
      //   } else if (item.file.ExtensionType.FileType === "Image") {
      //     this.src = await this.readFileAsDataUrlAsync(this.fileData);
      //   } else if (item.file.ExtensionType.FileType === "Adobe Reader") {
      //     let src = await this.readFileAsDataUrlAsync(this.fileData);
      //     this.src = pdf.createLoadingTask(src);
      //     this.src.promise.then((p) => {
      //       console.log(JSON.stringify(p._pdfInfo));
      //       console.log(p, p._pdfInfo);
      //       this.pageCount = p._pdfInfo.numPages;
      //     });
      //   }
      //   this.previewFile = item.file;
      // } catch (error) {
      //   this.error = error;
      // }
      // console.log("preview", item.file);
      this.fileItem = {
        Id: item.file.Id,
        FileName: item.file.Name,
        FileType: item.file.ExtensionType.FileType,
        FileExtension: item.file.ExtensionType.FileExtension,
      };
      this.showPreview = true;
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    readFileAsDataUrlAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsDataURL(file);
      });
    },
    readFileAsTextAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsText(file);
      });
    },
    onAddClick() {
      const uploader = document.getElementById(this.id);
      if (uploader !== null && uploader !== undefined) {
        uploader.click();
      }
      // this.$refs.uploader.click();
    },
    remove(file) {
      // console.log(file, this.selectedFiles[0]);
      this.selectedFiles = this.selectedFiles.filter(
        (o) => o.file.Name !== file.file.Name
      );
      this.emitFiles(this.selectedFiles);
      const uploader = document.getElementById(this.id);
      if (uploader !== null && uploader !== undefined) {
        uploader.value = "";
      }
    },
    async handleFileChange(files) {
      let displayWarning = false;
      // console.log("file change: ", e);
      // const files = e.target.files;
      // console.log(files);
      if (!files) {
        return;
      }
      if (files.length === 0) {
        return;
      }

      try {
        const newItems = [];
        for (const file of files) {
          const filesize = (file.size / 1024 / 1024).toFixed(4);
          if (filesize > this.maxFileSizeMB) {
            throw {
              customMessage: this.$t("components.new_claim.upload_size_error"),
            };
          }
          if (
            this.internalFiles.filter((o) => o.file.Name === file.name)
              .length === 0
          ) {
            const newItem = await this.createUploadFile(file);
            newItems.push(newItem);
          } else {
            displayWarning = true;
          }
        }
        if (newItems.length > 0) {
          this.emitUploadStart();
          // let token = TokenService.getToken();
          // axios.defaults.headers.common = {};
          // const res = (
          //   await axios.post(this.fileUploadUrl, newItems, {
          //     withCredentials: false,
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   })
          // ).data;
          const res = (
            await this.uploadFile({
              planIdentityId: this.user.plan,
              payload: newItems,
            })
          ).data;
          for (const [i, fileItem] of res.entries()) {
            fileItem.Base64Contents = newItems[i].Base64Contents;
            this.selectedFiles.push({ file: fileItem });
          }
          this.emitFiles(this.selectedFiles);
          if (this.internalFiles === null || this.internalFiles === undefined) {
            this.internalFiles = [];
          }
          for (const f of this.selectedFiles) {
            f.description = this.createDescription(f);
          }
          this.internalFiles = this.internalFiles.concat(this.selectedFiles);
        }
      } catch (error) {
        console.log("file upload error:", error);
        this.emitUploadError(error);
      } finally {
        this.fileExistsWarning = displayWarning;
      }
    },
    async getFiles() {
      if (this.filesIds && this.filesIds !== null) {
        try {
          this.emitUploadStart();
          this.selectedFiles = [];
          if (this.internalFiles === null || this.internalFiles === undefined) {
            this.internalFiles = [];
          }

          const result = (
            await axios.post(`${this.getFilesUrl}`, this.filesIds)
          ).data;

          for (const item of result) {
            const newfile = await this.makeFile(item);
            const internalItem = { file: item, File: newfile };
            internalItem.description = this.createDescription(internalItem);
            this.selectedFiles.push(internalItem);
            this.internalFiles.push(internalItem);
          }
          this.emitUploadEnd();
        } catch (error) {
          this.emitUploadError(error);
        }
      }
    },
    async makeFile(item) {
      const res = (await axios.get(`${this.fileUploadUrl}/${item.Id}`)).data;
      const blob = this.b64toBlob(res);
      return new File([blob], item.Name, { type: blob.type });
    },
    async createUploadFile(file) {
      const res = await this.readFileAsDataUrlAsync(file);
      const tempFolder =
        this.currentFolder == null ? null : { Path: this.currentFolder };
      const user = await AuthService.getUser();
      const itemFile = {
        File: {
          OwnerId: user.profile.sub, //this.ownerId,
          Owner: { UserName: this.user.username },
          Name: file.name,
          FileSize: file.size,
          KeepRevisions: this.keepRevisions,
          Temporary: false,
          MarkedForDeletion: false,
          IsPublic: this.public ? true : false,
          Folder: tempFolder,
          Categories: this.categories
            ? this.categories.map((x) => ({ Name: x }))
            : null,
          Tags: this.tags ? this.tags.map((x) => ({ Name: x })) : null,
          Group: this.groups ? this.groups.map((x) => ({ Name: x })) : null,
          Locations: [],
        },
        Base64Contents: res.split(",")[1],
        // File: file,
      };

      return itemFile;
    },
    async download(item) {
      const res = await axios.get(`${this.fileUploadUrl}/${item.file.Id}`, {
        withCredentials: false,
      });
      const data = this.b64toBlob(res.data);
      saveAs(data, item.file.Name);
    },
  },
};
</script>

<style scoped>
.text--white /deep/ label {
  color: white;
}
</style>
<style>
.chip-btn {
  margin-left: 5px !important;
}
</style>
