<template>
  <div>
    <v-form v-model="valid">
      <v-container class="py-4">
        <v-row align-content="center">
          <v-col cols="12" md="6" class="py-0 mb-4">
            <v-row no-gutters>
              <v-col class="pa-0" cols="auto" align-self="center">
                <v-avatar
                  v-show="internalMember.FirstName"
                  color="white"
                  size="80"
                  class="mb-2 avatar"
                >
                  <img
                    v-if="internalMember.Image"
                    :src="'data:image/jpg;base64,' + internalMember.Image"
                    alt="member-img"
                  />
                  <v-avatar v-else color="secondary" size="78">
                    <span class="text-h4 white--text">
                      {{
                        internalMember.FirstName &&
                        internalMember.FirstName.charAt(0) +
                          (internalMember.LastName &&
                            internalMember.LastName.charAt(0))
                      }}
                    </span>
                  </v-avatar>
                </v-avatar>
                <v-btn
                  v-show="internalMember.FirstName"
                  fab
                  x-small
                  class="camera-btn secondary lighten-3"
                  @click="showPhotoCrop = true"
                  :loading="savingImage"
                >
                  <v-icon class="mr-0" style="margin-right: 0"
                    >mdi-camera-outline</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col align-self="center">
                {{
                  $t("components.registration_completion_form.upload_picture")
                }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <member-image-dialog
      :model="showPhotoCrop"
      @save="cropSuccess($event)"
      @close="showPhotoCrop = false"
    />
  </div>
</template>

<script>
import MemberImageDialog from "@/components/MemberInformation/items/MemberImageDialog.vue";
export default {
  components: { MemberImageDialog },
  props: {
    member: Object,
    savingImage: Boolean,
  },
  data() {
    return {
      dateMenu: false,
      internalMember: {},
      valid: false,
      showPhotoCrop: false,
      sinRules: [
        this.$rules.required,
        (v) => (v && v.length === 4) || "Must be 4 digits",
      ],
    };
  },
  watch: {
    member: {
      handler(v) {
        this.internalMember = v;
      },
      deep: true,
    },
  },
  mounted() {
    if (this.member !== null) {
      this.internalMember = JSON.parse(
        JSON.stringify(this.member ? this.member : {})
      );
    }
  },
  methods: {
    cropSuccess(imgDataUrl) {
      this.internalMember.Image = imgDataUrl.split(",")[1];
      this.$emit("save-image", this.internalMember.Image);
      this.showPhotoCrop = false;
    },
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-btn__content .v-icon {
  margin-right: 0 !important;
}
</style>
