<template>
  <div>
    <material-card-custom
      style="z-index: 2"
      :size.sync="internalSize"
      :isPensions="true"
      :height.sync="internalHeight"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      color="primary"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :title="$t('globals.loading')"
    >
      <v-card height="432" flat class="rounded-t-0" light>
        <v-list>
          <v-list-item>
            <v-list-item-content class="text-left font-weight-bold">
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-5 my-1"></v-divider>
          <v-list-item>
            <v-list-item-content class="text-center font-weight-bold py-10">
              <v-col cols="12" class="primary--text">
                <v-progress-circular
                  size="50"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mx-4 my-1"></v-divider>
          <v-list-item>
            <v-list-item-content class="text-left font-weight-bold">
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="pensionSummaryData.AnnualPension"
            class="mx-4 my-1"
          ></v-divider>
        </v-list>
      </v-card>
      <v-row no-gutters class="bottom-btn">
        <v-btn
          disabled
          class="secondary lighten-2 mt-2 mt-sm-7"
          width="150px"
          to="pension-earned-to-date"
          >{{ $t("globals.more") }}</v-btn
        >
      </v-row>
    </material-card-custom>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import MaterialCardCustom from "@/components/VueMaterialDashboard/material/MaterialCardCustomDashboard.vue";
export default {
  components: {
    MaterialCardCustom,
  },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      pensionSummaryData: {},
      loading: false,
      printPdf: false,
      savePdf: false,
      reportData: null,
      pastYear: moment().year() - 1,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    pastYearData() {
      if (
        this.pensionSummaryData &&
        this.pensionSummaryData.servicePeriodsData
      ) {
        const pastYearData = this.pensionSummaryData.servicePeriodsData.find(
          (p) => p.year === this.pastYear.toString()
        );
        return pastYearData || {};
      }
      return {};
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getPensionSummaryData"]),
    async loadData() {
      try {
        this.loading = true;
        this.pensionSummaryData = (
          await this.getPensionSummaryData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.loading = false;
      }
    },
    downloadReport() {
      console.log("Download Report");
    },
    viewReport() {
      console.log("View Report");
    },
    print() {
      console.log("Print Report");
      this.printPdf = true;
    },
  },
};
</script>
<style scoped>
.printer-icon {
  position: absolute !important;
  top: 15px;
  right: 15px;
}
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.monthly-pension {
  font-weight: 600 !important;
}
</style>
