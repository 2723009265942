var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list',{staticClass:"results-list",attrs:{"dense":""}},[(_vm.foundMenuItems.length)?_c('v-subheader',{staticClass:"text-subtitle-2 font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.$t("components.dashboard.search.pages")))]):_vm._e(),(_vm.foundMenuItems.length)?_c('v-list-item-group',_vm._l((_vm.foundMenuItems),function(item,i){return _c('v-list-item',{key:'page' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.openMenuItem(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.TitleEN : item.TitleFR)+" ")])}),1):_vm._e(),(_vm.foundDownloads.length)?_c('v-subheader',{staticClass:"text-subtitle-2 font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.$t("components.dashboard.search.documents")))]):_vm._e(),(_vm.foundDownloads.length)?_c('v-list-item-group',_vm._l((_vm.foundDownloads),function(item,i){return _c('v-list-item',{key:'doc' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.$emit('view-pdf', item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.TitleEN : item.TitleFR)+" ")])}),1):_vm._e(),(
        _vm.foundLifeEvents.length ||
        _vm.foundLifeCategories.length ||
        _vm.foundLifeAnswers.length
      )?_c('v-subheader',{staticClass:"text-subtitle-2 font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.$t("components.dashboard.search.life_events")))]):_vm._e(),(_vm.foundLifeCategories.length)?_c('v-list-item-group',_vm._l((_vm.foundLifeCategories),function(item,i){return _c('v-list-item',{key:'le-cat' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.openSelectedLifeCategory(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.CategoryEN : item.CategoryFR)+" ")])}),1):_vm._e(),(_vm.foundLifeEvents.length)?_c('v-list-item-group',_vm._l((_vm.foundLifeEvents),function(item,i){return _c('v-list-item',{key:'le' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.openSelectedLifeEvent(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.QuestionEN : item.QuestionFR)+" ")])}),1):_vm._e(),(_vm.foundLifeAnswers.length)?_c('v-list-item-group',_vm._l((_vm.foundLifeAnswers),function(item,i){return _c('v-list-item',{key:'le' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.openSelectedLifeEvent(item)}}},[_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',{staticClass:"text-decoration-underline"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.QuestionEN : item.QuestionFR)+" ")]),_c('v-list-item-subtitle',{staticStyle:{"max-height":"20px"}},[_c('span',{staticClass:"elipsis-text",domProps:{"innerHTML":_vm._s(
                _vm.$vuetify.lang.current === 'en' ? item.AnswerEN : item.AnswerFR
              )}})])],1)],1)}),1):_vm._e(),(_vm.foundFaqs.length)?_c('v-subheader',{staticClass:"text-subtitle-2 font-weight-bold primary--text"},[_vm._v(_vm._s(_vm.$t("components.dashboard.search.faq")))]):_vm._e(),(_vm.foundFaqs.length)?_c('v-list-item-group',_vm._l((_vm.foundFaqs),function(item,i){return _c('v-list-item',{key:'faq' + i,staticClass:"text-subtitle-2 text-left",on:{"click":function($event){return _vm.openSelectedFaq(item)}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.current === "en" ? item.QuestionEN : item.QuestionFR)+" ")])}),1):_vm._e(),(!_vm.isSearchFound)?_c('v-list-item-group',[_c('v-list-item',{staticClass:"text-subtitle-2 text-left"},[_vm._v(" "+_vm._s(_vm.$t("components.dashboard.search.no_results"))+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }