<template>
  <div>
    <v-card flat height="400" color="rgb(0,0,0,0)">
      <v-container class="fill-height">
        <v-col class="pa-0 mt-4">
          <v-card-text>
            <v-row justify="center">
              <v-card
                style="border: 4px dotted gray !important"
                @click="addWidgetDialog = true"
                class="transparent"
                outlined
                height="240"
                width="240"
              >
                <v-container class="fill-height">
                  <v-card-text class="justify-center">
                    <v-icon size="80">mdi-plus</v-icon>
                  </v-card-text>
                </v-container>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-2">
            <v-btn height="70" color="primary lighten-1" block to="contact-us">
              <v-row no-gutters justify="space-around" align="center">
                <v-icon color="rgba(230, 230, 230, 0.4)"
                  >mdi-message-reply-text</v-icon
                >
                {{
                  $t("components.vue_material_dashboard.add_widget.contact_us")
                }}
                <v-icon class="circled-arrow"
                  >mdi-arrow-right-thin-circle-outline</v-icon
                >
              </v-row>
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-container>
    </v-card>
    <v-dialog v-model="addWidgetDialog" width="400">
      <v-card>
        <v-card-title>
          {{ $t("components.vue_material_dashboard.add_widget_dialog.title") }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-select
              :label="
                $t('components.vue_material_dashboard.add_widget_dialog.select')
              "
              :no-data-text="
                $t(
                  'components.vue_material_dashboard.add_widget_dialog.select.no_data'
                )
              "
              v-model="widgetToAdd"
              class="align-center"
              item-text="title"
              item-value="value"
              :items="availableDashboardItemTypes"
              @change="addWidget"
            />
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    id: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      addWidgetDialog: false,
      widgetToAdd: undefined,
    };
  },
  computed: {
    ...mapGetters("common", ["availableDashboardItemTypes"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    addWidget(widgetName) {
      this.addWidgetDialog = false;
      this.$emit("add-widget", widgetName);
    },
  },
};
</script>
<style scoped>
.v-btn__content .v-icon {
  font-size: 50px !important;
  margin-right: 5px !important;
  margin-left: 0px !important;
}
.v-btn__content .v-icon.circled-arrow {
  font-size: 20px !important;
  margin-right: 0px !important;
  margin-left: 5px !important;
}
</style>
