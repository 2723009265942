<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    persistent
    :fullscreen="fullScreen"
    hide-overlay
    scrollable
    v-model="view"
    max-width="800"
    max-height="80hv"
  >
    <v-card style="display: flex; flex-direction: column">
      <v-card-title class="primary pa-2 pl-4 text-subtitle-1">
        <span class="white--text">{{ fileItems.UploadName }}</span>
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pa-2 pt-0" style="background-color: grey">
        <div v-for="file in fileItems.FileSegments" :key="file.PhysicalFileId">
          <div class="filename pt-2 white--text">{{ file.FileName }}</div>

          <!-- Pdf Preview -->
          <div v-if="file.FileExtension === 'pdf'">
            <pdf-preview :fileItem="file" />
          </div>

          <!-- Image preview -->
          <div
            v-else-if="
              file.FileExtension === 'png' ||
              file.FileExtension === 'jpg' ||
              file.FileExtension === 'jpeg'
            "
            class="file-preview-container"
          >
            <v-img :src="'data:image/jpg;base64,' + file.Base64"></v-img>
          </div>

          <!-- text preview -->
          <div
            v-else-if="file.FileExtension === 'txt'"
            class="file-preview-container"
            style="font-size: 1em; overflow-y: auto"
          >
            {{ formatBase64ToText(file.Base64) }}
          </div>
          <v-card-text v-else class="file-preview-container">
            <h5 class="white--text">
              {{ $t("components.filepreview.no_preview") }}
            </h5>
          </v-card-text>
        </div>
      </v-card-text>
      <v-card-actions v-if="!fullScreen" class="justify-end">
        <v-btn text @click="$emit('close')">
          {{ $t("globals.close") }}
        </v-btn>
      </v-card-actions>

      <v-dialog scrollable v-model="loadingFile" persistent max-width="200">
        <v-card>
          <v-card-title>{{
            $t("components.filepreview.please_wait")
          }}</v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md>
              <v-layout row justify-center>
                <v-flex xs12 sm6 md4 lg3>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import PdfPreview from "@/components/controls/PdfPreview.vue";
export default {
  name: "file-preview-base64",
  props: ["fileItems", "view", "fullScreen"],
  components: {
    PdfPreview,
  },
  mounted() {
    if (this.fullScreen) {
      this.previewZoom = 60;
    } else {
      this.previewZoom = 100;
    }
  },
  data() {
    return {
      loadingFile: false,
      previewZoom: 60,
      file: null,
      pdfSrc: null,
      pageCount: 0,
      src: null,
    };
  },
  watch: {},
  methods: {
    formatBase64ToText(base64Txt) {
      return atob(base64Txt);
    },
  },
};
</script>
<style>
.file-preview-container {
  height: 100%;
  width: 750px;
  margin: 10px auto;
}
.filename {
  font-size: 14px;
  font-weight: 600;
}
</style>
