<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    :widgetId="widgetId"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :title="$t('components.vue_material_dashboard.items.beneficiaries.title')"
  >
    <router-link to="beneficiary-centre" class="text-decoration-none">
      <v-card-text class="text-left pb-0">
        <v-list three-line>
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item v-if="loading">
              <v-row justify="center">
                <v-progress-circular
                  size="50"
                  class="mt-10"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-row>
            </v-list-item>
            <div v-show="!loading">
              <template v-for="i in 2">
                <v-list-item :key="i">
                  <v-list-item-avatar color="primary">
                    <v-icon dark> mdi-account </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <router-link
                      class="secondary--text text-decoration-none"
                      to="member-information"
                    >
                      <v-list-item-title>
                        {{
                          beneficiariesData[i - 1] &&
                          beneficiariesData[i - 1].FirstName +
                            " " +
                            (beneficiariesData[i - 1] &&
                              beneficiariesData[i - 1].LastName)
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-text="
                          beneficiariesData[i - 1] &&
                          beneficiariesData[i - 1].Dob
                        "
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-text="
                          beneficiariesData[i - 1] &&
                          beneficiariesData[i - 1].Relationship
                        "
                      ></v-list-item-subtitle>
                    </router-link>
                  </v-list-item-content>

                  <v-list-item-action class="ma-0"
                    ><v-col align-self="center" class="px-0">
                      <v-row no-gutters justify="center">
                        <span class="text-h6 grey--text">{{
                          beneficiariesData[i - 1] &&
                          beneficiariesData[i - 1].Share
                        }}</span>
                        <v-icon color="grey"> mdi-percent-outline </v-icon>
                      </v-row>
                      <v-chip
                        color="green"
                        dark
                        small
                        v-show="
                          beneficiariesData[i - 1] &&
                          beneficiariesData[i - 1].Status === 'primary'
                        "
                      >
                        <span class="d-none d-sm-flex">primary</span>
                        <v-icon class="d-flex d-sm-none" small>mdi-key</v-icon>
                      </v-chip>
                    </v-col>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-show="i === 1" :key="i + '-divider'"></v-divider>
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </router-link>
  </material-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      selectedItem: undefined,
      beneficiariesData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getBeneficiariesData"]),
    async loadData() {
      try {
        this.loading = true;
        this.beneficiariesData = (
          await this.getBeneficiariesData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        (this.beneficiariesData = [
          {
            FirstName: "Maria",
            LastName: "Foster",
            Dob: "1982-04-13",
            Relationship: "Spouse",
            Share: 100,
            Status: "primary",
          },
          {
            FirstName: "Dimitris",
            LastName: "Vagias",
            Dob: "1953-05-25",
            Relationship: "Father",
            Share: 100,
            Status: "secondary",
          },
          {
            FirstName: "Andreas",
            LastName: "Vagias",
            Dob: "1982-04-13",
            Relationship: "Brother",
            Share: 30,
          },
          {
            FirstName: "Dimitris",
            LastName: "Vagias",
            Dob: "1953-05-25",
            Relationship: "Father",
            Share: 20,
          },
        ]),
          (this.loading = false);
      }
    },
    printFile() {
      console.log("Print file");
    },
    downloadFile() {
      console.log("Download file");
    },
  },
};
</script>
<style scoped>
.key {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
