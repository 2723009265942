var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.image)?_c('div',[_c('v-file-input',{ref:"fileIcon",attrs:{"label":_vm.label,"accept":".jpg, .png","rules":_vm.rules},on:{"change":_vm.uploadImg},model:{value:(_vm.uploadUrl),callback:function ($$v) {_vm.uploadUrl=$$v},expression:"uploadUrl"}})],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',{class:`
            v-input v-input--is-label-active v-input--is-dirty
            theme--light
            v-text-field v-text-field--is-booted`},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot",staticStyle:{"margin":"0 auto"}},[_c('label',{staticClass:"v-label v-label--active theme--light mb-1",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":_vm.imgControlId}},[_vm._v(_vm._s(_vm.$t("components.member_image_dialog.clear")))]),_c('img',_vm._g(_vm._b({staticStyle:{"height":"100px","cursor":"pointer","margin":"0 auto"},attrs:{"id":_vm.imgControlId,"src":_vm.image},on:{"click":function($event){return _vm.$emit('image-data', null)}}},'img',attrs,false),on))])])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("globals.clear")))])])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticStyle:{"height":"200px"}},[_c('vueCropper',{ref:"cropper",attrs:{"img":_vm.imageData,"canMove":true,"canMoveBox":true,"info":true,"autoCrop":true,"autoCropWidth":160,"autoCropHeight":160,"outputType":"png"},on:{"realTime":_vm.realTime}})],1),(_vm.previews)?_c('div',{staticClass:"show-preview",style:({
          width: _vm.previews.w + 'px',
          height: _vm.previews.h + 'px',
          overflow: 'hidden',
          margin: '5px auto',
        })},[_c('div',{style:(_vm.previews.div)},[_c('img',{style:(_vm.previews.img),attrs:{"src":_vm.previews.url}})])]):_vm._e(),_c('div',{staticClass:"col-auto align-self-center"},[_c('div',{ref:"icon",staticClass:"svg-icon",staticStyle:{"width":"50px"}})]),_c('v-row',{staticClass:"pb-5 pr-5",attrs:{"no-gutters":"","justify":"end"}},[_c('div',{staticClass:"col-auto align-self-center"},[_c('v-btn',{staticClass:"mr-2",on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("globals.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.imageData},on:{"click":_vm.saveImageHandler}},[_vm._v(" "+_vm._s(_vm.$t("globals.save"))+" ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }