<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    :title="
      $t(
        'components.vue_material_dashboard.items.recent_activity_dashboard_item.title'
      )
    "
  >
    <v-card-title>
      {{
        $t(
          "components.vue_material_dashboard.items.recent_activity_dashboard_item.title"
        )
      }}
    </v-card-title>
    <v-card-text>
      Sed vel semper turpis, sit amet rutrum lectus. Sed commodo convallis
      facilisis. Donec dictum justo non velit viverra convallis non in tellus.
      Aenean consequat arcu sed dolor scelerisque dictum. Suspendisse nec justo
      sit amet libero interdum scelerisque. Nunc a commodo neque, vel faucibus
      erat. Vivamus dapibus vulputate mauris non hendrerit. Phasellus feugiat
      sit amet risus a tristique. Duis a augue congue, facilisis ante vel,
      efficitur ex. Quisque posuere justo quis efficitur porttitor. Nunc blandit
      nisl vitae est tempor sagittis. Nullam sed fringilla augue, vel posuere
      mi. Donec non leo augue.
    </v-card-text>
  </material-card>
</template>

<script>
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
