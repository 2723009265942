var render = function render(){var _vm=this,_c=_vm._self._c;return _c('material-card',{attrs:{"size":_vm.internalSize,"order":_vm.internalOrder,"maxOrderValue":_vm.maxOrderValue,"widgetId":_vm.widgetId,"title":_vm.$t('components.vue_material_dashboard.items.beneficiaries.title')},on:{"update:size":function($event){_vm.internalSize=$event},"update:order":function($event){_vm.internalOrder=$event},"remove-widget":function($event){return _vm.$emit('remove-widget', $event)},"add-widget":function($event){return _vm.$emit('add-widget', $event)}}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"beneficiary-centre"}},[_c('v-card-text',{staticClass:"text-left pb-0"},[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[(_vm.loading)?_c('v-list-item',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{staticClass:"mt-10",attrs:{"size":"50","indeterminate":"","color":"primary"}})],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._l((2),function(i){return [_c('v-list-item',{key:i},[_c('v-list-item-avatar',{attrs:{"color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('router-link',{staticClass:"secondary--text text-decoration-none",attrs:{"to":"member-information"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.beneficiariesData[i - 1] && _vm.beneficiariesData[i - 1].FirstName + " " + (_vm.beneficiariesData[i - 1] && _vm.beneficiariesData[i - 1].LastName))+" ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                        _vm.beneficiariesData[i - 1] &&
                        _vm.beneficiariesData[i - 1].Dob
                      )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
                        _vm.beneficiariesData[i - 1] &&
                        _vm.beneficiariesData[i - 1].Relationship
                      )}})],1)],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-col',{staticClass:"px-0",attrs:{"align-self":"center"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"text-h6 grey--text"},[_vm._v(_vm._s(_vm.beneficiariesData[i - 1] && _vm.beneficiariesData[i - 1].Share))]),_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" mdi-percent-outline ")])],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(
                        _vm.beneficiariesData[i - 1] &&
                        _vm.beneficiariesData[i - 1].Status === 'primary'
                      ),expression:"\n                        beneficiariesData[i - 1] &&\n                        beneficiariesData[i - 1].Status === 'primary'\n                      "}],attrs:{"color":"green","dark":"","small":""}},[_c('span',{staticClass:"d-none d-sm-flex"},[_vm._v("primary")]),_c('v-icon',{staticClass:"d-flex d-sm-none",attrs:{"small":""}},[_vm._v("mdi-key")])],1)],1)],1)],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(i === 1),expression:"i === 1"}],key:i + '-divider'})]})],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }