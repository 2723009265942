export const translate = (item, language) => {
  if (!item) return "";
  //console.log(item, item.Translations)
  if (item.Translations) {
    let trans = item.Translations.filter((o) => o.LanguageCode === language);
    //console.log(trans)
    return trans.length > 0
      ? trans[0].DisplayValue
      : language + "-" + item.Name;
  }
  return item.Name;
};

export const translateProperty = (item, property, language) => {
  if (!item) return "";
  //console.log(item, item.Translations)
  if (item.Translations) {
    let trans = item.Translations.filter((o) => o.LanguageCode === language);
    //console.log(trans)
    return trans.length > 0
      ? trans[0][property]
      : language + "-" + item[property];
  }
  return item[property];
};
