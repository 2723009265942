<template>
  <div>
    <v-card-title class="title pb-0 mt-2">
      <v-icon color="primary" large class="mr-2">mdi-camera-outline</v-icon>
      {{ $t("components.new_claim.proofs_dialog.library") }}
    </v-card-title>
    <v-sheet class="mx-auto" max-width="800" height="210">
      <v-slide-group
        v-model="model"
        class="pa-4 pt-0"
        center-active
        show-arrows
      >
        <v-slide-item
          v-for="(proof, i) in proofs"
          :key="proof.FileCategory + ' ' + i"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'accent' : 'grey lighten-2'"
            class="ma-4"
            height="180"
            width="120"
            @click="toggle"
          >
            <v-row
              no-gutters
              class="fill-height"
              align="center"
              @click="showPreview(proof)"
            >
              <v-col>
                <v-col
                  class="pa-0 upload-name"
                  :class="active ? 'white--text' : ''"
                  >{{ proof.UploadName }}
                </v-col>
                <v-img
                  v-if="proof.FileSegments[0].FileExtension !== 'pdf'"
                  :src="
                    proof.FileSegments[0].Base64
                      ? 'data:image/jpg;base64,' + proof.FileSegments[0].Base64
                      : null
                  "
                  alt="proof"
                  contain
                  height="120"
                  width="110"
                  class="mx-auto"
                />

                <pdf
                  v-if="proof.FileSegments[0].FileExtension === 'pdf'"
                  :src="
                    'data:application/pdf;base64,' +
                    proof.FileSegments[0].Base64
                  "
                  style="display: inline-block; width: 90px"
                ></pdf>

                <v-col
                  class="pa-0 pt-1 segment"
                  :class="active ? 'white--text' : ''"
                  >{{ proof.FileSegments[0].FileName }}</v-col
                >
              </v-col>
              <div class="close-btn">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="action-icon"
                      :class="active ? 'white--text' : ''"
                      @click.stop="checkIfCanRemoveProof(i)"
                      >mdi-close</v-icon
                    >
                  </template>
                  <span>{{
                    $t(`components.new_claim.proofs_dialog.remove_proof`)
                  }}</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <file-preview-base64
      :fullScreen="false"
      v-if="preview"
      :view="preview"
      :fileItems="fileItems"
      @close="closePreview"
    />

    <v-dialog v-model="deleteProofDialog" width="400" class="mx-0">
      <v-card max-width="400">
        <v-card-title class="primary white--text mb-6 py-2">
          <v-icon dark class="mr-2">mdi-delete-forever-outline</v-icon>
          {{
            $t("components.new_claim.proofs_dialog.confirm_remove.title")
          }}</v-card-title
        >
        <v-card-text class="text-subtitle-1 font-weight-medium">
          {{ $t("components.new_claim.proofs_dialog.confirm_remove.text") }}
        </v-card-text>
        <v-card-actions class="justify-end pr-6">
          <v-btn
            text
            @click="
              deleteProofDialog = false;
              proofToDeleteIndex = null;
            "
          >
            {{ $t("globals.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="removeProof">
            {{ $t("globals.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unableRemoveProofDialog" width="500" class="mx-0">
      <v-card max-width="500">
        <v-card-title class="primary white--text mb-6 py-2">
          <v-icon dark class="mr-2">mdi-alert-octagon-outline</v-icon>
          {{
            $t("components.new_claim.proofs_unable_remove_dialog.title")
          }}</v-card-title
        >
        <v-card-text class="text-subtitle-1 font-weight-medium text-left">
          {{ $t("components.new_claim.proofs_unable_remove_dialog.text") }}
        </v-card-text>
        <v-card-actions class="justify-end pr-6">
          <v-btn color="primary" @click="unableRemoveProofDialog = false">
            {{ $t("globals.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import FilePreviewBase64 from "@/components/controls/FilePreviewBase64.vue";
export default {
  components: {
    pdf,
    FilePreviewBase64,
  },
  props: {
    expense: {
      type: Object,
      default: () => {},
    },
    receiptToPreview: {
      type: Object,
      default: () => {},
    },
    proofs: {
      type: Array,
      default: () => [],
    },
    saving: Boolean,
    editMode: Boolean,
    claimValid: Boolean,
  },
  data() {
    return {
      showPreviewDialog: false,
      deleteProofDialog: false,
      unableRemoveProofDialog: false,
      model: null,
      preview: false,
      fileItems: null,
      proofToDeleteIndex: null,
    };
  },
  watch: {
    receiptToPreview: {
      handler(v) {
        if (v.UploadName) this.showPreview(v);
      },
    },
  },
  methods: {
    showPreview(proof) {
      this.preview = true;
      this.fileItems = proof;
    },
    closePreview() {
      this.preview = false;
      this.$emit("close-preview");
    },
    removeProof() {
      this.$emit("remove-proof", this.proofToDeleteIndex);
      this.deleteProofDialog = false;
    },
    checkIfCanRemoveProof(i) {
      if (this.proofs[i]?.ClaimLines?.length) {
        this.unableRemoveProofDialog = true;
        return;
      }
      this.proofToDeleteIndex = i;
      this.deleteProofDialog = true;
    },
  },
};
</script>
<style scoped>
.v-tooltip__content {
  max-width: 120px !important;
  font-size: 12px;
}
.close-btn {
  position: absolute;
  top: -10px;
  right: -25px;
  z-index: 1;
}
.v-btn--fab.v-size--x-small {
  height: 20px;
  width: 20px;
}
.preview-image:hover {
  cursor: pointer;
}
.segment {
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.action-icon {
  position: absolute;
  top: 4px;
  right: 4px;
}
.action-icon:hover {
  color: var(--primary) !important;
}
.upload-name {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
