<template v-if="fetchedAddress">
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="py-0">
        <v-row no-gutters>
          <v-col cols="12" class="py-0 text--secondary">
            <span
              class="text-subtitle-1 fetched-address"
              style="white-space: pre"
            >
              {{ fetchedAddress }}
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="!editMode">
      <v-col cols="12" class="pt-0">
        <v-btn
          class="mr-3 mb-2"
          :loading="saving"
          color="primary"
          @click="$emit('set-edit-mode')"
        >
          <span>
            {{ fetchedAddress === "" ? $t("globals.add") : $t("globals.edit") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    editMode: Boolean,
    fetchedAddress: String,
    saving: Boolean,
  },
};
</script>
<style scoped>
.fetched-address {
  line-height: 1 !important;
}
</style>
