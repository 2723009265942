var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.member)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"py-4"},[(!_vm.loading)?_c('v-row',{attrs:{"align-content":"center"}},[(_vm.checkVisible('Certificate'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{class:_vm.checkRequired('Certificate') ? 'required' : '',attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.certificateLabel ||
              _vm.$t('components.member_validation_form.student_id_certficate'),"value":_vm.member.Certificate,"type":"number","rules":_vm.checkRequired('Certificate')
                ? _vm.sinRulesRequired
                : _vm.sinRulesOptional}})],1):_vm._e(),(_vm.checkVisible('First Name'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{class:_vm.checkRequired('First Name') ? 'required' : '',attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.firstname'),"value":_vm.member.FirstName,"rules":_vm.checkRequired('First Name')
                ? [_vm.$rules.required, _vm.$rules.validName]
                : [_vm.$rules.validName]}})],1):_vm._e(),(_vm.checkVisible('Last Name'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{class:_vm.checkRequired('Last Name') ? 'required' : '',attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.lastname'),"value":_vm.member.LastName}})],1):_vm._e(),(_vm.member.MiddleName && _vm.checkVisible('Middle Name'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('no-autocomplete-textfield',{class:_vm.checkRequired('Middle Name') ? 'required' : '',attrs:{"disabled":"","clearable":"","outlined":"","label":_vm.$t('components.registration_completion_form.middlename'),"value":_vm.member.MiddleName,"rules":_vm.checkRequired('Middle Name')
                ? [_vm.$rules.required, _vm.$rules.validLastName]
                : [_vm.$rules.validLastName]}})],1):_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[(
              _vm.optionalMemberFields.filter((o) => o === 'DateOfBirth').length >
                0 && _vm.checkVisible('Date Of Birth')
            )?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('####-##-##'),expression:"'####-##-##'"}],class:_vm.checkRequired('Date Of Birth') ? 'required' : '',attrs:{"clearable":"","dense":_vm.$vuetify.breakpoint.xsOnly ? true : false,"outlined":"","value":_vm.dob,"label":_vm.$t('components.member_validation_form.dob'),"append-icon":"mdi-calendar","rules":_vm.checkRequired('Date Of Birth')
                    ? [_vm.$rules.required, _vm.$rules.validDate, _vm.$rules.dateOfBirth]
                    : [_vm.$rules.validDate, _vm.$rules.dateOfBirth]},on:{"click:append":function($event){_vm.dateMenu = true},"input":function($event){_vm.$emit('dob', $event);
                  _vm.dob.length > 3 ? (_vm.pickerDate = _vm.dob) : '';}}},'v-text-field',attrs,false))]}}],null,false,870621984),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{staticClass:"px-0 mx-0",attrs:{"value":_vm.dob,"max":_vm.$helpers.toISOStringLocal(new Date()),"picker-date":_vm.pickerDate},on:{"input":function($event){_vm.dateMenu = false;
                _vm.$emit('dob', $event);},"update:pickerDate":function($event){_vm.pickerDate=$event},"update:picker-date":function($event){_vm.pickerDate=$event}}})],1):_vm._e(),(
              _vm.optionalMemberFields.filter((o) => o === 'DateOfBirth')
                .length === 0 && _vm.checkVisible('Date Of Birth')
            )?_c('v-text-field',{class:_vm.checkRequired('Date Of Birth') ? 'required' : '',attrs:{"disabled":"","outlined":"","value":_vm.dob,"label":_vm.$t('components.member_validation_form.dob'),"append-icon":"mdi-calendar","readonly":"","rules":_vm.checkRequired('Date Of Birth') ? [_vm.$rules.required] : []}}):_vm._e()],1),(_vm.checkVisible('Gender'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{class:_vm.checkRequired('Gender') ? 'required' : '',attrs:{"disabled":"","outlined":"","value":_vm.$vuetify.lang.current === 'en'
                ? _vm.enumModels.Gender.filter(
                    (o) => o.value === _vm.member.Gender
                  )[0]?.textEn
                : _vm.enumModels.Gender.filter(
                    (o) => o.value === _vm.member.Gender
                  )[0]?.textFr,"label":_vm.$t('components.registration_completion_form.gender'),"rules":_vm.checkRequired('Gender') ? [_vm.$rules.required] : []}})],1):_vm._e(),(_vm.checkVisible('Member Group'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{class:_vm.checkRequired('Member Group') ? 'required' : '',attrs:{"disabled":"","outlined":"","value":_vm.member?.MemberGroup?.Translations?.filter(
                (o) => o.LanguageCode === _vm.$vuetify.lang.current
              )[0]
                ? _vm.member?.MemberGroup?.Translations?.filter(
                    (o) => o.LanguageCode === _vm.$vuetify.lang.current
                  )[0]?.Name
                : _vm.member?.MemberGroup?.Name,"label":_vm.memberGroupLabel ||
              _vm.$t('components.registration_completion_form.member_group'),"readonly":"","rules":_vm.checkRequired('Member Group') ? [_vm.$rules.required] : []}})],1):_vm._e()],1):_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"48"}})],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }