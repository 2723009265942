<template>
  <div>
    <v-form v-model="valid" v-if="member">
      <v-container class="py-4">
        <v-row align-content="center" v-if="!loading">
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="checkVisible('Certificate')"
          >
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="
                certificateLabel ||
                $t('components.member_validation_form.student_id_certficate')
              "
              :value="member.Certificate"
              type="number"
              :class="checkRequired('Certificate') ? 'required' : ''"
              :rules="
                checkRequired('Certificate')
                  ? sinRulesRequired
                  : sinRulesOptional
              "
            ></no-autocomplete-textfield>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="checkVisible('First Name')"
          >
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.firstname')"
              :value="member.FirstName"
              :rules="
                checkRequired('First Name')
                  ? [$rules.required, $rules.validName]
                  : [$rules.validName]
              "
              :class="checkRequired('First Name') ? 'required' : ''"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="checkVisible('Last Name')"
          >
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.lastname')"
              :value="member.LastName"
              :class="checkRequired('Last Name') ? 'required' : ''"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="member.MiddleName && checkVisible('Middle Name')"
          >
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.registration_completion_form.middlename')"
              :value="member.MiddleName"
              :rules="
                checkRequired('Middle Name')
                  ? [$rules.required, $rules.validLastName]
                  : [$rules.validLastName]
              "
              :class="checkRequired('Middle Name') ? 'required' : ''"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-menu
              v-if="
                optionalMemberFields.filter((o) => o === 'DateOfBirth').length >
                  0 && checkVisible('Date Of Birth')
              "
              v-model="dateMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-mask="'####-##-##'"
                  clearable
                  :dense="$vuetify.breakpoint.xsOnly ? true : false"
                  outlined
                  :value="dob"
                  :label="$t('components.member_validation_form.dob')"
                  append-icon="mdi-calendar"
                  @click:append="dateMenu = true"
                  @input="
                    $emit('dob', $event);
                    dob.length > 3 ? (pickerDate = dob) : '';
                  "
                  v-bind="attrs"
                  :rules="
                    checkRequired('Date Of Birth')
                      ? [$rules.required, $rules.validDate, $rules.dateOfBirth]
                      : [$rules.validDate, $rules.dateOfBirth]
                  "
                  :class="checkRequired('Date Of Birth') ? 'required' : ''"
                ></v-text-field>
              </template>
              <v-date-picker
                class="px-0 mx-0"
                :value="dob"
                :max="$helpers.toISOStringLocal(new Date())"
                @input="
                  dateMenu = false;
                  $emit('dob', $event);
                "
                :picker-date.sync="pickerDate"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-if="
                optionalMemberFields.filter((o) => o === 'DateOfBirth')
                  .length === 0 && checkVisible('Date Of Birth')
              "
              disabled
              outlined
              :value="dob"
              :label="$t('components.member_validation_form.dob')"
              append-icon="mdi-calendar"
              readonly
              :rules="checkRequired('Date Of Birth') ? [$rules.required] : []"
              :class="checkRequired('Date Of Birth') ? 'required' : ''"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="checkVisible('Gender')"
          >
            <v-text-field
              disabled
              outlined
              :value="
                $vuetify.lang.current === 'en'
                  ? enumModels.Gender.filter(
                      (o) => o.value === member.Gender
                    )[0]?.textEn
                  : enumModels.Gender.filter(
                      (o) => o.value === member.Gender
                    )[0]?.textFr
              "
              :label="$t('components.registration_completion_form.gender')"
              :rules="checkRequired('Gender') ? [$rules.required] : []"
              :class="checkRequired('Gender') ? 'required' : ''"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
            class="py-0"
            v-if="checkVisible('Member Group')"
          >
            <v-text-field
              disabled
              outlined
              :value="
                member?.MemberGroup?.Translations?.filter(
                  (o) => o.LanguageCode === $vuetify.lang.current
                )[0]
                  ? member?.MemberGroup?.Translations?.filter(
                      (o) => o.LanguageCode === $vuetify.lang.current
                    )[0]?.Name
                  : member?.MemberGroup?.Name
              "
              :label="
                memberGroupLabel ||
                $t('components.registration_completion_form.member_group')
              "
              readonly
              :rules="checkRequired('Member Group') ? [$rules.required] : []"
              :class="checkRequired('Member Group') ? 'required' : ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
              size="48"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters } from "vuex";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    member: Object,
    savingImage: Boolean,
    dob: String,
    formFields: Array,
  },
  data() {
    return {
      dateMenu: false,
      pickerDate: "1985-06",
      valid: false,
      showPhotoCrop: false,
      loading: false,
      sinRulesRequired: [
        this.$rules.required,
        (v) => (v && v.length === 4) || "Must be 4 digits",
      ],
      sinRulesOptional: [(v) => (v && v.length === 4) || "Must be 4 digits"],
    };
  },
  computed: {
    ...mapGetters("common", [
      "enumModels",
      "optionalMemberFields",
      "certificateLabel",
      "memberGroupLabel",
    ]),
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    checkVisible(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      // console.log(
      //   `check visible ${field} result is ${result ? result.Visible : false}`
      // );
      // return true;
      return result ? result.OnCreate === "Edit" : false;
    },
    checkRequired(field) {
      const result = this.formFields?.find((o) => o.Text === field);
      // console.log("check required result", result);
      // console.log(
      //   `check required ${field} result is ${
      //     result ? !result.IsOptional : false
      //   }`
      // );
      // return true;
      return result ? !result.IsOptional : false;
    },
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.4) !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.4) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-btn__content .v-icon {
  margin-right: 0 !important;
}
</style>
