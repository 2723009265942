<template>
  <div>
    <v-col class="pa-0 pb-2">
      <submit-claim
        :widgetIcon="widgetIcon"
        :size.sync="internalSize"
        :order.sync="internalOrder"
        :height.sync="internalHeight"
        :maxOrderValue="maxOrderValue"
      ></submit-claim>
    </v-col>
    <my-recent-claims
      :widgetIcon="widgetIcon"
      :size.sync="internalSize"
      :order.sync="internalOrder"
      :height.sync="internalHeight"
      :maxOrderValue="maxOrderValue"
    ></my-recent-claims>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubmitClaim from "@/components/VueMaterialDashboard/dashboardItems/SubmitClaimDashboardItemSba.vue";
import MyRecentClaims from "@/components/VueMaterialDashboard/dashboardItems/MyRecentClaimsDashboardItemSba.vue";
export default {
  components: {
    SubmitClaim,
    MyRecentClaims,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile"]),
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    headers() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "Id",
          class: "secondary--text text-uppercase pr-0 pl-2",
          cellClass: "px-2",
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
          value: "FirstName",
          class: "secondary--text text-uppercase px-0",
          cellClass: "px-2",
        },
        {
          text: "Dob",
          align: "center",
          sortable: false,
          value: "DateOfBirth",
          class: "secondary--text text-uppercase px-0",
          cellClass: "px-2",
        },
        {
          text: "Covered",
          align: "center",
          sortable: false,
          value: "Coverage",
          class: "secondary--text px-0",
          cellClass: "px-2",
          width: "20%",
        },
        {
          text: "Expires",
          align: "center",
          sortable: false,
          value: "ExpireDate",
          class: "secondary--text text-uppercase px-0",
          cellClass: "px-2",
        },
      ];
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    //this.loadData();
  },
  methods: {
    ...mapActions("data", ["getMemberData"]),
  },
};
</script>
<style scoped>
.avatar {
  position: absolute;
  top: 60px;
  left: 20px;
  border: 3px white solid;
  z-index: 1;
}
.card-link {
  cursor: pointer;
}
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.v-application .text-caption {
  font-weight: 600;
  line-height: 0.75rem !important;
  letter-spacing: 0.01em !important;
  font-size: 0.7rem !important;
}
.table >>> .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.6rem !important;
  font-weight: 600;
  height: 70px;
}
.table >>> .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.75rem !important;
  font-weight: 600;
  height: 35px;
  box-shadow: inset 0 1px 0 rgb(0 0 0 / 12%), inset 0 -1px 0 rgb(0 0 0 / 12%);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-track-piece {
  background-color: white;
  border-color: white;
  border-width: 2px 7px 2px 7px;
  border-style: solid;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--primary) !important;
  border-color: white;
  border-style: solid;
  border-width: 1px 4px 1px 4px;
}
</style>
