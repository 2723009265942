/* eslint-disable prettier/prettier */
export const createMemberFile = (
  memberFile,
  fileCategory,
  memberIdentityId
) => {
  let fileSegments = [];
  for (const item of memberFile) {
    // console.log("CREATE FILE", item);
    const fileSegment = {
      PhysicalFileId: item.file.Id,
      FileName: item.file.Name,
      FileSize: item.file.FileSize,
      FileType: item.file.ExtensionType.FileType,
      FileExtension: item.file.ExtensionType.FileExtension,
      Timestamp: item.file.DateCreated,
      Base64: item.file.Base64Contents,
    };
    fileSegments.push(fileSegment);
  }
  let ret = { FileSegments: fileSegments, ProofType: fileCategory };
  if (memberIdentityId !== null && memberIdentityId !== undefined) {
    ret.MemberIdentityId = memberIdentityId;
  }
  return ret;
};

export const createEmployerFile = (
  memberFile,
  fileCategory,
  employerIdentityId
) => {
  let fileSegments = [];
  for (const item of memberFile) {
    console.log(item);
    const fileSegment = {
      PhysicalFileId: item.file.Id,
      FileName: item.file.Name,
      FileSize: item.file.FileSize,
      FileType: item.file.ExtensionType.FileType,
      Timestamp: item.file.DateCreated,
    };
    fileSegments.push(fileSegment);
  }
  let ret = { FileSegments: fileSegments, FileCategory: fileCategory };
  if (employerIdentityId !== null && employerIdentityId !== undefined) {
    ret.EmployerIdentityId = employerIdentityId;
  }
  return ret;
};

export const mapFileSegmentIds = function mapMemberFilesIds(memberFiles) {
  const ids = [];
  for (const memberFile of memberFiles) {
    if (memberFile.FileSegments) {
      memberFile.FileSegments.map((x) => ids.push(x.PhysicalFileId));
    }
  }
  return ids;
};
