/* eslint-disable no-unused-vars */
import SettingsService from "@/services/settings.service.js";
import ApiService from "@/services/api.service";

const getters = {
  options: (state) => {
    return state.options;
  },
  // cmsEnums: (state) => {
  //     return state.cmsEnums
  // }
};

const actions = {
  async downloadFile({ commit }, options) {
    const settings = SettingsService.settings;
    const downloadStorage = options.storage ? options.storage : "Main";
    if (!options.ids) {
      return;
    }
    if (options.ids.length > 1) {
      const url =
        settings.APIURL + `dms/Api/storage/${downloadStorage}/downloadZip`;
      return ApiService.post(url, options.ids);
    } else {
      const url =
        settings.APIURL +
        `dms/Api/storage/${downloadStorage}/download{${options.ids[0]}}`;
      return ApiService.get(url);
    }
  },
  async downloadFileGuest({ commit }, options) {
    const settings = SettingsService.settings;
    const downloadStorage = options.storage ? options.storage : "Main";
    if (!options.ids) {
      return;
    }
    if (options.ids.length > 1) {
      const url =
        settings.APIURL + `dms/Api/storage/${downloadStorage}/downloadZip`;
      return ApiService.postGuest(url, options.ids);
    } else {
      const url =
        settings.APIURL +
        `dms/Api/storage/${downloadStorage}/download{${options.ids[0]}}`;
      console.log("ela edw eisai: ", url);
      return ApiService.getGuest(url);
    }
  },
  async deleteFiles({ commit }, ids) {
    console.log(ids);
    const settings = SettingsService.settings;
    const url = settings.APIURL + `dms/Api/MoveCopyFiles/DeleteFiles`;
    return ApiService.post(url, ids);
  },
  async uploadFile({ commit }, options) {
    const settings = SettingsService.settings;
    const url =
      settings.APIURL + `plans/${options.planIdentityId}/upload-files`;
    return ApiService.post(url, options.payload);

    // upload via deployed portal-proxy
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/upload-files`;
    // return ApiService.postDeployedProxy(url, options.payload);
    // for local direct upload to dms
    // const url = settings.DOCUMENTSERVICEURL + "api/UploadFiles";
    // return ApiService.postDms(url, options.payload);
  },
  async updateFile({ commit }, options) {
    const settings = SettingsService.settings;
    const downloadStorage = options.storage ? options.storage : "Main";
    const url =
      settings.APIURL +
      `dms/Api/storage/${downloadStorage}/{${options.item.Id}}`;
    return ApiService.put(url, options.item);
  },
  async shareFiles({ commit }, payload) {
    const settings = SettingsService.settings;
    const url = settings.APIURL + `dms/Api/ShareFiles/SendEmail`;
    return ApiService.post(url, payload);
    //todo share
  },
  async getFile({ commit }, fileId) {
    const settings = SettingsService.settings;
    // const url = settings.APIURL + "dms/Api/UploadFiles/" + fileId;
    const url = settings.DOCUMENTSERVICEURL + "Api/UploadFiles/" + fileId;
    return await ApiService.getCustom(url);
  },
  async getPortalFiles({ commit }, planUniqueIdentifier) {
    const settings = SettingsService.settings;
    const url = settings.APIURL + "dms/Api/MemberFiles/" + planUniqueIdentifier;
    return await ApiService.get(url);
  },
  async makeFilesPermanent({ commit }, options) {
    const settings = SettingsService.settings;
    const url =
      settings.APIURL + `dms/Api/ChangeTemporaryFiles/MakeFilesPermanent`;
    return ApiService.post(url, options.payload);
  },
};
export const documentsService = {
  namespaced: true,
  getters,
  actions,
};
