import axios from "axios";

const SettingsService = {
  settings: null,
  async init() {
    var config = (await axios.get("/config.json")).data;
    console.log(Object.keys(config));
    // for (const key of Object.keys(config)) {
    //   console.log(key, config[key]);
    // }
    this.settings = config;
    // console.log("settings service init: ", JSON.parse(config));
  },
};

export default SettingsService;
