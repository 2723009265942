<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-2 pa-sm-4 pa-lg-8 my-0 my-sm-5 pt-sm-4 rounded-lg"
        :flat="role === 'Trustee'"
      >
        <v-card-title
          v-if="contactUsNote"
          :class="`
                      text-subtitle-2
                      font-weight-medium pt-0`"
          v-html="
            $vuetify.lang.current === 'fr' ? contactUsNote.Fr : contactUsNote.En
          "
        ></v-card-title>
        <v-card-title
          v-else
          :class="`
                      text-subtitle-2 text-sm-subtitle-1
                      font-weight-medium pt-0`"
          >{{ $t("components.contact.fill") }}</v-card-title
        >
        <v-card-text class="py-0">
          <v-autocomplete
            :menu-props="{ contentClass: 'text-left' }"
            name="subject"
            outlined
            :label="$t('components.contact.request_type')"
            v-model="message.requestType"
            :items="contactRequestType"
            :item-text="$vuetify.lang.current === 'fr' ? 'Fr' : 'En'"
            :item-value="$vuetify.lang.current === 'fr' ? 'Fr' : 'En'"
            class="required"
            clearable
            :rules="[$rules.required]"
          ></v-autocomplete>
          <v-autocomplete
            :menu-props="{ contentClass: 'text-left' }"
            name="subject"
            outlined
            :label="$t('components.contact.subject')"
            v-model="message.subject"
            :items="contactSubjectType"
            :item-text="$vuetify.lang.current === 'fr' ? 'Fr' : 'En'"
            :item-value="$vuetify.lang.current === 'fr' ? 'Fr' : 'En'"
            class="required"
            clearable
            :rules="[$rules.required]"
          ></v-autocomplete>
          <v-textarea
            rows="8"
            outlined
            :label="$t('components.contact.message')"
            clearable
            v-model="message.text"
            :rules="[$rules.required]"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end pt-0 pr-4">
          <v-col class="pa-0">
            <v-checkbox
              v-model="sendCopy"
              :label="$t('components.contact.send_me_a_copy')"
            ></v-checkbox
          ></v-col>
          <v-btn
            @click="sendMessageToAdmin"
            class="primary"
            :rules="[$rules.required]"
            :disabled="!valid"
            :loading="sending"
            >{{ $t("globals.submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      {{ $t("components.contact.success") }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <error-handler :error="error"></error-handler>
  </div>
</template>

<script>
import ErrorHandler from "@/components/ErrorHandler.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    ErrorHandler,
  },
  props: {
    faqData: {
      type: Array,
    },
  },
  data() {
    return {
      valid: false,
      message: {},
      subject: null,
      error: null,
      sending: false,
      snackbar: false,
      timeout: -1,
      sendCopy: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user", "role"]),
    ...mapGetters("common", [
      "userProfile",
      "contactInfo",
      "leftDrawerMenuItems",
      "planAdministrator",
      "planAdministratorName",
      "portalPlanNumber",
      "adminEmailRecipients",
      "certificateLabel",
      "contactUsNote",
      "contactRequestType",
      "contactSubjectType",
      "isPensionsPortal",
    ]),
    pageIcon() {
      const icon = this.leftDrawerMenuItems.find(
        (i) => i.Url === this.$route.path
      );
      return icon;
    },
  },
  methods: {
    ...mapActions("users", ["sendMessage"]),
    async sendMessageToAdmin() {
      this.sending = true;
      const adminRecipients =
        this.$vuetify.lang.current === "fr"
          ? this.adminEmailRecipients.Fr
          : this.adminEmailRecipients.En;
      const recipients = this.sendCopy
        ? [...adminRecipients, this.userProfile.EmailAddress]
        : adminRecipients;
      console.log("recipients", recipients);
      const trackingId =
        this.userProfile.Certificate + "-" + new Date().getTime();
      try {
        await this.sendMessage({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: {
            AccountStatus: this.userProfile.AccountStatus,
            From: this.userProfile.FirstName + " " + this.userProfile.LastName,
            PlanNumber: this.portalPlanNumber,
            PlanName: this.planAdministratorName,
            PlanNameShort: this.planAdministrator,
            Certificate: this.userProfile.Certificate,
            CertificateLabel: this.certificateLabel,
            DateOfBirth: this.userProfile.Dob?.split("T")[0],
            Email: this.userProfile.EmailAddress,
            Phone:
              this.userProfile.PreferredPhone === "Mobile"
                ? this.userProfile.MobilePhone
                : this.userProfile.HomePhone,
            ProvinceOfEmployment: this.userProfile.ProvinceOfEmployment,
            IsPensionsPortal: this.isPensionsPortal,
            Subject: this.message.subject,
            TypeOfRequest: this.message.requestType,
            TrackingId: trackingId,
            Message: this.message.text,
            Recipients: recipients,
          },
        });
        this.message = {};
        this.sendCopy = false;
        this.snackbar = true;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
        if (error.customMessage === null || error.customMessage === undefined) {
          this.error = {
            customMessage: this.$t("components.contact.error"),
          };
        } else {
          this.error = error;
        }
      } finally {
        this.sending = false;
      }
    },
  },
};
</script>
<style scoped>
.page-container {
  max-width: 1600px;
}
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
