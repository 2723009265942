<template>
  <v-col cols="12" md="6" class="py-0">
    <v-tabs v-model="tab" background-color="transparent" grow>
      <v-tab> {{ $t("components.beneficiary_sba.pre-typed") }} </v-tab>
      <v-tab> {{ $t("components.beneficiary_sba.draw_it") }} </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          flat
          outlined
          max-width="450"
          height="150px"
          class="d-flex align-center justify-center"
        >
          <v-card-text id="text" ref="signatureTyped">
            <img id="image" :src="signatureTyped" height="40" width="300" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <div>
          <v-card flat outlined max-width="500">
            <VueSignaturePad
              width="450px"
              height="150px"
              ref="signaturePad"
              :options="{ onBegin, onEnd }"
            />
            <v-btn
              icon
              color="black--lighten-2"
              class="erase-btn"
              @click="clearSignature"
            >
              <v-icon>mdi-eraser</v-icon>
            </v-btn>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <div>{{ $t("globals.signature") }}</div>
    <canvas
      id="textCanvas"
      width="300"
      height="40"
      style="border: 1px solid grey"
    ></canvas>
    <img contain id="image1" height="40" width="300" />
  </v-col>
  <!-- Canvas to capture pre-typed signature -->
</template>
<script>
export default {
  props: {
    member: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tab: 0,
      signatureTyped: "",
      signature: null,
    };
  },
  watch: {
    signature(value) {
      this.$emit("update-signature", value);
    },
    tab(value) {
      if (value === 0) {
        this.createPreTypedSignature();
      }
      if (value === 1) {
        this.$emit("update-signature", this.signature);
      }
    },
    pretypedSignature() {
      this.createPreTypedSignature();
    },
  },
  computed: {
    pretypedSignature() {
      return this.member?.FirstName + " " + this.member?.LastName;
    },
  },
  mounted() {
    this.createPreTypedSignature();
  },
  methods: {
    clearSignature() {
      this.$refs.signaturePad?.clearSignature();
      this.signature = null;
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        this.signature = data;
      }
    },
    createPreTypedSignature() {
      let tCtx = document.getElementById("textCanvas").getContext("2d"); //Hidden canvas
      tCtx.canvas.width = tCtx.measureText(this.pretypedSignature).width + 20;
      tCtx.font = "40px Brush Script MT";
      tCtx.fillText(this.pretypedSignature, 10, 30);
      let imageElem = document.getElementById("image");
      let imageElem1 = document.getElementById("image1");
      if (imageElem) {
        URL.revokeObjectURL(imageElem.src);
        imageElem.src = tCtx.canvas.toDataURL();
      }
      imageElem1.src = tCtx.canvas.toDataURL();
      this.signatureTyped = tCtx.canvas.toDataURL();
      this.$emit("update-signature", this.signatureTyped);
    },
  },
};
</script>
<style scoped>
#signature {
  border: double 1px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.erase-btn {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  color: white;
}
canvas {
  border: 1px black solid;
}
#textCanvas {
  display: none;
}
#image1 {
  display: none;
}
</style>
