<template>
  <v-container class="py-4">
    <v-form ref="form" v-model="valid">
      <v-row v-if="internalMember">
        <v-col cols="12" md="6" class="py-0">
          <no-autocomplete-textfield
            disabled
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.email_readonly'
              )
            "
            v-model="internalMember.EmailAddress"
            :rules="[$rules.required, $rules.email]"
            class="required highlight"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.secondary_email'
              )
            "
            v-model="internalMember.SecondaryEmail"
            :rules="[$rules.email]"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.mobile_phone'
              )
            "
            v-model="internalMember.MobilePhone"
            v-mask="telephoneMask"
            :rules="[rules.phone]"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <no-autocomplete-textfield
            clearable
            outlined
            :label="
              $t('components.registration_completion.contacts_form.home_phone')
            "
            v-model="internalMember.HomePhone"
            v-mask="telephoneMask"
            :rules="[rules.phone]"
          ></no-autocomplete-textfield>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-select
            outlined
            :label="
              $t(
                'components.contact_information_expansion_panel.preferred_phone'
              )
            "
            v-model="internalPreferredPhone"
            :items="preferredPhone"
            :rules="
              internalMember.HomePhone || internalMember.Mobile
                ? [$rules.required]
                : []
            "
            :class="
              internalMember.HomePhone || internalMember.Mobile
                ? 'required'
                : ''
            "
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-select
            outlined
            :label="
              $t('components.registration_completion.contacts_form.language')
            "
            v-model="internalMember.Language"
            :items="languages"
            :rules="[$rules.required]"
            class="required"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6" class="py-0">
          <v-select
            outlined
            :label="
              $t(
                'components.registration_completion.contacts_form.communication'
              )
            "
            v-model="internalMember.Communication"
            :rules="[$rules.required]"
            :items="enumModels.CommunicationPreference"
            class="required"
            :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters } from "vuex";
import { tokens, mask } from "vue-the-mask";
import masker from "vue-the-mask/src/masker";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    member: Object,
    saving: Boolean,
  },
  directives: { mask },
  data() {
    return {
      dateMenu: false,
      internalMember: undefined,
      internalPreferredPhone: null,
      valid: false,
      languages: [
        { text: "English", value: "English" },
        { text: "Français", value: "French" },
      ],
      telephoneMask: "(###) ### - ####",
      rules: {
        phone: (v) => {
          let val = this.unmask(v, this.telephoneMask);
          return !val || this.$rules.phone(val);
        },
      },
    };
  },
  computed: {
    ...mapGetters("common", ["enumModels", "identityUser"]),
    ...mapGetters("auth", ["user"]),
    preferredPhone() {
      const en = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Home",
          value: "Home",
        },
      ];
      const fr = [
        {
          text: "Mobile",
          value: "Mobile",
        },
        {
          text: "Téléphone",
          value: "Home",
        },
      ];
      return this.$vuetify.lang.current === "fr" ? fr : en;
    },
    preferredPhoneAutoAssigned() {
      let preferred = null;
      if (this.internalMember?.MobilePhone && !this.internalMember?.HomePhone)
        preferred = "Mobile";
      if (!this.internalMember?.MobilePhone && this.internalMember?.HomePhone)
        preferred = "Home";
      if (this.internalMember?.PreferredPhone) {
        if (
          this.internalMember?.MobilePhone ===
          this.internalMember?.PreferredPhone
        )
          preferred = "Mobile";
        if (
          this.internalMember?.HomePhone === this.internalMember?.PreferredPhone
        )
          preferred = "Home";
      }

      return preferred;
    },
  },
  watch: {
    member: {
      async handler(v) {
        if (v && this.internalMember === undefined) {
          this.loadMember(v);
        }
      },
      deep: true,
    },
    internalMember: {
      handler(v) {
        this.$emit("update-member", v);
      },
      deep: true,
    },
    internalPreferredPhone: {
      handler(v) {
        this.internalMember.PreferredPhone = v;
      },
    },
    valid: {
      handler(v) {
        this.$emit("update-valid", v);
      },
    },
    preferredPhoneAutoAssigned: {
      handler() {
        this.internalPreferredPhone = this.preferredPhoneAutoAssigned;
      },
    },
  },
  mounted() {
    if (this.member) {
      this.loadMember(this.member);
    }
    this.$refs.form.validate();
  },
  methods: {
    loadMember(v) {
      v.EmailAddress = this.identityUser.Email;
      v.Communication = "Email";
      this.internalMember = v;
      if (!this.internalMember.PreferredPhone) {
        this.internalPreferredPhone = this.preferredPhoneAutoAssigned;
      }
    },
    unmask(v, mask) {
      if (v) {
        return masker(v, mask, false, tokens);
      }
      return v;
    },
  },
};
</script>
<style scoped>
.camera-btn {
  right: 25px;
  top: 25px;
  position: relative;
}
.avatar {
  border: 3px white solid;
}
.v-input--is-disabled >>> input {
  color: rgba(0, 0, 0, 0.87) !important;
}
.v-input--is-disabled >>> label {
  color: rgba(0, 0, 0, 0.87) !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
.v-tooltip__content {
  max-width: 150px !important;
}
</style>
