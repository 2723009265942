<template>
  <doughnut-chart
    :styles="{ position: 'relative', zIndex: '1' }"
    :chartData="chartData"
    :chartOptions="chartOptions"
    :width="$vuetify.breakpoint.mdAndDown ? 95 : 120"
    :height="$vuetify.breakpoint.mdAndDown ? 95 : 120"
  />
</template>

<script>
import DoughnutChart from "@/components/VueMaterialDashboard/material/DoughnutChart.vue";
export default {
  components: {
    DoughnutChart,
  },
  props: {
    coverageBalance: Object,
    default: () => {},
  },
  data() {
    return {
      chartOptions: {
        responsive: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
  computed: {
    allowedValue() {
      if (this.coverageBalance) {
        return this.coverageBalance.Allowed;
      }
      return 0;
    },
    usedValue() {
      if (this.coverageBalance) {
        return this.coverageBalance.Used;
      }
      return 0;
    },
    remainingVlaue() {
      if (this.allowedValue) {
        return this.allowedValue - this.usedValue;
      }
      return 0;
    },
    chartData() {
      return {
        labels: ["Used", "Remaining"],
        datasets: [
          {
            backgroundColor: [
              this.$vuetify.theme.themes.light.accent,
              "#8EE5F3",
            ],
            data: [this.usedValue, this.remainingVlaue],
            cutout: "80%",
            circumference: 270,
            rotation: 225,
          },
        ],
      };
    },
  },
  methods: {
    colorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },
  },
};
</script>
