import printJS from "print-js";
export const printFileFromBase64 = async (
  files, // array of items in Base64 or just one item in array
  extension = "jpg",
  // eslint-disable-next-line prettier/prettier
  filename = "print",
) => {
  const objectURLs = [];
  const pagestyle =
    "@page { size: letter; margin-top: 5mm; margin-bottom: 0mm;} body {margin: 0;} h4 {margin:0}";
  try {
    files.forEach((file) => {
      const blob = b64toBlob(file);

      const fileData = new File([blob], filename, {
        type: blob.type,
      });

      // setTimeout(() => {
      objectURLs.push(URL.createObjectURL(fileData));
    });

    if (extension === "jpg") {
      printJS({
        printable: objectURLs,
        type: "image",
        imageStyle: "max-width: 400px; display: block; margin: 5px auto",
        headerStyle: "font-weight: 300;",
        style: pagestyle,
        targetStyles: ["*"],
      });
    } else if (extension === "pdf") {
      printJS({
        printable: objectURLs,
        type: "pdf",
      });
    } else {
      printJS({
        printable: "print",
        type: "html",
        headerStyle: "font-weight: 300;",
        style: pagestyle,
        targetStyles: ["*"],
      });
    }
    // }, 100);
  } catch (error) {
    console.log(error);
  }

  function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
};
