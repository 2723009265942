<template>
  <material-card-custom
    :size.sync="internalSize"
    :height.sync="internalHeight"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    :widgetId="widgetId"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    color="primary"
    :title="
      $t(
        'components.vue_material_dashboard.items.my_documents_dashboard_item.title'
      )
    "
  >
    <router-link to="download-centre" class="text-decoration-none">
      <v-card-text class="text-left pb-0">
        <v-list class="pt-0">
          <v-list-item-group v-model="selectedItem">
            <v-list-item v-if="loading">
              <v-row justify="center">
                <v-progress-circular
                  size="50"
                  class="mt-10"
                  indeterminate
                ></v-progress-circular>
              </v-row>
            </v-list-item>
            <div v-show="!loading">
              <v-list-item
                class="px-0"
                @click.prevent="$emit('view-pdf', downloadsData[0])"
              >
                <v-list-item-icon class="my-auto">
                  <v-col>
                    <v-img
                      :max-width="$vuetify.breakpoint.mdOnly ? 100 : 120"
                      contain
                      @error="imageLoadError = true"
                      @load="imageLoadError = false"
                      :src="
                        downloadsData[0]
                          ? $vuetify.lang.current === 'en'
                            ? downloadsData[0].PreviewImageUrlEN
                            : downloadsData[0].PreviewImageUrlFR
                          : ''
                      "
                    >
                      <v-skeleton-loader
                        v-show="imageLoadError"
                        class="mx-auto"
                        :width="$vuetify.breakpoint.mdOnly ? 100 : 120"
                        :height="$vuetify.breakpoint.mdOnly ? 130 : 155"
                        type="image"
                      ></v-skeleton-loader>
                      <!-- <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template> -->
                    </v-img>
                  </v-col>
                </v-list-item-icon>
                <v-list-item-content class="py-0">
                  <v-col class="text-subtitle-1 font-weight-bold">
                    {{
                      downloadsData[0]
                        ? $vuetify.lang.current === "en"
                          ? downloadsData[0].TitleEN
                          : downloadsData[0].TitleFR
                        : ""
                    }}
                  </v-col>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <template v-for="i in 3">
                <v-list-item
                  class="px-0"
                  :key="i"
                  @click.prevent="$emit('view-pdf', downloadsData[i + 1])"
                >
                  <v-list-item-icon class="my-auto">
                    <v-icon size="50" color="primary">mdi-file-document</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="py-1">
                    <v-col
                      :class="`
                      text-subtitle-2 text-lg-subtitle-1
                      font-weight-bold
                      py-2`"
                    >
                      {{
                        downloadsData[i + 1]
                          ? $vuetify.lang.current === "en"
                            ? downloadsData[i + 1].TitleEN
                            : downloadsData[i + 1].TitleFR
                          : ""
                      }}
                    </v-col>
                  </v-list-item-content>
                  <!-- <v-list-item-icon class="pt-2">
                    <v-icon @click.stop="printFile" small>mdi-printer</v-icon>
                  </v-list-item-icon>
                  <v-list-item-icon class="pt-2 ml-0">
                    <v-icon class="" @click.stop="downloadFile" small
                      >mdi-download</v-icon
                    >
                  </v-list-item-icon> -->
                </v-list-item>
                <v-divider :key="i + '-divider'"></v-divider>
              </template>
            </div>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </router-link>
    <v-row no-gutters class="bottom-btn">
      <v-btn
        class="secondary lighten-2"
        width="150px"
        to="download-centre-new"
        >{{
          $t(
            "components.vue_material_dashboard.items.my_documents_dashboard_item.more"
          )
        }}</v-btn
      >
    </v-row>
  </material-card-custom>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardCustom from "@/components/VueMaterialDashboard/material/MaterialCardCustomDashboard.vue";
export default {
  components: {
    MaterialCardCustom,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      selectedItem: undefined,
      downloadsData: [],
      loading: false,
      imageLoadError: true,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("common", ["getDownloadCentreData"]),
    async loadData() {
      try {
        this.loading = true;
        this.downloadsData = (
          await this.getDownloadCentreData({
            planIdentityId: this.user.plan,
            memberIdentityId: this.user.sub,
          })
        ).data;
        this.loading = false;
      } catch (error) {
        this.error = error;
        console.log(error);
        this.loading = false;
      }
    },
    printFile() {
      console.log("Print file");
    },
    downloadFile() {
      console.log("Download file");
    },
    previewDocument(doc) {
      console.log("preview document", doc);
    },
    errorLoadingImage() {
      console.log("image loading error");
    },
  },
};
</script>
<style scoped>
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>
