<template>
  <v-container fluid class="px-0 pt-1 page-container">
    <v-row
      @drop="onDrop"
      @dragover.prevent
      @dragenter.prevent
      no-gutters
      v-if="!loadingUserProfile && isUserAllowedToLogin"
    >
      <v-col
        class="pa-0 pb-1 pa-sm-1"
        v-for="item in itemsToShow"
        :key="item.Id"
        :order="item.Order"
        cols="12"
        :md="item.Name === 'BenefitCardsDashboardItemSba' ? 12 : 6"
        :sm="item.Name === 'BenefitCardsDashboardItemSba' ? 12 : 6"
        :lg="item.Size"
        @dragstart="startDrag($event, item)"
      >
        <!-- add draggable to above to enable drag and drop -->
        <component
          v-if="checkRole(item)"
          :is="item.Name"
          :size.sync="item.Size"
          :height="role === 'Trustee' ? 3 : item.Height"
          :widgetIcon="item.WidgetIcon"
          :order="item.Order"
          :maxOrderValue="items.length - 1"
          @update-order="updateOrder($event, item)"
          @add-widget="$emit('add-widget', $event)"
          @remove-widget="$emit('remove-widget', $event)"
          @send-message="$emit('send-message', $event)"
          @view-pdf="openPdf($event)"
          :widgetId="item.Id"
          :id="`dashboard_item-${item.Order}`"
          :sending="sending"
        />
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        class="mt-10"
        color="primary"
      ></v-progress-circular>
    </v-row>

    <!-- Global Search -->
    <!-- <v-container class="px-0 px-sm-4">
      <v-row no-gutters justify="center">
        <v-menu
          max-height="60vh"
          :value.sync="isSearchFound"
          top
          offset-y
          transition="expand-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              clearable
              v-model="search"
              class="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('views.page.home.search')"
              single-line
              hide-details
              outlined
              @keydown="on.click"
              @input="triggerSearch = !triggerSearch"
            ></v-text-field>
          </template>

          <global-search-results
            :search="search"
            :triggerSearch="triggerSearch"
            @search-found="isSearchFound = $event"
            @view-pdf="openPdf($event)"
            @clear-search="search = null"
          />
        </v-menu>
      </v-row>
    </v-container> -->

    <v-dialog v-model="showPdf">
      <transition name="transition-anim">
        <section class="pdf-preview" v-if="pdfSource">
          <button @click.self="closePdfViewer()">&times;</button>
          <iframe :src="pdfSource" width="100%" height="100%" />
        </section>
      </transition>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BenefitBalanceDashboardItem from "./dashboardItems/BenefitBalanceDashboardItem.vue";
import DependantsDashboardItem from "./dashboardItems/DependantsDashboardItem.vue";
import DownloadsDashboardItem from "./dashboardItems/DownloadsDashboardItem.vue";
import MyDocumentsDashboardItem from "./dashboardItems/MyDocumentsDashboardItem.vue";
import RecentActivityDashboardItem from "./dashboardItems/RecentActivityDashboardItem.vue";
import WelcomeDashboardItem from "./dashboardItems/WelcomeDashboardItem.vue";
import NewsDashboardItem from "./dashboardItems/NewsDashboardItem.vue";
import PensionSummaryDashboardItem from "./dashboardItems/PensionSummaryDashboardItem.vue";
import MyPensionDashboardItem from "./dashboardItems/MyPensionDashboardItem.vue";
import CcwippLoadingDashboardItem from "./dashboardItems/CcwippLoadingDashboardItem.vue";
import MyLifeEventsDashboardItem from "./dashboardItems/MyLifeEventsDashboardItem.vue";
import MyBalanceDashboardItem from "./dashboardItems/MyBalanceDashboardItem.vue";
import AddWidgetDashboardItem from "./dashboardItems/AddWidgetDashboardItem.vue";
import AssistantDashboardItem from "./dashboardItems/AssistantDashboardItem.vue";
import BeneficiariesDashboardItem from "./dashboardItems/BeneficiariesDashboardItem.vue";
import MemberInformationDashboardItem from "./dashboardItems/MemberInformationDashboardItem.vue";
import MyInfoDashboardItem from "./dashboardItems/MyInfoDashboardItem.vue";
import MyInfoDashboardItemSba from "./dashboardItems/MyInfoDashboardItemSba.vue";
import MyClaimDashboardItemSba from "./dashboardItems/MyClaimDashboardItemSba.vue";
import DrugProviderSearchDashboardItemSba from "./dashboardItems/DrugProviderSearchDashboardItemSba.vue";
import BenefitCardsDashboardItemSba from "./dashboardItems/BenefitCardsDashboardItemSba.vue";
import UsedBenefitsDashboardItemSba from "./dashboardItems/UsedBenefitsDashboardItemSba.vue";
import UsedBenefitsDashboardItemSbaLegacy from "./dashboardItems/UsedBenefitsDashboardItemSbaLegacy.vue";
import DocumentsDashboardItemSba from "./dashboardItems/DocumentsDashboardItemSba.vue";
import HSADashboardItemSba from "./dashboardItems/HSADashboardItemSba.vue";
import ContactDashboardItem from "./dashboardItems/ContactDashboardItem.vue";
import EmptyDashboardItem from "./dashboardItems/EmptyDashboardItem.vue";
import GlobalSearchResults from "@/components/VueMaterialDashboard/GlobalSearchResults.vue";
import PdfView from "@/components/DownloadCentre/PdfView.vue";

export default {
  components: {
    BenefitBalanceDashboardItem,
    DependantsDashboardItem,
    DownloadsDashboardItem,
    MyDocumentsDashboardItem,
    RecentActivityDashboardItem,
    WelcomeDashboardItem,
    NewsDashboardItem,
    PensionSummaryDashboardItem,
    MyPensionDashboardItem,
    CcwippLoadingDashboardItem,
    MyLifeEventsDashboardItem,
    MyBalanceDashboardItem,
    AddWidgetDashboardItem,
    AssistantDashboardItem,
    BeneficiariesDashboardItem,
    MemberInformationDashboardItem,
    MyInfoDashboardItem,
    MyInfoDashboardItemSba,
    MyClaimDashboardItemSba,
    BenefitCardsDashboardItemSba,
    DrugProviderSearchDashboardItemSba,
    DocumentsDashboardItemSba,
    HSADashboardItemSba,
    UsedBenefitsDashboardItemSba,
    UsedBenefitsDashboardItemSbaLegacy,
    ContactDashboardItem,
    EmptyDashboardItem,
    GlobalSearchResults,
    PdfView,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    sending: Boolean,
  },
  data() {
    return {
      search: null,
      isSearchFound: false,
      triggerSearch: false,
      pdfSource: null,
      pdfTitle: null,
      showPdf: false,
      isUserAllowedToLogin: false,
      allowedAccountStatuses: [
        "active",
        "deferred",
        "terminated",
        "paid",
        "retired",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["role", "user"]),
    ...mapGetters("data", ["memberHasHSA"]),
    ...mapGetters("common", [
      "isPensionsPortal",
      "isDemoMockPlan",
      "useLegacyClaims",
      "userProfile",
      "hideFrequentlyUsed",
    ]),
    numberOfWidgetsInARow() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 3
        : this.$vuetify.breakpoint.xsOnly
        ? 1
        : 2;
    },
    loadingUserProfile() {
      return this.userProfile === null || this.userProfile === undefined;
    },
    itemsToShow() {
      let toShow = this.items.filter((i) => {
        if (i.ShowTo) {
          if (this.isPensionsPortal) {
            if (this.loadingUserProfile) {
              return i.ShowTo.includes("Loading");
            }
            if (
              this.userProfile?.AccountStatus !== "Active" &&
              this.userProfile?.AccountStatus !== "Deferred" &&
              this.userProfile?.AccountStatus !== "Terminated" &&
              i.Name === "MyLifeEventsDashboardItem"
            ) {
              return true;
            }

            return (
              i.ShowTo.includes(this.userProfile?.AccountStatus) ||
              i.ShowTo.includes("All")
            );
          }
          return i.ShowTo.includes(this.role);
        } else return true;
      });

      const usedBenefitsWidgetIndex = toShow.findIndex(
        (w) => w.Name === "UsedBenefitsDashboardItemSba"
      );

      if (this.useLegacyClaims && usedBenefitsWidgetIndex !== -1) {
        toShow[usedBenefitsWidgetIndex].Name =
          "UsedBenefitsDashboardItemSbaLegacy";
      }

      if (usedBenefitsWidgetIndex !== -1 && this.hideFrequentlyUsed) {
        toShow = toShow.filter(
          (i) =>
            i.Name !== "UsedBenefitsDashboardItemSba" &&
            i.Name !== "UsedBenefitsDashboardItemSbaLegacy"
        );
      }

      console.log("hideFrequentlyUsed: ", this.hideFrequentlyUsed);

      const documentsWidgetIndex = toShow.findIndex(
        (w) => w.Name === "DocumentsDashboardItemSba"
      );

      const hsaWidgetIndex = toShow.findIndex(
        (w) => w.Name === "HSADashboardItemSba"
      );

      if (this.memberHasHSA) {
        if (hsaWidgetIndex !== -1 && documentsWidgetIndex !== -1) {
          if (this.hideFrequentlyUsed) {
            toShow[hsaWidgetIndex].Size = 4;
            toShow[documentsWidgetIndex].Size = 5;
          } else {
            toShow[usedBenefitsWidgetIndex].Size = 4;
          }
        }
      } else {
        toShow = toShow.filter((i) => i.Name !== "HSADashboardItemSba");
        if (this.hideFrequentlyUsed) {
          const documentsWidgetIndex = toShow.findIndex(
            (w) => w.Name === "DocumentsDashboardItemSba"
          );
          toShow[documentsWidgetIndex].Size = 9;
        }
      }
      return toShow;
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v) {
          // Check if member is Neospin member and the member status is not in the allowed list
          if (this.isPensionsPortal && this.user?.neospinMemberId) {
            if (
              !this.allowedAccountStatuses.includes(
                this.userProfile?.AccountStatus?.toLowerCase()
              )
            ) {
              console.log(
                "user is not allowed to access this portal",
                this.user?.neospinMemberId,
                "AccountStatus: ",
                this.userProfile?.AccountStatus
              );
              this.isUserAllowedToLogin = false;
              this.$store.dispatch("auth/logout");
            } else {
              this.isUserAllowedToLogin = true;
            }
          } else {
            this.isUserAllowedToLogin = true;
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (!this.isPensionsPortal) {
      var result = await this.getHSA({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
      });
      await this.getHasBeneficiariesFlag();
      if (this.isDemoMockPlan) {
        this.setMockHSA(result?.data?.value);
      }
    }
  },
  methods: {
    ...mapActions("data", ["getHSA", "setMockHSA"]),
    ...mapActions("common", ["getHasBeneficiariesFlag"]),
    openPdf(item) {
      this.pdfSource =
        item["FileUrl" + this.$vuetify.lang.current.toUpperCase()];
      this.pdfTitle = item["Title" + this.$vuetify.lang.current.toUpperCase()];
      this.showPdf = true;
      this.search = null;
    },
    closePdfViewer() {
      this.showPdf = false;
      this.pdfSource = null;
    },
    updateOrder(itemInPositionOrder, item) {
      const newOrder = parseInt(itemInPositionOrder);

      if (isNaN(newOrder)) {
        return;
      }
      if (newOrder === item.Order) {
        return;
      }

      if (
        item.Height === 2 &&
        itemInPositionOrder >= this.items.length - this.numberOfWidgetsInARow
      ) {
        return;
      }

      const itemInPosition = this.items.find(
        (i) => i.Order === itemInPositionOrder
      );
      if (itemInPosition.Name === "EmptyDashboardItem") return;

      if (
        item.Order >= this.items.length - this.numberOfWidgetsInARow &&
        itemInPosition.Height === 2
      )
        return;
      itemInPosition.Order = item.Order;

      item.Order = newOrder;
      if (item.Height === 2) {
        const emptyWidget = this.items.find(
          (i) => i && i.Name === "EmptyDashboardItem"
        );
        const itemInPosition = newOrder + this.numberOfWidgetsInARow - 1;
        this.updateOrder(itemInPosition, emptyWidget);
      }

      if (itemInPosition.Height === 2) {
        const emptyWidget = this.items.find(
          (i) => i && i.Name === "EmptyDashboardItem"
        );
        const itemUnderTallWidget =
          itemInPosition.Order + this.numberOfWidgetsInARow - 1;
        this.updateOrder(itemUnderTallWidget, emptyWidget);
      }
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("id", item.Id);
      evt.dataTransfer.setData("x", evt.x);
      evt.dataTransfer.setData("y", evt.y);
      console.log("drag: ", evt, item);
    },

    onDrop(evt) {
      const id = evt.dataTransfer.getData("id");
      const item = this.items.find((item) => item.Id == id);
      const itemInPosition = this.getItemInPosition(evt.x, evt.y);
      console.log("dropped: ", evt, id, item, itemInPosition);

      if (
        item.Name === "AddWidgetDashboardItem" ||
        item.Name === "EmptyDashboardItem"
      )
        return;

      if (itemInPosition !== undefined) {
        const itemInPositionOrder = parseInt(itemInPosition.id.split("-")[1]);
        this.updateOrder(itemInPositionOrder, item);
      }
    },
    getItemInPosition(x, y) {
      let els = document.elementsFromPoint(x, y);
      console.log(els);
      let materialCardElements = els.filter((o) =>
        o.classList.contains("material-card")
      );
      if (materialCardElements.length === 1) {
        return materialCardElements[0];
      }
      return undefined;
    },
    checkRole(item) {
      if (item.ShowTo) {
        if (this.isPensionsPortal) {
          if (this.loadingUserProfile) {
            return item.ShowTo.includes("Loading");
          }
          if (item.ShowTo.includes(this.userProfile?.AccountStatus))
            return true;
          if (item.ShowTo.includes("All")) return true;
        }
        if (
          this.userProfile?.AccountStatus !== "Active" &&
          this.userProfile?.AccountStatus !== "Defferred" &&
          this.userProfile?.AccountStatus !== "Terminated" &&
          !this.loadingUserProfile &&
          item.Name === "MyLifeEventsDashboardItem"
        ) {
          return true;
        }
        return item.ShowTo.includes(this.role);
      } else return true;
    },
  },
};
</script>
<style scoped>
.search {
  max-width: 1200px;
  margin: 20px auto auto;
}
.search >>> .v-input__slot {
  background-color: white !important;
  border-radius: 10px;
}
.search >>> fieldset {
  border: 2px solid rgb(189, 189, 189);
  border-radius: 10px;
}

@media screen and (max-width: 375px) {
  .search >>> .v-label {
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.pdf-preview {
  position: fixed;
  width: 65%;
  min-width: 600px;
  height: 80vh;
  top: 100px;
  z-index: 9999999;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000048;

  button {
    position: absolute;
    top: -20px;
    right: -15px;
    width: 35px;
    height: 35px;
    background: #555;
    border: 0;
    box-shadow: 0px 0px 10px #00000048;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
  }

  iframe {
    border: 0;
  }
}

.transition-anim-enter-active,
.transition-anim-leave-active {
  transition: opacity 0.3s ease-in;
}

.transition-anim-enter,
.transition-anim-leave-to {
  opacity: 0;
}
</style>
