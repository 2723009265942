<template>
  <material-card
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :maxOrderValue="maxOrderValue"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :widgetId="widgetId"
    :title="
      $t('components.vue_material_dashboard.items.welcome_dashboard_item.title')
    "
  >
    <v-card-text class="align-center">
      <!-- <div class="video-wrapper"> -->
      <!-- <iframe
          :src="require('@/assets/CCWIPP_EN_2-4_1.mp4?autoplay=0&rel=0')"
          frameborder="0"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
          :autoplay="false"
          allowfullscreen
        ></iframe> -->
      <video
        width="100%"
        height="170"
        controls
        :poster="require('@/assets/' + welcomeVideo.videoPoster)"
        ref="videoPoster"
      >
        <source
          :src="require('@/assets/' + welcomeVideo.videoUrl)"
          type="video/mp4"
          ref="videoSrc"
        />
        Your browser does not support the video tag.
      </video>
      <!-- </div> -->
    </v-card-text>
  </material-card>
</template>

<script>
import { mapGetters } from "vuex";
import MaterialCard from "@/components/VueMaterialDashboard/material/MaterialCardDashboard.vue";
export default {
  components: {
    MaterialCard,
  },
  props: {
    widgetId: String,
    size: Number,
    order: Number,
    maxOrderValue: Number,
  },
  computed: {
    ...mapGetters("common", ["welcomeVideo"]),
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
    };
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
};
</script>
<style scoped>
/* .video_wrapper {
  position: relative;
  padding-bottom: 56.25%;
} */
iframe {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
