<template>
  <material-card-sba-small
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :widgetIcon="widgetIcon"
    color="accent"
    :title="$t('components.vue_material_dashboard.items.my_recent_claim.title')"
  >
    <v-card height="144" flat to="claims-history">
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="recentClaim.ClaimNo"
      >
        <v-col cols="6" class="pl-2 pl-md-4">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pa-0 text-left">
              <v-col
                :class="`
                pa-0
                text-subtitle-1
                font-weight-bold
                accent--text
                lh-1`"
                >{{ recentClaim.ClaimNo }}</v-col
              >
              <v-col class="pa-0 text-subtitle-2 font-weight-bold lh-1">{{
                recentClaim.Date
              }}</v-col>
              <v-col
                v-if="recentClaim.PaidTo"
                :class="`
                pa-0
                mb-2
                text-subtitle-2
                font-weight-bold
                text-capitalize
                lh-1`"
                ><span class="text-caption text-uppercase font-weight-medium"
                  >{{
                    $t(
                      "components.vue_material_dashboard.items.my_recent_claim.paid_to"
                    )
                  }}:
                </span>
                <span v-if="useLegacyClaims" class="ml-1">{{
                  formatPaidTo(recentClaim.PaidTo)
                }}</span>
                <span v-else class="ml-1">{{ recentClaim.PaidTo }}</span>
              </v-col>
              <v-col
                v-if="recentClaim.ChequeNo"
                class="pa-0 title1 text-uppercase lh-1"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.my_recent_claim.deposit_cheque"
                  )
                }}</v-col
              >
              <v-col
                v-if="recentClaim.ChequeNo"
                class="pa-0 text-subtitle-1 font-weight-bold lh-1"
                >#{{ recentClaim.ChequeNo }}
              </v-col>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" class="text-left">
          <v-row no-gutters justify="center">
            <v-col cols="auto" class="pa-0 text-left">
              <v-col class="pa-0 title1 text-uppercase">{{
                $t(
                  "components.vue_material_dashboard.items.my_recent_claim.total_submitted"
                )
              }}</v-col>
              <v-col
                class="pa-0 text-h6 font-weight-bold accent--text lh-1 mb-4"
                >{{ $helpers.toCurrency(recentClaim.TotalSubmitted) }}
              </v-col>

              <v-col class="pa-0 title1 text-uppercase">{{
                $t(
                  "components.vue_material_dashboard.items.my_recent_claim.total_paid"
                )
              }}</v-col>
              <v-col class="pa-0 text-h6 font-weight-bold accent--text lh-1"
                >{{ $helpers.toCurrency(recentClaim.TotalPaid) }}
              </v-col>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="!recentClaim.ClaimNo && !loading"
      >
        <v-col class="px-6">
          {{
            $t(
              "components.vue_material_dashboard.items.my_recent_claim.no_claims"
            )
          }}
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="fill-height"
        align-content="center"
        v-if="loading"
      >
        <v-col class="px-6">
          <v-progress-circular
            size="50"
            color="accent"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </material-card-sba-small>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSbaSmall from "@/components/VueMaterialDashboard/material/MaterialCardSmallSbaDashboard.vue";
// import mockSubmittedClaimsHistoryDrawbridge from "@/components/ClaimCentre/mockSubmittedClaimsHistoryDrawbridge.json";
export default {
  components: {
    MaterialCardSbaSmall,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      // mockSubmittedClaimsHistoryDrawbridge:
      //   mockSubmittedClaimsHistoryDrawbridge,
      loading: false,
      recentClaim: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile", "useLegacyClaims"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
    this.loadData();
  },
  methods: {
    ...mapActions("data", ["getClaimsData", "searchDrawbridgeClaims"]),
    async loadData() {
      try {
        this.loading = true;
        let recentClaims;
        if (this.useLegacyClaims) {
          recentClaims = (
            await this.getClaimsData({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.sub,
            })
          ).data;
          if (recentClaims.length) {
            this.recentClaim = recentClaims[0];
          }
        } else {
          const data = (
            await this.searchDrawbridgeClaims({
              planIdentityId: this.user.plan,
              memberIdentityId: this.user.sub,
              // memberIdentityId: "8e442e9b-76d4-4706-9f96-79c334234734",
              page: 1,
              itemsPerPage: 2,
              sortBy: "Timestamp",
              sortDesc: true,
            })
          ).data;
          if (data && data.value?.length) {
            const lastClaim = data.value[0];
            this.recentClaim = {
              ClaimNo: lastClaim.Identifier,
              Date: lastClaim.Timestamp?.split("T")[0],
              PaidTo: lastClaim.Payee,
              ChequeNo: lastClaim.ChequeNumber,
              TotalSubmitted:
                lastClaim.Claims[0]?.CurrentClaimData?.ClaimedAmount,
              TotalPaid: this.calculateTotalPaid(lastClaim),
            };
          }
        }
      } catch (error) {
        this.error = error;
        console.log(error);
        // if (this.useLegacyClaims) {
        //   const mockClaims = [
        //     {
        //       ClaimNo: "10296258",
        //       Date: "3/2/2023",
        //       PaidTo: "D",
        //       ChequeNo: "50070387",
        //       TotalSubmitted: "370.20",
        //       TotalPaid: "263.06",
        //     },
        //   ];
        //   this.recentClaim = mockClaims[0];
        // } else {
        //   const mockClaims = {
        //     "@odata.context":
        //       "http://brokers.claims:5026/odata/$metadata#ClaimSubmissions(Claims(Proofs(FileSegments()),CurrentClaimData(PlanBenefit(Benefit(Name,Translations())))))",
        //     "@odata.count": 7,
        //     value: [
        //       {
        //         Identifier: "CMP-111-24032311452297",
        //         MemberId: "40598ee2-411c-4846-a6d4-01ffbd719fdb",
        //         PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //         PlanNumber: "111",
        //         Comments: null,
        //         Sequence: "24032311452297",
        //         PaymentNumber: null,
        //         Timestamp: "2023-03-24T09:45:25.3355957Z",
        //         ApplyRemainingToHCSA: false,
        //         RefundToDirectDeposit: false,
        //         Payee: "Member",
        //         ChequeNumber: null,
        //         UserType: "Member",
        //         PaymentMethod: "DirectDeposit",
        //         ExternalSubmissionId: "CMP-111-24032311452297",
        //         ExternalResponseProduced: false,
        //         ProviderId: "2289d3b5-54f4-403f-174b-08daf7a4ccec",
        //         OfficeId: "8d068a34-cba3-461e-9766-08daf7a4cd12",
        //         Id: "7d6eea76-10fe-4961-a7b7-08db2c4c7e7a",
        //         ExtractedTeeth: [],
        //         Claims: [
        //           {
        //             Creator: "",
        //             ClaimSubmissionId: "7d6eea76-10fe-4961-a7b7-08db2c4c7e7a",
        //             MemberId: "40598ee2-411c-4846-a6d4-01ffbd719fdb",
        //             DependantId: null,
        //             LastName: "Campbell",
        //             FirstName: "Jessica",
        //             Student: null,
        //             Disabled: null,
        //             School: null,
        //             HSACoveredBenefitId: null,
        //             PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //             Id: "04b3d3ee-7060-4ee8-b781-08db2c4c7e91",
        //             Type: "ClaimWorkflow",
        //             State: "Pended",
        //             Assignee: "",
        //             Proofs: [
        //               {
        //                 Name: null,
        //                 Timestamp: "2023-03-24T11:42:52.6265023Z",
        //                 ReferralDate: null,
        //                 ProofType: "Expense",
        //                 ClaimSubmissionId:
        //                   "7d6eea76-10fe-4961-a7b7-08db2c4c7e7a",
        //                 ClaimLines: [1],
        //                 Id: "1c410862-8c0d-4956-38f0-08db2c5ce6f4",
        //                 FileSegments: [
        //                   {
        //                     Id: "b9f52843-6ee2-4581-9e55-08db2c5ce6f5",
        //                     FileName: "emptypage.pdf",
        //                     Timestamp: "2023-03-24T09:45:18.2994623Z",
        //                     FileType: null,
        //                     FileSize: 24805,
        //                     PhysicalFileId:
        //                       "ab1d9194-28ca-ed11-bdd2-005056959613",
        //                     ProofId: "1c410862-8c0d-4956-38f0-08db2c5ce6f4",
        //                   },
        //                 ],
        //               },
        //             ],
        //             CurrentClaimData: {
        //               Id: "578fb1ee-8e9b-47ff-4d59-08db2c5ce665",
        //               ClaimId: "04b3d3ee-7060-4ee8-b781-08db2c4c7e91",
        //               Status: "Pended",
        //               Data: null,
        //               ClaimedAmount: 10,
        //               PaidAmount: 3.45,
        //               EligibleAmount: null,
        //               CoveredAmount: null,
        //               CoinsuranceAmount: null,
        //               CopaymentAmount: null,
        //               LabFee: null,
        //               PaidLabFee: null,
        //               EligibleLabFee: null,
        //               CoveredLabFee: null,
        //               CoinsuranceLabFee: null,
        //               CopaymentLabFee: null,
        //               Timestamp: "2023-03-24T11:42:51.6877438Z",
        //               ProcedureCode: null,
        //               SurfaceCode: null,
        //               PlanBenefitId: "700b8341-a15b-4ea9-7875-08db2170b49a",
        //               ToothCodeId: null,
        //               DateOfService: "2023-05-05T00:00:00Z",
        //               ReferralDate: null,
        //               ExpenseAmount: 10,
        //               HasCOB: false,
        //               COBAmount: 0,
        //               DIN: null,
        //               Line: 1,
        //               EOB: [],
        //               AdjudicationError: null,
        //               PlanBenefit: {
        //                 PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //                 BenefitId: "5f5dc531-f381-4606-66ca-08db09dbdb6d",
        //                 RequiresReferral: false,
        //                 RequiresLetter: false,
        //                 AuditRuleId: "ada8fe27-0062-4e9d-10a7-08db2170b4a7",
        //                 SupervisorAuditRuleId: null,
        //                 AdminOnly: false,
        //                 MemberOnly: true,
        //                 EffectiveDate: "2020-01-01T00:00:00Z",
        //                 DeactivationDate: null,
        //                 Deductible: 0,
        //                 PercentagePayable: 0,
        //                 AllowDependants: true,
        //                 Id: "700b8341-a15b-4ea9-7875-08db2170b49a",
        //                 Benefit: {
        //                   Name: "Dental",
        //                   Translations: [
        //                     {
        //                       Name: "Dental",
        //                       RefId: "5f5dc531-f381-4606-66ca-08db09dbdb6d",
        //                       LanguageCode: "en",
        //                       Id: "724291a3-b103-497a-3500-08db09dbdb78",
        //                     },
        //                     {
        //                       Name: "Dental",
        //                       RefId: "5f5dc531-f381-4606-66ca-08db09dbdb6d",
        //                       LanguageCode: "fr",
        //                       Id: "9b48055d-3e4f-4f47-3501-08db09dbdb78",
        //                     },
        //                   ],
        //                 },
        //               },
        //             },
        //           },
        //         ],
        //       },
        //       {
        //         Identifier: "CMP-111-23032317024080",
        //         MemberId: "40598ee2-411c-4846-a6d4-01ffbd719fdb",
        //         PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //         PlanNumber: "111",
        //         Comments: null,
        //         Sequence: "23032317024080",
        //         PaymentNumber: null,
        //         Timestamp: "2023-03-23T15:02:42.286028Z",
        //         ApplyRemainingToHCSA: false,
        //         RefundToDirectDeposit: false,
        //         Payee: "Member",
        //         ChequeNumber: null,
        //         UserType: "Member",
        //         PaymentMethod: "DirectDeposit",
        //         ExternalSubmissionId: "CMP-111-23032317024080",
        //         ExternalResponseProduced: false,
        //         ProviderId: "61c95a86-2768-4f72-48cb-08daf4ac2cfe",
        //         OfficeId: "2efc732b-bfc6-4177-2fb8-08daf4ac2d06",
        //         Id: "61f5632d-2ade-4463-2d58-08db2baf4de5",
        //         ExtractedTeeth: [],
        //         Claims: [
        //           {
        //             Creator: "",
        //             ClaimSubmissionId: "61f5632d-2ade-4463-2d58-08db2baf4de5",
        //             MemberId: "40598ee2-411c-4846-a6d4-01ffbd719fdb",
        //             DependantId: null,
        //             LastName: "Campbell",
        //             FirstName: "Jessica",
        //             Student: null,
        //             Disabled: null,
        //             School: null,
        //             HSACoveredBenefitId: null,
        //             PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //             Id: "6e284cbf-2487-47d5-d4be-08db2baf4eb0",
        //             Type: "ClaimWorkflow",
        //             State: "Rejected",
        //             Assignee: "",
        //             Proofs: [
        //               {
        //                 Name: null,
        //                 Timestamp: "2023-03-23T15:08:13.7351231Z",
        //                 ReferralDate: null,
        //                 ProofType: "Expense",
        //                 ClaimSubmissionId:
        //                   "61f5632d-2ade-4463-2d58-08db2baf4de5",
        //                 ClaimLines: [1],
        //                 Id: "31698283-5a88-4c49-25a8-08db2baf4ed2",
        //                 FileSegments: [
        //                   {
        //                     Id: "9518315c-58a6-4468-4d1a-08db2baf4ed7",
        //                     FileName: "emptypage.pdf",
        //                     Timestamp: "2023-03-23T15:02:34.3590683Z",
        //                     FileType: null,
        //                     FileSize: 24805,
        //                     PhysicalFileId:
        //                       "94fc47bd-8bc9-ed11-bdd2-005056959613",
        //                     ProofId: "31698283-5a88-4c49-25a8-08db2baf4ed2",
        //                   },
        //                 ],
        //               },
        //             ],
        //             CurrentClaimData: {
        //               Id: "b7cf849f-6c3a-4610-3610-08db2baf4eba",
        //               ClaimId: "6e284cbf-2487-47d5-d4be-08db2baf4eb0",
        //               Status: "Rejected",
        //               Data: null,
        //               ClaimedAmount: 10,
        //               PaidAmount: null,
        //               EligibleAmount: 10,
        //               CoveredAmount: 10,
        //               CoinsuranceAmount: 8,
        //               CopaymentAmount: 2,
        //               LabFee: null,
        //               PaidLabFee: null,
        //               EligibleLabFee: null,
        //               CoveredLabFee: null,
        //               CoinsuranceLabFee: null,
        //               CopaymentLabFee: null,
        //               Timestamp: "2023-03-23T15:10:17.9374279Z",
        //               ProcedureCode: null,
        //               SurfaceCode: null,
        //               PlanBenefitId: "eb168e8c-c7fa-474c-7514-08db2170b49a",
        //               ToothCodeId: "70b48e6f-f5eb-42a7-8df7-286646274909",
        //               DateOfService: "2023-02-02T00:00:00Z",
        //               ReferralDate: null,
        //               ExpenseAmount: 10,
        //               HasCOB: false,
        //               COBAmount: 0,
        //               DIN: null,
        //               Line: 1,
        //               EOB: [],
        //               AdjudicationError: {
        //                 code: "75",
        //                 genericMessage:
        //                   "Max number of claims for the specified number of months has been reached.",
        //                 message:
        //                   "Maximum of {0} claims per {1} month(s) has been reached.",
        //                 formattedParams: ["1", "36"],
        //               },
        //               PlanBenefit: {
        //                 PlanId: "f978da2f-b0c1-456e-9cb1-6c0f4332077b",
        //                 BenefitId: "b11b3dbc-9092-4d66-89cf-08db09bf6ab8",
        //                 RequiresReferral: false,
        //                 RequiresLetter: false,
        //                 AuditRuleId: null,
        //                 SupervisorAuditRuleId: null,
        //                 AdminOnly: true,
        //                 MemberOnly: false,
        //                 EffectiveDate: "2020-01-01T00:00:00Z",
        //                 DeactivationDate: null,
        //                 Deductible: 0,
        //                 PercentagePayable: 80,
        //                 AllowDependants: true,
        //                 Id: "eb168e8c-c7fa-474c-7514-08db2170b49a",
        //                 Benefit: {
        //                   Name: "EXAMINATIONS, COMPLETE ORAL",
        //                   Translations: [
        //                     {
        //                       Name: "EXAMINATIONS, COMPLETE ORAL",
        //                       RefId: "b11b3dbc-9092-4d66-89cf-08db09bf6ab8",
        //                       LanguageCode: "en",
        //                       Id: "dfdac098-ba77-4256-a669-08db09bf6ac2",
        //                     },
        //                     {
        //                       Name: "EXAMINATIONS, COMPLETE ORAL",
        //                       RefId: "b11b3dbc-9092-4d66-89cf-08db09bf6ab8",
        //                       LanguageCode: "fr",
        //                       Id: "72666e48-bf3d-4172-a66a-08db09bf6ac2",
        //                     },
        //                   ],
        //                 },
        //               },
        //             },
        //           },
        //         ],
        //       },
        //     ],
        //   };
        //   const lastClaim = mockClaims.value[0];
        //   this.recentClaim = {
        //     ClaimNo: lastClaim.Identifier,
        //     Date: lastClaim.Timestamp?.split("T")[0],
        //     PaidTo: lastClaim.Payee,
        //     ChequeNo: lastClaim.ChequeNumber,
        //     TotalSubmitted:
        //       lastClaim.Claims[0]?.CurrentClaimData?.ClaimedAmount,
        //     TotalPaid: this.calculateTotalPaid(lastClaim),
        //   };
        // }
      } finally {
        this.loading = false;
      }
    },
    formatPaidTo(paidTo) {
      if (!paidTo) return;
      if (paidTo !== "M" && paidTo !== "P") paidTo = "P";
      return this.$helpers.formatEnumModel(
        paidTo,
        "PaidTo",
        this.$vuetify.lang.current
      );
    },
    calculateTotalPaid(claim) {
      let totalPaid = 0;
      if (claim?.Claims?.length) {
        claim?.Claims.forEach((c) => {
          totalPaid += c.CurrentClaimData?.PaidAmount;
        });
      }
      return totalPaid;
    },
  },
};
</script>
<style scoped>
.lh-1 {
  line-height: 1.1rem !important;
}
.title1 {
  font-size: 12px;
  font-weight: 600;
}
</style>
