<template>
  <div v-if="selectedCountry && fetchedAddress" class="mt-2">
    <v-row no-gutters align="center">
      <v-icon color="primary"> mdi-checkbox-marked-circle-outline </v-icon>
      <span class="text-title-1 ml-1 secondary--text bolder-text">
        {{ $t("components.address_expansion_panel.verify_address") }}
      </span>
      <span
        class="text-title-1 ml-1 red--text"
        v-if="addressCategory === 'Non-Civic'"
      >
        {{ $t("components.address_expansion_panel.non-civic") }}
      </span>
      <span
        class="text-title-1 ml-1 red--text"
        v-if="addressCategory === 'Civic'"
      >
        {{ $t("components.address_expansion_panel.civic") }}
      </span>
      <span
        class="text-title-1 ml-1 red--text"
        v-if="addressCategory === 'Non-Civic US'"
      >
        {{ $t("components.address_expansion_panel.non-civic-us") }}
      </span>
      <span
        class="text-title-1 ml-1 red--text"
        v-if="addressCategory === 'Non-Civic International'"
      >
        {{ $t("components.address_expansion_panel.non-civic-international") }}
      </span>
    </v-row>
    <!-- <v-row
      no-gutters
      align="center"
      class="py-2"
      v-if="!memberSaved && errorMessage?.length && !retry"
    >
      <v-col cols="12" class="text-left">
        <span class="text-title-1 red--text text-left">
          {{ $t("components.address_expansion_panel.error_saving_address") }}
          <a
            @click="setAddressCategory('Non-Civic')"
            class="custom-underline secondary--text"
            v-if="addressCategory === 'Civic'"
          >
            {{
              $t("components.address_expansion_panel.enter_address_non_civic")
            }}
          </a>
        </span>
      </v-col>
      <div v-if="errorMessage?.length" class="red--text errors">
        <div class="font-weight-bold">
          {{ $t("components.address_expansion_panel.address_errors") }}
        </div>
        <div v-for="msg of errorMessage" :key="msg">
          {{ msg }}
        </div>
      </div>
    </v-row> -->
    <v-form v-model="validForm" class="pt-8">
      <v-row>
        <template
          v-if="!selectedCountry.isOther && selectedCountry.code === 'CAN'"
        >
          <v-col
            cols="12"
            md="6"
            lg="2"
            class="py-0"
            v-if="addressCategory !== 'Non-Civic'"
          >
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :rules="[$rules.alphanumeric]"
              :label="$t('components.address_expansion_panel.apt_unit_suite')"
              v-model="internalMember.Address.AptUnitSuite"
              @change="selectedAddress = null"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="2"
            class="py-0"
            v-if="addressCategory !== 'Non-Civic'"
          >
            <no-autocomplete-textfield
              disabled
              v-if="addressCategory !== 'Non-Civic'"
              type="number"
              :rules="[$rules.required]"
              class="required errors-msg"
              @change="selectedAddress = null"
              clearable
              outlined
              :label="$t('components.address_expansion_panel.street_number')"
              v-model="internalMember.Address.StreetNumber"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col
            cols="12"
            md="6"
            lg="4"
            class="py-0"
            v-if="addressCategory !== 'Non-Civic'"
          >
            <no-autocomplete-textfield
              disabled
              v-if="addressCategory !== 'Non-Civic'"
              clearable
              outlined
              :rules="[$rules.required]"
              class="required errors-msg"
              @change="selectedAddress = null"
              :label="$t('components.address_expansion_panel.street_name')"
              v-model="internalMember.Address.StreetName"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col
            cols="12"
            md="3"
            lg="2"
            class="py-0"
            v-if="addressCategory !== 'Non-Civic'"
          >
            <v-text-field
              disabled
              outlined
              :label="$t('components.address_expansion_panel.street_type')"
              v-model="internalMember.Address.StreetType"
              :items="streetTypesList"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
            lg="2"
            class="py-0"
            v-if="addressCategory !== 'Non-Civic'"
          >
            <v-text-field
              disabled
              outlined
              :label="$t('components.address_expansion_panel.street_direction')"
              :value="internalMember.Address.StreetDirection"
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="12" md="3" lg="2" class="py-0">
                  <v-autocomplete
                    outlined
                    :label="
                      $t('components.address_expansion_panel.street_direction')
                    "
                    v-model="internalMember.Address.StreetDirection"
                    :item-text="
                      $vuetify.lang.current === 'en' ? 'textEn' : 'textFr'
                    "
                    item-value="value"
                    :items="enumModels.StreetDirection"
                  ></v-autocomplete>
                </v-col> -->
          <v-col cols="12" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.address_expansion_panel.city')"
              :value.sync="internalMember.Address.City"
              :rules="[$rules.required]"
              class="required errors-msg"
              @change="selectedAddress = null"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" md="4" lg="5" class="py-0">
            <v-select
              outlined
              disabled
              :label="$t('components.address_expansion_panel.province')"
              v-model="internalMember.Address.Province"
              :rules="[$rules.required]"
              class="required errors-msg"
              @change="selectedAddress = null"
              :items="enumModels.Province"
              :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
              item-value="code"
            ></v-select>
          </v-col>

          <v-col cols="12" md="4" lg="3" class="py-0">
            <no-autocomplete-textfield
              disabled
              v-mask="`A#A #A#`"
              placeholder="A1A 1A1"
              clearable
              outlined
              :rules="[$rules.required]"
              @change="selectedAddress = null"
              class="required errors-msg"
              :label="$t('components.address_expansion_panel.postal_code')"
              :value.sync="internalMember.Address.PostalCode"
            ></no-autocomplete-textfield>
          </v-col>
        </template>

        <template v-if="selectedCountry.isOther">
          <v-col cols="12" class="py-0">
            <v-autocomplete
              disabled
              outlined
              clearable
              :items="countries"
              :label="$t('components.address_expansion_panel.country')"
              :rules="[$rules.required]"
              v-model="internalMember.Address.Country"
              item-text="text"
              item-value="value"
              class="required errors-msg"
              @change="selectedAddress = null"
            ></v-autocomplete>
          </v-col>

          <!-- <v-col cols="12" md="4" class="py-0">
            <no-autocomplete-textfield
              disabled
              clearable
              outlined
              :label="$t('components.address_expansion_panel.city')"
              :value.sync="internalMember.Address.City"
              @change="selectedAddress = null"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" md="4" lg="5" class="py-0">
            <no-autocomplete-textfield
              outlined
              disabled
              :label="$t('components.address_expansion_panel.province')"
              v-model="internalMember.Address.Province"
              @change="selectedAddress = null"
              :items="enumModels.Province"
              :item-text="$vuetify.lang.current === 'en' ? 'textEn' : 'textFr'"
              item-value="value"
            ></no-autocomplete-textfield>
          </v-col>

          <v-col cols="12" md="4" lg="3" class="py-0">
            <no-autocomplete-textfield
              disabled
              outlined
              @change="selectedAddress = null"
              :label="$t('components.address_expansion_panel.postal_code')"
              v-model="internalMember.Address.PostalCode"
            ></no-autocomplete-textfield>
          </v-col> -->
        </template>
        <v-col
          cols="12"
          md="4"
          class="py-0"
          v-if="selectedCountry.code === 'USA'"
        >
          <v-autocomplete
            disabled
            outlined
            clearable
            :items="states"
            :label="$t('components.address_expansion_panel.state')"
            :rules="[$rules.required]"
            v-model="internalMember.Address.State"
            item-text="name"
            item-value="name"
            class="required errors-msg"
            @change="selectedAddress = null"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="py-0"
          v-if="selectedCountry.code === 'USA'"
        >
          <no-autocomplete-textfield
            disabled
            clearable
            outlined
            :label="$t('components.address_expansion_panel.city')"
            :value.sync="internalMember.Address.City"
            :rules="[$rules.required]"
            class="required errors-msg"
            @change="selectedAddress = null"
          ></no-autocomplete-textfield>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="py-0"
          v-if="selectedCountry.code === 'USA'"
        >
          <no-autocomplete-textfield
            clearable
            disabled
            v-mask="`#####-####`"
            outlined
            :label="$t('components.address_expansion_panel.zip_code')"
            :value.sync="internalMember.Address.ZipCode"
            :rules="[$rules.required]"
            class="required errors-msg"
            @change="selectedAddress = null"
          ></no-autocomplete-textfield>
        </v-col>

        <v-row no-gutters class="px-3" v-if="addressCategory !== 'Civic'">
          <v-col cols="12" class="py-0">
            <no-autocomplete-textfield
              dense
              clearable
              outlined
              :label="$t('components.address_expansion_panel.address_line')"
              v-model="internalMember.Address.AddressLine"
              :rules="[$rules.required]"
              class="required errors-msg"
            ></no-autocomplete-textfield>
          </v-col>
          <v-col cols="12" class="py-0">
            <no-autocomplete-textfield
              dense
              clearable
              outlined
              :label="$t('components.address_expansion_panel.address_line2')"
              v-model="internalMember.Address.AddressLine2"
            ></no-autocomplete-textfield>
          </v-col>
          <v-col cols="12" class="py-0">
            <no-autocomplete-textfield
              dense
              clearable
              outlined
              :label="$t('components.address_expansion_panel.address_line3')"
              v-model="internalMember.Address.AddressLine3"
            ></no-autocomplete-textfield>
          </v-col>
        </v-row>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import countries from "@/countries";
import streetTypesList from "@/streetTypesList";
import { mapGetters } from "vuex";
import states from "@/statesUSA";
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
export default {
  components: { NoAutocompleteTextfield },
  props: {
    selectedCountry: Object,
    fetchedAddress: String,
    addressCategory: String,
    memberSaved: Boolean,
    errorMessage: Array,
    member: Object,
    retry: Boolean,
    valid: Boolean,
  },
  data() {
    return {
      countries: countries,
      states: states,
      streetTypesList: streetTypesList,
      validForm: false,
      internalMember: {
        Address: {},
      },
    };
  },
  watch: {
    internalMember: {
      handler(v) {
        this.$emit("update-member", v);
      },
      deep: true,
    },
    fetchedAddress: {
      handler(v) {
        if (!v) this.validForm = false;
      },
      immediate: true,
    },
    validForm: {
      handler(v) {
        this.$emit("update:valid", v);
      },
      immediate: true,
    },
    // member: {
    //   handler(v) {
    //     if (v) {
    //       console.log(v);
    //       // this.internalMember = v;
    //       this.internalMember = JSON.parse(JSON.stringify(v));
    //       this.setMemberData(v);
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    ...mapGetters("common", ["enumModels"]),
  },
  mounted() {
    if (this.member) {
      this.internalMember = JSON.parse(JSON.stringify(this.member));
    }
  },
};
</script>
<style scoped>
.bolder-text {
  font-weight: 600;
}
.errors {
  text-align: left;
  font-size: 12px;
  line-height: 1.2;
}

.errors-msg {
  text-align: left;
  color: red !important;
}
.errors-msg:not(.v-input--is-dirty) >>> fieldset {
  border: 2px solid red !important;
}
</style>
