<template>
  <div id="app-pdf">
    <vue-html2pdf
      :show-layout="false"
      :enable-download="enableDownload"
      pdf-content-width="100%"
      :preview-modal="true"
      :filename="filename || filenameDefault"
      :pdf-quality="2"
      :pdf-format="pdfFormat || 'letter'"
      :pdf-orientation="pdfOrientation || 'portrait'"
      :manual-pagination="true"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <!-- <pdf-content
        :reportData="reportData"
        @domRendered="domRendered()"
        slot="pdf-content"
      /> -->
      <component
        :is="pdfContent || 'PdfContent'"
        :reportData="reportData"
        @domRendered="domRendered()"
        slot="pdf-content"
        :claim="claim"
        :member="member"
      />
    </vue-html2pdf>

    <section class="title-container">
      <h1 class="title-header">Vue HTML2PDF</h1>
    </section>
  </div>
</template>

<script>
import PensionPdfContent from "@/components/PdfGenerator/PensionPdfContent.vue";
import ClaimPdfContent from "@/components/PdfGenerator/ClaimPdfContent.vue";
// import VueHtml2pdf from "@/components/VueHtml2pdf";
// import ControlsContainer from "@/components/PdfGenerator/ControlsContainer.vue";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
export default {
  props: {
    pdfFormat: String,
    pdfOrientation: String,
    margin: Array,
    filename: String,
    printPdf: Boolean,
    savePdf: Boolean,
    report: Object,
    pdfContent: String,
    member: {
      type: Object,
      default: () => {},
    },
    claim: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueHtml2pdf,
    PensionPdfContent,
    ClaimPdfContent,
  },
  data() {
    return {
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
      enableDownload: false,
      reportDataHardcoded: {
        Broker: "Canadian Commercial Workers Industry",
        HeaderTitle: "Pension Plan",
        RegistrationNo: "Registration No. 0580431",
        MemberDetailsLeft: {
          Name: "John Doe",
          Address: "123 Somewhere Street Apt #10",
          City: "Toronto",
          Province: "ON",
          ["Postal Code"]: "H0H H0H",
        },
        MemberDetailsRight: {
          ["Membership Id"]: "2334543",
          ["Date of Birth"]: "1983-03-12",
          ["Earliest Hire Date"]: "2002-03-12",
          ["Latest Remittance Date"]: "2002-03-12",
          ["Years of Continuous Serivce"]: "11.20",
          ["Local Union"]: "12345",
          ["Spouse"]: "Jane Doe",
          ["Beneficiaries"]: "Mike Doe",
        },
        Title: "Statement Of Accrued Pension Benefit",
        Tables: [
          {
            TableTitle:
              "Total Monthly Pension Payable At Normal Retirement Date",
            Headers: [
              {
                text: "Years Of Total Credited Service",
                value: "YearsOfTotalCreditedService",
                align: "center",
              },
              {
                text: "Monthly Pension",
                value: "MonthlyPension",
                align: "center",
              },
              {
                text: "Total Member Contributions",
                value: "TotalMemberContributions",
                align: "center",
              },
            ],
            Items: [
              {
                YearsOfTotalCreditedService: 4.6,
                MonthlyPension: 355.44,
                TotalMemberContributions: 23355.44,
              },
            ],
          },
          {
            TableTitle: "Benefit Accrued Post: ",
            TableDate: "June 30,2015",
            Headers: [
              {
                text: "Year",
                value: "Year",
                align: "center",
              },
              {
                text: "Hours",
                value: "Hours",
                align: "center",
              },
              {
                text: "Years of Credited Service",
                value: "YearsOfCreditedService",
                align: "center",
              },
              {
                text: "Monthly Pension",
                value: "MonthlyPension",
                align: "center",
              },
              {
                text: "Member Contributions",
                value: "MemberContributions",
                align: "center",
              },
            ],
            Items: [
              {
                Year: "2022",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2021",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2020",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2019",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2018",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2017",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2016",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Year: "2015",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
            ],
          },
          {
            TableTitle: "Employment Detail Post: ",
            TableDate: "June 30,2015",
            Headers: [
              {
                text: "Employer",
                value: "Employer",
                align: "center",
              },
              {
                text: "Year",
                value: "Year",
                align: "center",
              },
              {
                text: "Hours",
                value: "Hours",
                align: "center",
              },
              {
                text: "Years of Credited Service",
                value: "YearsOfCreditedService",
                align: "center",
              },
              {
                text: "Monthly Pension",
                value: "MonthlyPension",
                align: "center",
              },
              {
                text: "Member Contributions",
                value: "MemberContributions",
                align: "center",
              },
            ],
            Items: [
              {
                Employer: "2022",
                Year: "2022",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2021",
                Year: "2021",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2020",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2019",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2018",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2017",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2016",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
              {
                Employer: "2022",
                Year: "2015",
                Hours: "123.45",
                YearsOfCreditedService: "1.23",
                MonthlyPension: "123.99",
                MemberContributions: "1234.23",
              },
            ],
          },
          {
            TableTitle: "Payable At Normal Retirement Date",
            Headers: [
              {
                text: "Years Of Total Credited Service",
                value: "YearsOfTotalCreditedService",
                align: "center",
              },
              {
                text: "Monthly Pension",
                value: "MonthlyPension",
                align: "center",
              },
              {
                text: "Total Member Contributions",
                value: "TotalMemberContributions",
                align: "center",
              },
            ],
            Items: [
              {
                YearsOfTotalCreditedService: 4.6,
                MonthlyPension: 355.44,
                TotalMemberContributions: 23355.44,
              },
            ],
          },
        ],
      },
    };
  },
  watch: {
    printPdf: {
      handler(v) {
        if (v) {
          this.downloadPdf();
          this.enableDownload = false;
        }
      },
    },
    savePdf: {
      handler(v) {
        if (v) {
          this.downloadPdf();
          this.enableDownload = true;
        }
      },
    },
  },
  computed: {
    filenameDefault() {
      return "PensionSatement-" + moment().format("YYYY-MM-DD-HH-mm-ss");
    },
    reportData() {
      return this.report || this.reportDataHardcoded;
    },
    htmlToPdfOptions() {
      return {
        margin: 0.6,

        filename: this.filenameDefault,

        image: {
          type: "jpeg",
          quality: 1,
        },

        enableLinks: true,

        html2canvas: {
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: this.pdfFormat || "letter",
          orientation: this.pdfOrientation || "portrait",
        },
      };
    },
  },

  methods: {
    async downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`);
    },

    startPagination() {
      console.log(`PDF has started pagination`);
      this.generatingPdf = true;
    },

    hasPaginated() {
      console.log(`PDF has been paginated`);
      this.generatingPdf = false;
    },

    // eslint-disable-next-line no-unused-vars
    async beforeDownload({ html2pdf, options, pdfContent }) {
      console.log(`On Before PDF Generation`);
      // await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      // 	const totalPages = pdf.internal.getNumberOfPages()
      // 	for (let i = 1; i <= totalPages; i++) {
      // 		pdf.setPage(i)
      // 		pdf.setFontSize(10)
      // 		pdf.setTextColor(150)
      // 		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      // 	}
      // }).save()
    },

    hasDownloaded(blobPdf) {
      console.log(`PDF has downloaded`);
      this.pdfDownloaded = true;
      this.$emit("pdf-generated");
      console.log(blobPdf);
    },

    domRendered() {
      console.log("Dom Has Rendered");
      this.contentRendered = true;
    },

    onBlobGenerate(blob) {
      console.log(blob);
    },
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
}

#app-pdf {
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .title-container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .title-header {
    color: #fff;
    padding: 25px 50px;
    border: 5px solid #fff;
    border-radius: 5px;
    opacity: 0;
  }
}
</style>
