var render = function render(){var _vm=this,_c=_vm._self._c;return _c('material-card-custom',{attrs:{"size":_vm.internalSize,"height":_vm.internalHeight,"order":_vm.internalOrder,"maxOrderValue":_vm.maxOrderValue,"widgetId":_vm.widgetId,"color":"primary","title":_vm.$t(
      'components.vue_material_dashboard.items.my_documents_dashboard_item.title'
    )},on:{"update:size":function($event){_vm.internalSize=$event},"update:height":function($event){_vm.internalHeight=$event},"update:order":function($event){_vm.internalOrder=$event},"remove-widget":function($event){return _vm.$emit('remove-widget', $event)},"add-widget":function($event){return _vm.$emit('add-widget', $event)}}},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"download-centre"}},[_c('v-card-text',{staticClass:"text-left pb-0"},[_c('v-list',{staticClass:"pt-0"},[_c('v-list-item-group',{model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},[(_vm.loading)?_c('v-list-item',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{staticClass:"mt-10",attrs:{"size":"50","indeterminate":""}})],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-list-item',{staticClass:"px-0",on:{"click":function($event){$event.preventDefault();return _vm.$emit('view-pdf', _vm.downloadsData[0])}}},[_c('v-list-item-icon',{staticClass:"my-auto"},[_c('v-col',[_c('v-img',{attrs:{"max-width":_vm.$vuetify.breakpoint.mdOnly ? 100 : 120,"contain":"","src":_vm.downloadsData[0]
                        ? _vm.$vuetify.lang.current === 'en'
                          ? _vm.downloadsData[0].PreviewImageUrlEN
                          : _vm.downloadsData[0].PreviewImageUrlFR
                        : ''},on:{"error":function($event){_vm.imageLoadError = true},"load":function($event){_vm.imageLoadError = false}}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.imageLoadError),expression:"imageLoadError"}],staticClass:"mx-auto",attrs:{"width":_vm.$vuetify.breakpoint.mdOnly ? 100 : 120,"height":_vm.$vuetify.breakpoint.mdOnly ? 130 : 155,"type":"image"}})],1)],1)],1),_c('v-list-item-content',{staticClass:"py-0"},[_c('v-col',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.downloadsData[0] ? _vm.$vuetify.lang.current === "en" ? _vm.downloadsData[0].TitleEN : _vm.downloadsData[0].TitleFR : "")+" ")])],1)],1),_c('v-divider'),_vm._l((3),function(i){return [_c('v-list-item',{key:i,staticClass:"px-0",on:{"click":function($event){$event.preventDefault();return _vm.$emit('view-pdf', _vm.downloadsData[i + 1])}}},[_c('v-list-item-icon',{staticClass:"my-auto"},[_c('v-icon',{attrs:{"size":"50","color":"primary"}},[_vm._v("mdi-file-document")])],1),_c('v-list-item-content',{staticClass:"py-1"},[_c('v-col',{class:`
                    text-subtitle-2 text-lg-subtitle-1
                    font-weight-bold
                    py-2`},[_vm._v(" "+_vm._s(_vm.downloadsData[i + 1] ? _vm.$vuetify.lang.current === "en" ? _vm.downloadsData[i + 1].TitleEN : _vm.downloadsData[i + 1].TitleFR : "")+" ")])],1)],1),_c('v-divider',{key:i + '-divider'})]})],2)],1)],1)],1)],1),_c('v-row',{staticClass:"bottom-btn",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"secondary lighten-2",attrs:{"width":"150px","to":"download-centre-new"}},[_vm._v(_vm._s(_vm.$t( "components.vue_material_dashboard.items.my_documents_dashboard_item.more" )))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }