<template>
  <material-card-sba-small
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    color="warning"
    :widgetIcon="widgetIcon"
    :title="$t('components.vue_material_dashboard.items.drug_search.title')"
  >
    <v-card-text>
      <v-form v-model="valid" class="pa-4 pl-2 pt-0">
        <v-row no-gutters justify="center">
          <v-col class="pa-0" cols="10">
            <v-col cols="12" class="text-left pa-0">
              <label
                for="drug"
                class="text-uppercase font-weight-bold input-label"
                >{{
                  $t(
                    "components.vue_material_dashboard.items.drug_search.drug_name"
                  )
                }}</label
              >
              <v-combobox
                :menu-props="{
                  contentClass: 'text-left autocomplete-search',
                  dense: true,
                }"
                clearable
                name="drug"
                dense
                outlined
                v-model="drug"
                class="mr-2 rounded-lg search-input"
                :items="entries"
                :loading="isLoading"
                :search-input.sync="searchDrug"
                hide-no-data
                hide-selected
                item-text="Name"
                item-value="Name"
                :placeholder="$t('components.drug_search.search_by_name')"
                @blur="entries = []"
                auto-select-first
                hide-details
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="item.Name"
                      class="auto-search"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon :color="item.IsActive ? 'success' : 'error'">{{
                      item.IsActive ? "mdi-check" : "mdi-close"
                    }}</v-icon>
                  </v-list-item-action>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" class="text-left pa-0 mt-n1">
              <label for="din" class="font-weight-bold input-label">{{
                $t("components.vue_material_dashboard.items.drug_search.din")
              }}</label>
              <v-combobox
                :menu-props="{ contentClass: 'text-left' }"
                type="number"
                clearable
                name="din"
                hide-spin-buttons
                hide-details
                dense
                outlined
                v-model="din"
                class="rounded-lg search-input mr-2"
                :items="entries"
                :loading="isLoading"
                :search-input.sync="searchDin"
                hide-no-data
                hide-selected
                item-text="Din"
                item-value="Din"
                :placeholder="$t('components.drug_search.search_by_din')"
                @blur="entries = []"
                auto-select-first
              ></v-combobox>
            </v-col>
          </v-col>
          <v-col cols="1" class="pa-0 pb-1" align-self="end">
            <v-btn
              :disabled="!din && !drug"
              min-width="40"
              color="secondary"
              @click="search"
              ><v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-left pa-0 note">
            {{ $t("components.vue_material_dashboard.items.drug_search.note") }}
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </material-card-sba-small>
</template>

<script>
import MaterialCardSbaSmall from "@/components/VueMaterialDashboard/material/MaterialCardSmallSbaDashboard.vue";
// import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapActions, mapGetters } from "vuex";
import demoDrugsList from "@/components/DrugSearch/DemoDrugsList.json";
export default {
  components: {
    MaterialCardSbaSmall,
    // NoAutocompleteTextfield,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      valid: false,
      din: null,
      drug: null,
      loading: false,
      isLoading: false,
      entries: [],
      searchDin: null,
      searchDrug: null,
      timerId: null,
      demoDrugsList: demoDrugsList,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", ["userProfile", "isDemoMockPlan"]),
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
    searchDin(v) {
      if (!v || this.isLoading) return;
      if (this.searchDin.length < 3) return;
      this.autocompleteSearch();
    },
    searchDrug(v) {
      if (!v || this.isLoading) return;
      if (this.searchDrug.length < 3) return;
      this.autocompleteSearch();
    },
  },
  mounted() {
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    if (this.height !== null && this.height !== undefined) {
      this.internalHeight = this.height;
    }
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("data", ["searchDrugs"]),
    search() {
      this.loading = true;
      if (this.din && typeof this.din == "object") {
        this.din = this.din.Din;
      }
      if (this.drug && typeof this.drug == "object") {
        this.drug = this.drug.Name;
      }
      this.$router.push({
        path: "drug-search",
        query: {
          din: this.din,
          name: this.drug,
        },
      });
    },
    async autocompleteSearch() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        this.isLoading = true;
        try {
          if (this.isDemoMockPlan) {
            this.demoDrugSearch();
            return;
          } else {
            this.entries = (
              await this.searchDrugs({
                planIdentityId: this.user.plan,
                memberIdentityId: this.user.sub,
                memberGroupId: this.userProfile?.MemberGroup?.IdentityId,
                name: this.searchDrug ? this.searchDrug.toLowerCase() : null,
                din: this.searchDin,
                itemsPerPage: 40,
                page: 1,
              })
            ).data.value;
          }
          this.isLoading = false;
        } catch (error) {
          this.error = error;
          console.log(error);
          this.isLoading = false;
        } finally {
          this.isLoading = false;
        }
      }, 500);
    },
    async demoDrugSearch() {
      this.entries = this.demoDrugsList.filter((drug) => {
        return (
          drug?.Name?.toLowerCase().includes(this.searchDrug?.toLowerCase()) ||
          drug?.Din?.includes(this.searchDin)
        );
      });
    },
  },
};
</script>
<style scoped>
.search-input >>> fieldset {
  height: 40px;
}
.search-input >>> .v-input__append-inner {
  margin-top: 6px !important;
}
.search-input >>> input::placeholder {
  font-size: 15px !important;
}
.input-label {
  font-size: 12px;
}
.note {
  font-size: 9px;
  line-height: 0.9;
  font-weight: 600;
}
</style>
