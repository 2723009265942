import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
// Translation provided by Vuetify (typescript)
import en from "vuetify/es5/locale/en";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";
// Your own translation file
import fr from "vuetify/es5/locale/fr";

import store from "@/store";

Vue.use(Vuetify);

const vuetifyTheme = store.getters["common/vuetifyTheme"];
export default new Vuetify({
  lang: {
    locales: { en, fr },
    current: "en",
  },
  theme: {
    themes: {
      light: {
        primary: vuetifyTheme.Light.Primary,
        secondary: vuetifyTheme.Light.Secondary,
        accent: vuetifyTheme.Light.Accent,
        error: vuetifyTheme.Light.Error,
        warning: vuetifyTheme.Light.Warning,
        info: vuetifyTheme.Light.Info,
        success: vuetifyTheme.Light.Success,
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
