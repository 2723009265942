<template>
  <v-dialog v-model="dialog" width="400" class="mx-0">
    <v-card max-width="400">
      <v-card-title class="primary white--text mb-6">
        <v-icon dark class="mr-2">mdi-account-box-outline</v-icon>
        {{ $t("components.member_image_dialog.title") }}</v-card-title
      >
      <v-card-text>
        <v-form v-model="valid">
          <image-uploader-string
            @image-data="imageBase64 = $event"
            @cancel-upload="imageBase64 = ''"
            :image.sync="imageBase64"
            :label="$t('components.member_image_dialog.label')"
            :rules="[$rules.required]"
          ></image-uploader-string>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pr-6">
        <v-btn
          :disabled="saving"
          text
          @click="
            $emit('close');
            imageBase64 = null;
          "
        >
          {{ $t("globals.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="saving"
          color="primary"
          @click="save"
        >
          {{ $t("globals.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageUploaderString from "@/components/MemberInformation/items/ImageUploaderString.vue";
export default {
  props: ["model"],
  components: {
    ImageUploaderString,
  },
  data() {
    return {
      imageBase64: null,
      valid: false,
      saving: false,
      dialog: false,
    };
  },
  watch: {
    model: {
      handler(v) {
        this.dialog = v;
      },
    },
  },
  methods: {
    save() {
      console.log("save image");
      this.$emit("save", this.imageBase64);
    },
  },
};
</script>
