<template>
  <div>
    <v-card flat>
      <!-- <v-card-title class="primary white--text" v-if="showTitle">
        <v-icon dark class="mr-2">{{ "mdi-file-image" }}</v-icon>
        <span>{{
          uploadProofDialogTitle ??
          $t("components.member_information.dependants.add_dependant_proof")
        }}</span>
      </v-card-title> -->
      <v-card-text class="pt-0 mb-4">
        <v-row justify="center">
          <v-col cols="12" class="px-0 pt-4 pb-0">
            <v-card-title class="py-0 pl-0 text-subtitle-1 font-weight-bold">
              <v-icon class="mr-2 primary--text"
                >mdi-check-decagram-outline</v-icon
              >
              {{ $t("components.dependants_form.proof") }}
            </v-card-title>
          </v-col>
          <v-col cols="auto" class="pt-0" v-show="dependant.LifeEventProof">
            {{
              $t(
                "components.member_information.dependants.dependant_proof.life_event_must"
              )
            }}
          </v-col>
          <v-col cols="auto" class="pt-0" v-show="dependant.CommonLawProof">
            {{
              $t(
                "components.member_information.dependants.dependant_proof.common_law_must"
              )
            }}
          </v-col>
          <v-col cols="auto" class="pa-0 pb-5">
            <v-row>
              <proof-upload-box
                class="animate__animated animate__fadeIn animate__faster"
                v-show="dependant.Disabled"
                :proof="dependant?.Qualification?.ProofOfDisability"
                :proofs="proofs"
                :uploading="savingReceipt"
                :loading="
                  loading &&
                  proofToPreview?.ProofType === 'DependantDisabilityProof'
                "
                :title="$t('components.dependants_form.proof_of_disability')"
                :disabled="false"
                proofType="DependantDisabilityProof"
                uploadType="DependantDisabilityProof"
                @upload-receipt="startUpload"
                @preview-receipt="receiptToPreview = $event"
                @preview-proof-files="previewProof"
                @remove-receipt="$emit('remove-receipt', $event)"
              />
              <proof-upload-box
                class="animate__animated animate__fadeIn animate__faster"
                v-show="dependant.Student"
                :proof="dependant?.Qualification?.ProofOfSchooling"
                :proofs="proofs"
                :uploading="savingReceipt"
                :loading="
                  loading &&
                  proofToPreview?.ProofType === 'DependantSchoolingProof'
                "
                :title="$t('components.dependants_form.proof_of_schooling')"
                :disabled="false"
                proofType="DependantSchoolingProof"
                uploadType="DependantSchoolingProof"
                @upload-receipt="startUpload"
                @preview-receipt="receiptToPreview = $event"
                @preview-proof-files="previewProof"
                @remove-receipt="$emit('remove-receipt', $event)"
              />
              <proof-upload-box
                class="animate__animated animate__fadeIn animate__faster"
                v-show="dependant.LifeEventProof"
                :proof="dependant?.Qualification?.ProofOfLifeEvent"
                :proofs="proofs"
                :uploading="savingReceipt"
                :loading="
                  loading &&
                  proofToPreview?.ProofType === 'DependantLifeEventProof'
                "
                :title="$t('components.dependants_form.proof_of_life_event')"
                :disabled="false"
                proofType="DependantLifeEventProof"
                uploadType="DependantLifeEventProof"
                @upload-receipt="startUpload"
                @preview-receipt="receiptToPreview = $event"
                @preview-proof-files="previewProof"
                @remove-receipt="$emit('remove-receipt', $event)"
              />
              <proof-upload-box
                class="animate__animated animate__fadeIn animate__faster"
                v-show="dependant.CommonLawProof"
                :proof="dependant?.Qualification?.ProofOfCommonLawStatus"
                :proofs="proofs"
                :uploading="savingReceipt"
                :loading="
                  loading &&
                  proofToPreview?.ProofType === 'DependantCommonLawStatusProof'
                "
                :title="$t('components.dependants_form.proof_of_common_law')"
                :disabled="false"
                proofType="DependantCommonLawStatusProof"
                uploadType="DependantCommonLawStatusProof"
                @upload-receipt="startUpload"
                @preview-receipt="receiptToPreview = $event"
                @preview-proof-files="previewProof"
                @remove-receipt="$emit('remove-receipt', $event)"
              />
            </v-row>
            <v-expand-transition>
              <proofs-library
                v-if="proofs && proofs.length"
                :proofs="proofs"
                :receiptToPreview="receiptToPreview"
                @close-preview="receiptToPreview = {}"
                @remove-proof="removeProof"
              />
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('cancel')">
          {{ $t("globals.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="saveDepepndantProof"
          :disabled="proofs?.length < 1"
        >
          {{ $t("globals.save") }}
        </v-btn>
      </v-card-actions> -->
    </v-card>
    <!-- files upload dialog -->
    <v-dialog
      v-model="filesUploadDialog"
      :max-width="receiptSource === 'library' ? 600 : 0"
      hide-overlay
    >
      <v-card :width="receiptSource === 'library' ? 600 : 0">
        <v-card-title class="primary white--text px-4">
          <v-col class="pa-0" cols="auto">
            <v-icon dark class="mr-2">mdi-receipt-text-plus-outline</v-icon>
          </v-col>
          <v-col class="pa-0" cols="auto">
            {{ $t("components.new_claim.library.select_from_library") }}
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="pa-0" cols="auto">
            <v-icon dark @click="filesUploadDialog = false">mdi-close</v-icon>
          </v-col>
        </v-card-title>
        <v-card-text class="pt-2">
          <div v-if="receiptSource === 'library' && proofs.length > 0">
            <proofs-library-selection
              :proofs="proofs"
              @proof-selected="assignProof"
            />
          </div>
          <div v-if="receiptSource === 'upload' || proofs.length === 0">
            <multi-file-upload2
              v-if="filesUploadDialog"
              id="receiptId"
              @emit-upload-start="savingReceipt = true"
              @emit-upload-end="closeUploadDialog"
              :maxFileSizeMB="10"
              :label="$t('components.new_claim.select_or_add')"
              :tags="[selectedPlan.UniqueIdentifier]"
              :categories="[uploadType ?? 'DependantProof']"
              :currentFolder="planAdministratorName"
              :droppedFiles="droppedFiles"
              :uploadedFiles="selectedProof"
              :zipDownloadUrl="settings.APIURL + 'api/ZipFilesDownload'"
              :fileUploadUrl="
                settings.APIURL + `plans/${user.plan}/upload-files`
              "
              @emit-files="
                bindReceipts($event);
                closeUploadDialog();
              "
              @emit-upload-error="
                error = $event;
                closeUploadDialog();
              "
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <file-preview-base64
      :fullScreen="false"
      v-if="preview"
      :view="preview"
      :fileItems="fileItems"
      @close="closePreview"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProofUploadBox from "@/components/MemberInformation/items/panels/DependantsPanel/ProofUploadBox.vue";
import ProofsLibrary from "@/components/ClaimCentre/ProofsLibrary.vue";
import ProofsLibrarySelection from "@/components/ClaimCentre/ProofsLibrarySelection.vue";
import MultiFileUpload2 from "@/components/controls/MultiFileUpload2.vue";
import { createMemberFile } from "@/makeMemberFile";
import FilePreviewBase64 from "@/components/controls/FilePreviewBase64.vue";
export default {
  name: "AddProof",
  components: {
    FilePreviewBase64,
    ProofUploadBox,
    MultiFileUpload2,
    ProofsLibrary,
    ProofsLibrarySelection,
  },
  props: {
    model: Boolean,
    showTitle: Boolean,
    clearProofs: Boolean,
    category: String,
    dialogTitle: String,
    dialogText: String,
    dependant: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filesUploadDialog: false,
      preview: false,
      fileItems: null,
      loading: false,
      uploadProofDialogText: null,
      uploadProofDialogTitle: null,
      proof: null,
      proofOfSchooling: null,
      droppedFiles: null,
      uploadType: null,
      receiptSource: null,
      savingReceipt: false,
      proofToPreview: null,
      selectedProof: [],
      proofs: [],
      proofFiles: [],
      lastUploadIndex: 1,
      receiptToPreview: null,
    };
  },
  methods: {
    ...mapActions("data", ["getFile"]),
    saveDepepndantProof() {
      this.$emit("save", this.proof);
      this.proof = null;
    },
    startUpload({ type, source, droppedFiles }) {
      // console.log("starting upload", type, source, droppedFiles);
      this.droppedFiles = droppedFiles;
      this.uploadType = type;
      this.receiptSource = source;
      this.filesUploadDialog = true;
    },
    closeUploadDialog() {
      this.savingReceipt = false;
      this.filesUploadDialog = false;
      // console.log("upload finished");
      this.selectedProof = [];
    },
    bindReceipts(memberFiles) {
      if (memberFiles.length > 0) {
        const files = createMemberFile(memberFiles, this.uploadType);
        files.UploadName = `#${this.lastUploadIndex}:${this.uploadType}`;
        this.lastUploadIndex++;
        this.proof = files;
        this.proofs.push(files);
        this.proofFiles = memberFiles;
        this.saveDepepndantProof();
      } else {
        this.proofFiles = [];
      }
      // this.$forceUpdate();
    },
    assignProof(newProof) {
      // console.log("assign proof", newProof, this.category);
      this.proof = JSON.parse(JSON.stringify(newProof));
      this.proof.ProofType = this.uploadType;
      this.filesUploadDialog = false;
      this.saveDepepndantProof();
    },
    removeProof(index) {
      const proofToRemove = this.proofs[index];
      if (this.proof && this.proof.UploadName === proofToRemove.UploadName) {
        this.proof = null;
      }
      this.proofs.splice(index, 1);
    },
    async previewProof(proof) {
      this.loading = true;
      this.proofToPreview = proof;
      proof.UploadName = proof.ProofType;

      const getFileBase64 = (fileSegment) => {
        // eslint-disable-next-line no-async-promise-executor, no-unused-vars
        return new Promise(async (resolve, reject) => {
          let file = (
            await this.getFile({
              planIdentityId: this.user.plan,
              physicalFileId: fileSegment.PhysicalFileId,
            })
          ).data;
          if (file[0] === '"') file = file.slice(1, -1);
          fileSegment.Base64 = file;
          fileSegment.FileExtension = this.$helpers.fileExtension(
            fileSegment.FileName
          );
          resolve(fileSegment);
        });
      };

      try {
        await Promise.all(proof.FileSegments.map((fs) => getFileBase64(fs)));
        this.fileItems = proof;
        if (proof.FileSegments[0]?.Base64) {
          this.preview = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.proofToPreview = null;
      }
    },

    closePreview() {
      this.preview = false;
      this.fileItems = null;
    },
  },
  computed: {
    ...mapGetters("common", { selectedPlan: "plan" }),
    ...mapGetters("common", [
      "settings",
      "planAdministratorName",
      "userProfile",
    ]),
    ...mapGetters("auth", ["user"]),
    internalModel: {
      get() {
        return this.model;
      },
      set(v) {
        this.close(v);
      },
    },
  },
  watch: {
    category: {
      handler: function (val) {
        if (val == "DependantDisabilityProof") {
          this.title = this.$t(
            "components.member_information.dependants.dependant_disability_proof"
          );
          this.uploadProofDialogTitle = this.$t(
            "components.dependants_form.proof_of_disability"
          );
          this.uploadProofDialogText = this.$t(
            "components.dependants_form.proof_of_disability_text"
          );
        } else if (val == "DependantSchoolingProof") {
          this.title = this.$t(
            "components.member_information.dependants.schooling_proof"
          );
          this.uploadProofDialogTitle = this.$t(
            "components.dependants_form.proof_of_schooling"
          );
          this.uploadProofDialogText = this.$t(
            "components.dependants_form.proof_of_schooling_text"
          );
        } else if (val == "DependantCommonLawStatusProof") {
          this.title = this.$t(
            "components.member_information.dependants.common_law_status_proof"
          );
          this.uploadProofDialogTitle = this.$t(
            "components.dependants_form.proof_of_common_law"
          );
          this.uploadProofDialogText = this.$t(
            "components.dependants_form.proof_of_common_law_text"
          );
        } else if (val == "DependantLifeEventProof") {
          this.title = this.$t(
            "components.member_information.dependants.event_life_proof"
          );
          this.uploadProofDialogTitle = this.$t(
            "components.dependants_form.proof_of_life_event"
          );
          this.uploadProofDialogText = this.$t(
            "components.dependants_form.proof_of_life_event_text"
          );
        } else
          this.title = this.$t(
            "components.member_information.dependants.dependant_proof"
          );
      },
      immediate: true,
    },
    clearProofs: {
      handler() {
        this.proofs = [];
        this.proofFiles = [];
        this.lastUploadIndex = 1;
      },
    },
    dependant: {
      handler(newVal, oldVal) {
        // console.log("dependant changed", this.dependant);
        this.proof = null;
        if (newVal?.Id !== oldVal?.Id) {
          this.proofs = [];
        }
        this.proofFiles = [];
        this.lastUploadIndex = 1;
      },
    },
  },
};
</script>

<style scoped></style>
